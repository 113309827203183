var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"schema-attributes-container",attrs:{"data-test-id":"schemaAttributes"}},[_c('v-toolbar',{attrs:{"flat":"","width":"100%"}},[_c('v-toolbar-title',[_vm._v("Attributes")]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","inset":""}}),_c('v-checkbox',{staticClass:"schema-inherit-switch",attrs:{"data-test-id":"schemaInheritSwitch","hide-details":"","input-value":_vm.schema.inherit,"disabled":_vm.disabled || !_vm.allowSchemaRangeChanges,"false-value":false,"true-value":true,"color":_vm.schema.inherit ? 'orange' : undefined},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Inherit Attributes")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticClass:"help-tooltip"},[_vm._v(" If turned on, the schema will inherit any attributes from higher level ")])])]},proxy:true}]),model:{value:(_vm.schema.inherit),callback:function ($$v) {_vm.$set(_vm.schema, "inherit", $$v)},expression:"schema.inherit"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{staticClass:"schema-attributes"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"text-truncate"},[_vm._v("title")])]),_c('v-col',{staticClass:"d-flex"},[_c('Localization',{attrs:{"data-test-id":"titleLocalizationEditor","value":_vm.titleKey,"disabled":_vm.disabled,"key-pattern":_vm.uimodel.key,"key-space":_vm.uimodel.keySpace},on:{"input":function (i18nKey) { return _vm.updateAttribute('title', i18nKey ? { i18nKey: i18nKey } : undefined); }}}),(_vm.titleRestoreable)?_c('v-btn',{staticClass:"restore-btn",attrs:{"icon":"","color":"orange","title":'Restore to ' +
              JSON.stringify(
                _vm.uimodel.savedSchema ? _vm.uimodel.savedSchema.title : 'undefined'
              )},on:{"click":function($event){return _vm.restore(
                'title',
                _vm.uimodel.savedSchema ? _vm.uimodel.savedSchema.title : undefined,
                _vm.schema
              )}}},[_c('v-icon',[_vm._v("mdi-restore")])],1):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"text-truncate"},[_vm._v("description")])]),_c('v-col',[_c('Localization',{attrs:{"data-test-id":"descriptionLocalizationEditor","value":_vm.descriptionKey,"disabled":_vm.disabled,"key-pattern":_vm.uimodel.key,"key-space":_vm.uimodel.keySpace},on:{"input":function (i18nKey) { return _vm.updateAttribute(
                  'description',
                  i18nKey ? { i18nKey: i18nKey } : undefined
                ); }}}),(_vm.descriptionRestoreable)?_c('v-btn',{staticClass:"restore-btn",attrs:{"icon":"","color":"orange","title":'Restore to ' +
              JSON.stringify(
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.description
                  : 'undefined'
              )},on:{"click":function($event){return _vm.restore(
                'description',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.description
                  : undefined,
                _vm.schema
              )}}},[_c('v-icon',[_vm._v("mdi-restore")])],1):_vm._e()],1)],1)],1),(_vm.type === 'string')?_c('v-container',{staticClass:"type-settings"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("format")]),_c('v-col',[_c('AttributesField',{attrs:{"value":_vm.schema.format,"items":_vm.stringFormats,"attribute":"format","type":"select","uimodel":_vm.uimodel,"disabled":_vm.isDisabled('format')},on:{"input":function($event){return _vm.updateAttribute('format', $event)},"restore":function($event){return _vm.restore(
                'format',
                _vm.uimodel.savedSchema ? _vm.uimodel.savedSchema.format : undefined,
                _vm.schema
              )}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._v("minLength")]),_c('v-col',{attrs:{"cols":"4"}},[_c('AttributesField',{staticClass:"pr-2",attrs:{"value":_vm.schema.minLength,"attribute":"minLength","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.minLength],"disabled":_vm.isDisabled('minLength')},on:{"restore":function($event){return _vm.restore(
                'minLength',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.minLength
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('minLength', $event)}}})],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v("maxLength")]),_c('v-col',{attrs:{"cols":"4"}},[_c('AttributesField',{attrs:{"value":_vm.schema.maxLength,"attribute":"maxLength","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.maxLength],"disabled":_vm.isDisabled('maxLength')},on:{"restore":function($event){return _vm.restore(
                'maxLength',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.maxLength
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('maxLength', $event)}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("pattern")]),_c('v-col',[_c('AttributesField',{attrs:{"value":_vm.schema.pattern,"attribute":"pattern","type":"text","uimodel":_vm.uimodel,"disabled":_vm.isDisabled('pattern'),"placeholder":"Regular Expression"},on:{"restore":function($event){return _vm.restore(
                'pattern',
                _vm.uimodel.savedSchema ? _vm.uimodel.savedSchema.pattern : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('pattern', $event)}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex align-start",attrs:{"cols":"2"}},[_vm._v("enumOptions")]),_c('v-col',[_c('EnumOptions',{staticClass:"pt-3",attrs:{"value":_vm.schema.enumOptions,"uimodel":_vm.uimodel,"disabled":_vm.isDisabled('enumOptions'),"allow-schema-range-changes":_vm.allowSchemaRangeChanges,"pattern":_vm.schema.pattern},on:{"input":function($event){return _vm.updateEnumOptions($event)}}})],1)],1)],1):_vm._e(),(_vm.type === 'number' || _vm.type === 'integer')?_c('v-container',{staticClass:"type-settings"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("exclusiveMinimum")]),_c('v-col',{attrs:{"cols":"4"}},[_c('AttributesField',{attrs:{"value":_vm.schema.exclusiveMinimum,"attribute":"exclusiveMinimum","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.exclusiveMinimum],"disabled":_vm.isDisabled('exclusiveMinimum')},on:{"restore":function($event){return _vm.restore(
                'exclusiveMinimum',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.exclusiveMinimum
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('exclusiveMinimum', $event)}}})],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v("exclusiveMaximum")]),_c('v-col',{attrs:{"cols":"4"}},[_c('AttributesField',{attrs:{"value":_vm.schema.exclusiveMaximum,"attribute":"exclusiveMaximum","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.exclusiveMaximum],"disabled":_vm.isDisabled('exclusiveMaximum')},on:{"restore":function($event){return _vm.restore(
                'exclusiveMaximum',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.exclusiveMaximum
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('exclusiveMaximum', $event)}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":""}},[_vm._v("minimum")]),_c('v-col',{attrs:{"cols":"4"}},[_c('AttributesField',{attrs:{"value":_vm.schema.minimum,"attribute":"minimum","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.minimum],"disabled":_vm.isDisabled('minimum')},on:{"restore":function($event){return _vm.restore(
                'minimum',
                _vm.uimodel.savedSchema ? _vm.uimodel.savedSchema.minimum : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('minimum', $event)}}})],1),_c('v-col',[_vm._v("maximum")]),_c('v-col',{attrs:{"cols":"4"}},[_c('AttributesField',{attrs:{"value":_vm.schema.maximum,"attribute":"maximum","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.maximum],"disabled":_vm.isDisabled('maximum')},on:{"restore":function($event){return _vm.restore(
                'maximum',
                _vm.uimodel.savedSchema ? _vm.uimodel.savedSchema.maximum : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('maximum', $event)}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._v("multipleOf")]),_c('v-col',{attrs:{"cols":"10"}},[_c('AttributesField',{attrs:{"value":_vm.schema.multipleOf,"attribute":"multipleOf","type":"number","uimodel":_vm.uimodel,"disabled":_vm.isDisabled('multipleOf')},on:{"restore":function($event){return _vm.restore(
                'multipleOf',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.multipleOf
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('multipleOf', $event)}}})],1)],1)],1):_vm._e(),(_vm.type === 'array')?_c('v-container',{staticClass:"type-settings"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._v("minItems")]),_c('v-col',{attrs:{"cols":"5"}},[_c('AttributesField',{attrs:{"value":_vm.schema.minItems,"attribute":"minItems","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.minItems],"disabled":_vm.isDisabled('minItems')},on:{"restore":function($event){return _vm.restore(
                'minItems',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.minItems
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('minItems', $event)}}})],1),_c('v-col',[_vm._v("maxItems")]),_c('v-col',{attrs:{"cols":"5"}},[_c('AttributesField',{attrs:{"value":_vm.schema.maxItems,"attribute":"maxItems","type":"number","uimodel":_vm.uimodel,"rules":[_vm.ruleSet.maxItems],"disabled":_vm.isDisabled('maxItems')},on:{"restore":function($event){return _vm.restore(
                'maxItems',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.maxItems
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('maxItems', $event)}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("uniqueItems")]),_c('v-col',[_c('AttributesField',{attrs:{"value":_vm.schema.uniqueItems,"uimodel":_vm.uimodel,"disabled":_vm.isDisabled('uniqueItems'),"attribute":"uniqueItems","type":"checkbox"},on:{"restore":function($event){return _vm.restore(
                'uniqueItems',
                _vm.uimodel.savedSchema
                  ? _vm.uimodel.savedSchema.uniqueItems
                  : undefined,
                _vm.schema
              )},"input":function($event){return _vm.updateAttribute('uniqueItems', $event)}}})],1)],1)],1):_vm._e(),(
        _vm.type === 'object' &&
        _vm.schema.properties &&
        Object.keys(_vm.schema.properties).length > 0
      )?_c('v-container',{staticClass:"type-settings"},[_c('PropertiesInheritance',{attrs:{"schema":_vm.schema,"disabled":_vm.disabled || !_vm.allowSchemaRangeChanges,"uimodel":_vm.uimodel}}),(_vm.schema.properties)?_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-2"},[_vm._v("required")]),_c('RequiredPropertiesTooltip')],1),_c('v-col',{attrs:{"cols":"10"}},[_c('RequiredProperties',{attrs:{"schema":_vm.schema,"disabled":_vm.isDisabled(_vm.required),"uimodel":_vm.uimodel}})],1)],1):_vm._e()],1):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("default")]),_c('v-col',[_c('v-switch',{staticClass:"default-switch",attrs:{"hide-details":"","disabled":_vm.disabled},model:{value:(_vm.hasDefault),callback:function ($$v) {_vm.hasDefault=$$v},expression:"hasDefault"}})],1),(_vm.hasDefault && _vm.type !== 'object' && _vm.type !== 'array')?_c('v-col',{attrs:{"cols":"10"}},[(_vm.hasDefault)?_c('DataEditor',{attrs:{"ext-view-mode":"edit","domain-data":undefined,"merged-data":undefined,"schema":_vm.schema,"property":"default"},model:{value:(_vm.schema.default),callback:function ($$v) {_vm.$set(_vm.schema, "default", $$v)},expression:"schema.default"}}):_vm._e()],1):_vm._e()],1),(_vm.hasDefault && (_vm.type === 'object' || _vm.type === 'array'))?_c('v-row',[_c('DataEditor',{attrs:{"ext-view-mode":"edit","domain-data":undefined,"merged-data":undefined,"schema":_vm.schema,"property":"default"},model:{value:(_vm.schema.default),callback:function ($$v) {_vm.$set(_vm.schema, "default", $$v)},expression:"schema.default"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }