<template>
  <v-container
    fluid
    class="pattern-search-container"
    data-test-id="patternSearch"
  >
    <v-row no-gutters>
      <v-col
        v-for="(part, index) in selectorPattern"
        :key="index"
        :class="{
          'pattern-part': true,
          dot: part === '.',
        }"
      >
        <div v-if="part === '.'" class="text-h3">.</div>
        <v-combobox
          v-else
          v-model="dimensions[part]"
          outlined
          dense
          hide-details="auto"
          :label="part"
          :items="dimensionOptions[part]"
          :disabled="part === 'productType'"
          :data-test-id="'pattern_dimension_' + part"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :value="pattern"
          :loading="loading"
          dense
          outlined
          class="sku-input"
          label="SKU"
          hide-details="auto"
          placeholder="Enter SKU or SKU pattern ..."
          data-test-id="patternSearchInput"
          @change="pattern = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <div class="pattern-search-table-container">
        <v-data-table
          :headers="headers"
          :items="lookupResults"
          :item-class="(item) => isSelected(item)"
          :loading="loading"
          :items-per-page="-1"
          hide-default-footer
          fixed-header
          dense
          item-key="key"
          class="pattern-search-table"
          data-test-id="patternSearchTable"
          @click:row="(item) => selectPattern(item.key)"
        >
          <!-- eslint-disable-next-line -->
          <template #item.key="{ item }">
            <div class="pattern-key" :data-test-id="'pattern_key_' + item.key">
              {{ item.key }}
            </div>
          </template>
        </v-data-table>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import mixin from "../../../mixins/config-service-mixin";

export default {
  mixins: [mixin],

  props: {
    value: {
      required: false,
    },

    skuPattern: {
      required: true,
    },

    productType: {
      required: true,
    },

    lookupRegion: {
      required: true,
      validator: (value) => ["data", "schema", "translation"].includes(value),
    },

    isDirty: {
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dimensions: {},
      pattern: undefined,
      dimensionOptions: {},
      loading: false,
      lookupResults: [],
      selectedPattern: this.value,
    };
  },

  mounted() {
    this.loadDimensions();
  },

  watch: {
    async $route(to, from) {
      const toSearch =
        to.name === "productSchemaSearch" || to.name === "productDataSearch";
      const fromEditorView =
        from.name === ("productSchema" || from.name === "productData");
      if (fromEditorView && toSearch) {
        //Reload keys when switching from editor to search
        await this.lookupKeys();
      }
    },

    value(value) {
      this.selectedPattern = value;
    },

    dimensions: {
      handler: async function (dimensions) {
        this.pattern = this.getCombinedPattern(dimensions);
        if (this.lookupRegion) await this.lookupKeys();
      },
      deep: true,
    },

    pattern(pattern) {
      //emit event 300ms after the last input change
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        //if the SKU did not change, skip the process
        if (
          this.sanitizeKeyPattern(pattern) ===
          this.getCombinedPattern(this.dimensions)
        )
          return;

        //get the dimensions from the string
        let parts = pattern.split(".");
        let dimensions = new Map();

        //update the values of the dimension select boxes
        this.cleanedPattern.forEach((patternPart, idx) => {
          if (patternPart === "productType")
            dimensions.set(patternPart, this.productType);
          else dimensions.set(patternPart, parts[idx] || "*");
        });
        this.dimensions = Object.fromEntries(dimensions);
      }, 300);
    },

    selectedPattern(pattern) {
      this.$emit("input", pattern);
    },

    async lookupRegion() {
      await this.lookupKeys();
    },
  },

  methods: {
    async loadDimensions() {
      //load dimensions of this product type
      const productTypeDimensions = await this.$store.dispatch("get", {
        path: "/option/" + this.productType + "/dimension",
      });

      //change dimensions array to object
      this.dimensionOptions = productTypeDimensions.reduce(
        (total, dimension) => {
          let oevs = dimension.optionEnumValues.map((oev) => {
            return oev.code.toUpperCase();
          });
          oevs.unshift("*");
          return { ...total, [dimension.key]: oevs };
        },
        {}
      );

      //load all available connector specs
      let specs = await this.$store.dispatch("get", {
        path: "/spec",
      });

      //Add the specs to the possible dimensions
      if (specs) {
        specs = specs.map((spec) => {
          return spec.id.toUpperCase();
        });
        specs.unshift("*");
        this.$set(this.dimensionOptions, "connector", specs);
      }
      this.$set(this.dimensionOptions, "productType", [this.productType]);

      //if the SKU stayed the same, prevent an update
      if (this.pattern && this.pattern === this.getCombinedPattern(dimensions))
        return;

      //update the dimensions and therefore the SKU
      let dimensions = {};
      Object.keys(this.dimensionOptions).forEach((item) =>
        this.$set(dimensions, item, this.dimensions[item] ?? "*")
      );
      this.$set(dimensions, "productType", this.productType);
      this.dimensions = Object.assign({}, dimensions);
    },

    getCombinedPattern(dimensions) {
      //combine the selected dimensions to a SKU string
      let sku = "";
      this.cleanedPattern.forEach((part, index) => {
        if (index > 0) sku += ".";
        if (part === "productType") sku += this.productType;
        else sku += dimensions?.[part] || "*";
      });
      return this.sanitizeKeyPattern(sku);
    },

    async lookupKeys() {
      try {
        this.loading = true;
        const keySpace = "SKU";
        const pattern = this.pattern || "*." + this.productType + ".*";
        let lookupRes =
          await this.$store.$coreApi.coreConfigurationApi.configLookup(
            this.selectedDomain,
            keySpace,
            pattern,
            {
              lookupRegion: [this.lookupRegion],
              includeParentDomains: false,
            }
          );

        if (!lookupRes) lookupRes = [];

        const idx = lookupRes.findIndex((res) => res.key === this.pattern);
        //add or move current search pattern to top of list
        if (idx < 0) {
          lookupRes.unshift({
            key: this.pattern,
            keySpace,
          });
        } else {
          const temp = lookupRes[idx];
          lookupRes.splice(idx, 1);
          lookupRes.unshift(temp);
        }

        //filter out duplicate keys
        this.lookupResults = lookupRes.filter(
          (res, index, self) =>
            index === self.findIndex((r) => r.key === res.key)
        );
      } finally {
        this.loading = false;
      }
    },

    isSelected(item) {
      let classes = [];
      if (!item.specificity) classes.push("font-italic text--disabled");
      if (this.selectedPattern && this.selectedPattern === item.key)
        classes.push("selected");
      classes.push(item.key);
      return classes.join(" ");
    },

    async selectPattern(pattern) {
      if (
        this.isDirty &&
        !(await this.$confirm(
          "Unsaved Changes",
          "Do you really want to leave? You have unsaved changes!",
          { width: 400 }
        ))
      )
        return;
      this.selectedPattern = pattern;
    },
  },

  computed: {
    cleanedPattern() {
      //returns an array of the dimension names in the SKU pattern without the chars < and >
      const pattern = this.skuPattern.split(".");
      return pattern.map((part) => part.replace(new RegExp("<|>", "g"), ""));
    },

    selectorPattern() {
      //inserts dots between two dimensions in the cleaned pattern
      //so that sku pattern is properly visualized by the dimension selects
      let pattern = this.$cloneObject(this.cleanedPattern);
      for (let i = 1; i < pattern.length; i = i + 2) {
        pattern.splice(i, 0, ".");
      }
      return pattern;
    },

    headers() {
      return [{ text: "Configurations", value: "key" }];
    },
  },
};
</script>

<style scoped>
.pattern-search-container > .row > .pattern-select-header {
  display: flex;
}

.pattern-search-container > .row > .pattern-part {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}

.pattern-search-container > .row > .pattern-part:not(.dot) {
  flex-grow: 10;
}

.pattern-search-table-container {
  display: flex;
  flex: 1 1 auto;
  margin: 12px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 6px;
}

.pattern-search-table-container > .pattern-search-table {
  width: 100%;
}

.pattern-search-table-container
  > .pattern-search-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr {
  cursor: pointer;
}

.pattern-search-table-container
  > .pattern-search-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.selected {
  background-color: var(--v-psblue-base);
  color: white;
}

.pattern-search-table-container
  > .pattern-search-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.selected:hover {
  color: black;
}
</style>