<template>
    <v-container 
        :class="{
            'json-schema-editor': true,
            'invalid': hasErrors
        }"
    >
        <textarea ref="editor"/>

        <v-tooltip 
            top
            v-if="hasErrors"
            v-model="showErrors"
            allow-overflow
        >
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    class="error-alert"
                >
                    mdi-alert-circle-outline
                </v-icon>
            </template>
            <ol>
                <li v-for="(error, index) in validationErrors" :key="index">{{error}}</li>
            </ol>  
        </v-tooltip>
    </v-container>
</template>

<script>

import CodeMirror from "codemirror"
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/xq-light.css'
import 'codemirror/mode/javascript/javascript.js'

import mixin from "../../../../mixins/schema-editor-mixin"


export default {

    mixins: [mixin],

    props: {

        value: {
            required: true
        },

        property: {
            required: true
        },

        disabled: {
            required: false,
            default: false,
        }

    },


    data(){
        return {
            editor: null,
            timeout: 0,
            validationErrors: [],
            showErrors: false,
        }
    },

    mounted(){

        //create a new editor instance and set options
        this.editor = CodeMirror.fromTextArea(this.$refs.editor, {
            height: "auto",
            lineNumbers: true,
            theme: 'xq-light',
            mode: {
                name: "javascript",
                json: true,
                statementIndent: 2
            },
        });

        //add change listener, so the value is validated and updated whenever the user enters something
        this.editor.on('keyup', editor => {
            let self = this;
            //validate and update data 300ms after the last input change
            window.clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                try{
                    let value = editor.getValue();
                    if(value === "") {
                        this.$emit("input", schema);
                        return;
                    }
                    let schema = JSON.parse(value);

                    if(this.$isEqual(self.value, schema) && this.validationErrors.length === 0) return;
                    //validate the schema
                    this.validate(schema);
                    this.$emit("input", schema);
                }catch(e){
                    this.validationErrors.push(e.toString());
                    return;
                }
                
            }, 300);
        });

        this.editor.setValue(this.value ? JSON.stringify(this.value, null, "\t") : "");

        //clear history to prevent a bug where the keybinding ctrl+z would undo the initial data
        this.editor.doc.clearHistory();
        this.editor.refresh();

        if(this.disabled){
            this.editor.setOption('readOnly', 'nocursor')
            return;
        }

        //initial validation
        this.validate(this.value);
    },

    watch: {

        value(val){
            let schema = this.editor.getValue();
            if(val === undefined){
                this.editor.setValue("");
                return;
            }

            this.editor.setOption('readOnly', false)

            //check if the values are not equal
            if(!schema || !this.$isEqual(JSON.parse(schema), val)){
                this.editor.setValue(JSON.stringify(val, null, "\t")  ?? "");
                //clear history to prevent a bug where the keybinding ctrl+z would undo the initial data
                this.editor.doc.clearHistory();
                this.editor.refresh();
            }
        },

    },

    methods: {

        validate(schema){
            if(!schema) return true;
            //validate the schema against defined rules
            let errors = [];
            this.validateSchema(null, schema, errors);

            if(errors.length > 0){
                //show errors and prevent emitting of invalid data
                this.validationErrors = errors;
                this.showErrors = true;
                window.setTimeout(() => this.showErrors = false, 2000);
                return false;
            }
            
            this.validationErrors = new Array();
            return true;
        },

    },

    computed: {

        hasErrors(){
            return this.validationErrors.length > 0
        }

    }
    
}
</script>

<style scoped>

div.json-schema-editor {
    text-align: left;
    margin: 10px 0px;
    position: relative;
    max-width: 100%;
}

.json-schema-editor::v-deep .CodeMirror{
    height: 100%;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.json-schema-editor.invalid::v-deep .CodeMirror{
    border: 1px solid red;
} 

.json-schema-editor > .error-alert{
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 10;
}

.json-schema-editor > .error-tooltip{
    text-align: left;
}

</style>