<template>
  <div class="schema-merge-previewer">
    <div class="previewer-toolbar elevation-4">
      <div class="text-h6 font-weight-regular flex-grow-1 mb-3">
        Schema merge preview
      </div>
      <div class="previewer-controls">
        <v-combobox
          dense
          hide-details="auto"
          background-color="white"
          ref="combobox"
          no-filter
          data-test-id="schemePreviewPatternInput"
          outlined
          :placeholder="schemaRule.keyPattern"
          :value="dummyModel"
          :disabled="isDisabled"
          :loading="loadingPossiblePatterns"
          :items="possiblePatterns"
          @focus="$emit('focus')"
          @change="onAutoCompleteSelection"
          @input.native="updateModel"
        />

        <v-btn
          icon
          class="ml-2"
          data-test-id="schemaPreviewReloadBtn"
          :disabled="isDisabled"
          :loading="runningAction"
          :color="hasChanged ? 'warning' : undefined"
          :title="reloadBtnTitle"
          @click="loadPreviewSchema"
        >
          <v-icon v-if="hasChanged">mdi-sync-alert</v-icon>
          <v-icon v-else>mdi-sync</v-icon>
        </v-btn>
        <div v-if="!runningAction && violation" class="ml-2">
          <ViolationAlert :violation="violation" color="red" />
        </div>
      </div>
      <div class="previewer-content-info">
        <div>Currently displaying merged schema for:</div>
        <div>{{ lastKeyPattern }}</div>
      </div>
    </div>
    <div class="previewer-content">
      <Reader
        :schema="previewSchema"
        data-test-id="schemaReaderView"
        class="mt-3"
      />
    </div>
  </div>
</template>

<script>
import Reader from "../reader/Reader.vue";
import ViolationAlert from "../../../common/display-helpers/ViolationAlert";
import configMixin from "../../../../mixins/config-service-mixin";
import esMixin from "../../../../mixins/elastic-search-mixin";

export default {
  mixins: [configMixin, esMixin],

  components: {
    Reader,
    ViolationAlert,
  },

  props: {
    schemaRule: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  provide() {
    return {
      getShownLocales: this.getShownLocales,
    };
  },

  data() {
    return {
      previewSchema: null,
      dummyModel: undefined,
      keyPattern: undefined,
      violation: null,
      runningAction: false,
      lastSchema: null,
      lastKeyPattern: null,
      hasChanged: false,
      possiblePatterns: [],
      loadingPossiblePatterns: false,
      timeout: 0,
    };
  },

  mounted() {
    this.loadPossiblePatterns();
    this.loadPreviewSchema();
  },

  watch: {
    "schemaRule.schema": {
      handler: function (schema) {
        this.hasChanged = !this.$isEqual(schema, this.lastSchema);
      },
      deep: true,
    },

    lastSchema: {
      handler: function (lastSchema) {
        const schema = this.schemaRule.schema;
        this.hasChanged = !this.$isEqual(schema, lastSchema);
      },
      deep: true,
    },

    keyPattern(keyPattern) {
      this.hasChanged = keyPattern !== this.lastKeyPattern;
      this.dummyModel = keyPattern;
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.loadPossiblePatterns();
      }, 300);
    },
  },

  methods: {
    onAutoCompleteSelection(keyPattern) {
      if (keyPattern === this.keyPattern) return;
      this.keyPattern = keyPattern;
    },

    updateModel(event) {
      this.keyPattern = event?.target?.value;
    },

    async loadPossiblePatterns() {
      try {
        this.loadingPossiblePatterns = true;
        const configs = await this.loadConfigurations();
        const products = await this.loadProducts();
        const patternSet = new Set(configs.concat(products));
        this.possiblePatterns = Array.from(patternSet);
      } finally {
        this.loadingPossiblePatterns = false;
      }
    },

    async loadConfigurations() {
      const configs =
        await this.$store.$coreApi.coreConfigurationApi.configLookup(
          this.selectedDomain,
          "SKU",
          this.schemaRule.keyPattern + ".*",
          {
            lookupRegion: ["schema"],
            includeParentDomains: false,
          }
        );

      return configs?.map((conf) => conf.key) ?? [];
    },

    async loadProducts() {
      this.loadingProducts = true;
      const pattern = this.keyPattern ?? this.schemaRule.keyPattern;
      const search = this.searchRequestBody({
        limit: 200,
        _source: ["sku"],
        query: {
          wildcard: {
            ["sku.keyword"]: {
              value: pattern + "*",
            },
          },
        },
      });

      const result = await this.$store.$coreApi.coreElasticSearchApi.search({
        domain: this.selectedDomain,
        indexType: "product",
        body: search,
        params: {
          suppressedErrorCodes: [404],
        },
      });

      const products = result?.items ?? [];
      return products.map((product) => {
        return product._source?.sku;
      });
    },

    async loadPreviewSchema() {
      if (!this.schemaRule?.schema) return;
      try {
        this.runningAction = true;
        await this.$nextTick();
        this.violation = null;
        this.previewSchema = null;
        this.lastSchema = this.$cloneObject(this.schemaRule.schema);
        const keySpace = this.schemaRule.keySpace;
        const keyPattern = this.keyPattern || this.schemaRule.keyPattern;
        this.lastKeyPattern = keyPattern;
        const res =
          await this.$store.$coreApi.coreConfigurationApi.getPreviewMergedSchema(
            this.selectedDomain,
            keyPattern,
            keySpace,
            this.schemaRule,
            {
              returnErrors: true,
            }
          );

        if (!res?.ok) {
          if (res?.status === 400) {
            const error = await res.json();
            this.violation = {
              message: error.message,
            };
            return;
          }

          this.violation = {
            message: "Something went wrong while merging!",
          };
          return;
        }
        const preview = await res.json();
        this.previewSchema = preview?.mergedSchema?.schema;
        const errors = preview?.validationErrors;
        if (errors && errors.length > 0) {
          const violation = this.parseValidationErrorsToViolation(errors);
          this.violation = violation.violation;
        }
      } finally {
        this.runningAction = false;
      }
    },
  },
  computed: {
    isDisabled() {
      return this.disabled || this.runningAction;
    },

    reloadBtnTitle() {
      if (!this.hasChanged) return undefined;
      return "Schema or key pattern have been changed since last reload";
    },
  },
};
</script>

<style scoped>
.schema-merge-previewer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: 100%;
}

.schema-merge-previewer > .previewer-toolbar {
  padding: 8px;
  margin-bottom: 4px;
}

.schema-merge-previewer > .previewer-toolbar > .previewer-controls {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
}

.schema-merge-previewer > .previewer-toolbar > .previewer-content-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.schema-merge-previewer
  > .previewer-toolbar
  > .previewer-content-info
  > div:first-child {
  font-weight: 900;
  margin-right: 4px;
}

.schema-merge-previewer > .previewer-content {
  height: calc(100% - 96px);
  overflow: scroll;
}
</style>