var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    'json-editor-container': true,
    invalid: _vm.hasErrors,
    root: _vm.uimodel.dataLevel === 0,
  },attrs:{"fluid":""}},[(_vm.showLabel === true && _vm.label)?_c('label',{staticClass:"label",attrs:{"title":_vm.uimodel.property}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('textarea',{ref:"editor"}),_vm._v(" "),_c('Controls',{attrs:{"uimodel":_vm.uimodel,"show-label":_vm.showLabel,"editor-shown":true,"hide-view-controls":_vm.hideViewControls},on:{"remove":function($event){return _vm.$emit('remove')},"restore":_vm.restore,"toggle-inherit":_vm.toggleInherit,"type-change":function($event){return _vm.$emit('type-change', $event)},"toggle-editor":_vm.toggleEditor,"enable-readonly":function($event){return _vm.$emit('enable-readonly')}}}),(_vm.hasErrors)?_c('v-tooltip',{attrs:{"top":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"error-alert",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,false,2505925405),model:{value:(_vm.showErrors),callback:function ($$v) {_vm.showErrors=$$v},expression:"showErrors"}},[_c('ol',_vm._l((_vm.validationErrors),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }