<template>
  <!-- TODO: Show Data/Schema editor for product according to selected schema  -->
  <v-container v-if="loading" fluid class="loading-overlay">
    <v-overlay>
      <v-progress-circular indeterminate size="96" color="primary" />
    </v-overlay>
  </v-container>
  <v-container
    v-else
    fluid
    :class="{
      'pattern-configuration-editor-container': true,
      'preview-shown': previewShown,
    }"
    :data-test-id="isData ? 'patternDataEditor' : 'patternSchemaEditor'"
  >
    <div class="pattern-configuration-editor">
      <PatternHeader
        v-if="!loading && (patternData || patternSchema)"
        :pattern="pattern"
        :lookup-region="lookupRegion"
        :is-dirty="isDirty"
        :deleteable="deleteable"
        :violation="violation"
        :loading="runningAction"
        class="pattern-configuration-toolbar"
        @save="save"
        @remove="remove"
      />

      <v-form ref="editorForm" class="pattern-configuration-editor-form">
        <DataEditor
          v-if="isData && patternData"
          v-model="patternData.data"
          :key="reloadTrigger"
          :domain-level="domainLevel"
          :saved-data="patternData.savedData"
          :merged-data="patternData.mergedData"
          :schema="patternData.schema"
          :property="pattern"
          :ext-view-mode="$route.query.mode || 'view'"
          :disabled="loading"
          :key-pattern="pattern"
          key-space="SKU"
          @restore="patternData.data = $cloneObject(patternData.savedData)"
        />

        <SchemaEditor
          v-else-if="isSchema && patternSchema"
          v-model="patternSchema.schema"
          :key="reloadTrigger"
          :domain="selectedDomain"
          :domain-schemas="patternSchema.domainSchemas"
          :merged-schema="patternSchema.mergedSchema"
          :saved-schema="patternSchema.savedSchema"
          :key-pattern="pattern"
          :type-editable="false"
          :ext-view-mode="$route.query.mode || 'view'"
          disabled
          key-space="SKU"
          parent-type="schema"
          @restore="
            patternSchema.schema = $cloneObject(patternSchema.savedSchema)
          "
        />
      </v-form>
    </div>

    <div
      v-if="isSchema && patternSchema && isInEditMode"
      v-ripple
      class="preview-expand-btn"
      @click="showPreview = !showPreview"
    >
      <div class="toggle-icon-top">
        <v-icon color="primary">
          mdi-chevron-double-{{ showPreview ? "right" : "left" }}
        </v-icon>
      </div>
      <div class="preview-expand-title-container">
        <div class="preview-expand-title text-button primary--text">
          Schema merge preview
        </div>
      </div>
      <div class="toggle-icon-bottom">
        <v-icon color="primary">
          mdi-chevron-double-{{ showPreview ? "right" : "left" }}
        </v-icon>
      </div>
    </div>
    <div v-show="previewShown" class="schema-previewer-container">
      <SchemaMergePreviewer
        v-if="!loading && isSchema && patternSchema"
        :schema-rule="schemaRule"
        :key="reloadPreview"
      />
    </div>
  </v-container>
</template>

<script>
import mixin from "../../../mixins/config-service-mixin";
import DataEditor from "../../configuration/data/editors/DataEditor";
import SchemaEditor from "../../configuration/schema/editors/SchemaEditor";
import PatternHeader from "./PatternHeader";
import SchemaMergePreviewer from "../../configuration/schema/preview/SchemaMergePreviewer";

export default {
  mixins: [mixin],

  components: {
    DataEditor,
    SchemaEditor,
    PatternHeader,
    SchemaMergePreviewer,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    productType: {
      type: String,
      required: true,
    },

    lookupRegion: {
      type: String,
      required: true,
      validator: (value) => ["data", "schema", "translation"].includes(value),
    },
  },

  provide() {
    return {
      setTranslation: this.setTranslation,
      addTranslationKey: this.addTranslationKey,
      getTranslations: this.getTranslations,
      getViolations: this.getViolations,
      getSource: this.getSource,
      getProductType: () => {
        return this.productType;
      },
    };
  },

  data() {
    return {
      pattern: this.value,
      patternData: undefined,
      patternSchema: undefined,
      loading: false,
      reloadTrigger: 0,
      isDirty: false,
      runningAction: false,
      deleteable: false,
      violation: null,
      translations: [],
      translationKeys: [],
      sources: [],
      showPreview: false,
      reloadPreview: 0,
    };
  },

  mounted() {
    this.init();
  },

  watch: {
    "$route.name": function (routeName, oldRouteName) {
      const routeChanged = routeName !== oldRouteName;
      const isEditorSwitch =
        (routeName == "productSchema" || routeName === "productData") &&
        (oldRouteName == "productSchema" || oldRouteName === "productData");

      if (routeChanged && isEditorSwitch) {
        this.init();
      }
    },

    value(value) {
      this.pattern = value;
      this.init();
    },

    pattern(pattern) {
      this.$emit("input", pattern);
    },

    "patternData.data": {
      handler: function (data) {
        const hasChanged = !this.$isEqual(data, this.patternData?.savedData);
        this.isDirty = hasChanged;
        this.$set(this.$route.params, "unsavedChanges", hasChanged);
      },
      deep: true,
    },

    "patternSchema.schema": {
      handler: function (schema) {
        const hasChanged =
          !this.$isEqual(schema, this.patternSchema?.savedSchema) ||
          this.translations.length > 0;
        this.isDirty = hasChanged;
        this.$set(this.$route.params, "unsavedChanges", hasChanged);
      },
      deep: true,
    },

    translations(translations) {
      const hasChanged =
        !this.$isEqual(
          this.patternSchema?.schema,
          this.patternSchema?.savedSchema
        ) || translations.length > 0;
      this.isDirty = hasChanged;
      this.$set(this.$route.params, "unsavedChanges", hasChanged);
    },

    showPreview(isShown) {
      if (isShown) {
        this.$nextTick(() => this.reloadPreview++);
      }
    },
  },

  methods: {
    async init() {
      if (!this.pattern) return;
      try {
        this.loading = true;
        let routeName = this.$route.name;

        if (routeName.startsWith("productData")) {
          routeName = "productData";
          await this.loadPatternData();
        } else if (routeName.startsWith("productSchema")) {
          routeName = "productSchema";
          await this.loadPatternSchema();
        }

        await this.$router.replace({
          name: routeName,
          params: {
            productType: this.productType,
            pattern: this.pattern,
          },
          query: this.$route.query,
        });
      } finally {
        this.loading = false;
      }
    },

    setTranslation(key, labels) {
      // Parse the keys and labels in the correct form for the translation service
      if (!key || !labels) return;
      this.translations = this.updateTranslations(
        key,
        labels,
        this.translations
      );
    },

    getTranslations(key) {
      //Get all localizations for a specific key from the translations array
      //This is needed to display translations which are updated but not yet saved
      const result = this.translations.reduce((obj, { locale, valueset }) => {
        const value = valueset?.[key];
        if (value !== undefined) {
          this.$set(obj, locale, value);
        }
        return obj;
      }, {});

      return Object.keys(result).length > 0 ? result : null;
    },

    addTranslationKey(i18nKey) {
      if (this.translationKeys.some((translation) => translation === i18nKey))
        return;
      this.translationKeys.push(i18nKey);
    },

    async loadPatternData() {
      const schemaRule = await this.loadMergedSchema(
        this.selectedDomain,
        "ProductType",
        this.productType + ".product.data",
        true
      );
      const schema = schemaRule.schema;
      const mergedDataRes =
        await this.$store.$coreApi.coreConfigurationApi.getMergedData(
          this.selectedDomain,
          "SKU",
          this.pattern,
          schema,
          {
            query: "withInheritanceSources=true",
          }
        );

      const mergedData = mergedDataRes?.data;
      this.sources = mergedDataRes?.sources ?? [];

      //retrieve data of this domain and the parent domains
      const fullData = await this.$store.$coreApi.coreConfigurationApi.getData(
        this.selectedDomain,
        "SKU",
        this.pattern,
        {
          includeParents: false,
        }
      );

      const currentKeyData = fullData?.[0]?.data;
      //deep clone of data for usage and restore
      const data = this.$cloneObject(currentKeyData);
      const savedData = this.$cloneObject(currentKeyData);
      this.$nextTick(() => (this.deleteable = !!data));
      this.patternData = Object.assign(
        {},
        {
          schema,
          data,
          savedData,
          mergedData,
          schemaRule,
        }
      );
    },

    async loadPatternSchema() {
      const mergedSchemaRule = await this.loadMergedSchema(
        this.selectedDomain,
        "SKU",
        this.pattern
      );
      const mergedSchema = mergedSchemaRule.schema;

      //load the schema of each parent domain and key pattern
      const sources = await this.$store.dispatch("get", {
        path: "/schemaRule/SKU/" + this.pattern + "/withParents",
        suppressedErrorCodes: [404],
      });

      let domainSchemas = [];
      let schema, savedSchema, id, schemaRule;
      sources
        .sort((s1, s2) => s1.specificity - s2.specificity)
        .forEach((source) => {
          if (
            source.domainId === this.selectedDomain &&
            source.keyPattern === this.pattern
          ) {
            schema = this.$cloneObject(source.schema);
            savedSchema = this.$cloneObject(schema);
            id = source.id;
            schemaRule = source;
          }
          domainSchemas.push({
            schema: source.schema,
            domain: source.domainId,
            keyPattern: source.keyPattern,
          });
        });

      this.$nextTick(() => (this.deleteable = !!schema));

      this.patternSchema = Object.assign(
        {},
        {
          id,
          schema,
          mergedSchema,
          savedSchema,
          domainSchemas,
          schemaRule,
        }
      );
    },

    async save() {
      try {
        if (!this.$validateVForm(this.$refs.editorForm)) {
          this.violation = {
            message: "At least one input is invalid, please check",
          };
          return;
        }
        this.violation = null;
        this.runningAction = true;

        if (this.isData) {
          const schemaRule = this.patternData.schemaRule;
          const keySpace = schemaRule.dataLocation?.keySpace;
          //update or insert the SKU data
          const dataRes = await this.upsertData(
            keySpace,
            this.pattern,
            this.patternData.data,
            "Configuration Data for key " + this.pattern + " saved"
          );
          if (dataRes?.status !== 200) {
            if (dataRes?.violation) {
              this.violation = dataRes.violation;
              this.$nextTick(() =>
                this.$validateVForm(this.$refs.editorForm, true)
              );
            }
            return;
          }

          await this.loadPatternData();
          this.reloadTrigger++;
        } else if (this.isSchema) {
          //if the schema Rule has no ID, create a new one
          const dataLocation = this.patternSchema?.schemaRule?.dataLocation;
          let id = this.patternSchema?.id;
          if (!id) id = this.$uuid.v4();

          //update or insert the schema rule
          const schemaRes = await this.upsertSchemaRule(
            id,
            "SKU",
            this.pattern,
            dataLocation,
            this.patternSchema.schema,
            "Schema for key " + this.pattern + " saved"
          );

          if (schemaRes?.status !== 200) {
            if (schemaRes?.violation) {
              this.violation = schemaRes.violation;
              this.$nextTick(() =>
                this.$validateVForm(this.$refs.editorForm, true)
              );
            }
            return;
          }
          await this.loadPatternSchema();
          this.reloadTrigger++;
        }

        if (this.translations.length > 0) {
          const res = await this.upsertTranslations(
            this.translations,
            "SKU",
            this.pattern
          );
          if (!res?.ok) {
            const response = await res.json();
            this.$store.commit(
              "SET_ERROR",
              "Something went wrong while updating translations! " +
                response.message
            );
            this.runningAction = false;
            return;
          } else {
            this.$store.commit("SET_SUCCESS", "Updated translations");
          }
          this.translations = [];
        }
      } finally {
        this.runningAction = false;
      }
    },

    async remove() {
      try {
        if (this.isData) {
          const confirmed = await this.$confirm(
            "Delete configuration?",
            "Are you sure you want to delete the configuration of " +
              this.pattern +
              " ?",
            { width: 600 }
          );
          if (!confirmed) return;
          this.runningAction = true;

          const schemaRule = this.patternData.schemaRule;
          const dataLocation = schemaRule.dataLocation;
          const dataRes = await this.deleteData(
            dataLocation.keySpace,
            this.pattern,
            "Configuration Data for key " + this.pattern + " deleted"
          );
          if (dataRes?.ok) {
            await this.loadPatternData();
            this.reloadTrigger++;
          } else {
            this.$store.commit(
              "SET_ERROR",
              "Something went wrong while deleting data of product"
            );
            this.deleting = false;
            return;
          }
        } else if (this.isSchema) {
          const confirmed = await this.$confirm(
            "Delete Schema?",
            "Are you sure you want to delete the schema of " +
              this.pattern +
              " ?",
            { width: 600 }
          );
          if (!confirmed) return;
          this.runningAction = true;

          const id = this.patternSchema?.id;
          //update or insert the schema rule
          const schemaRes = await this.deleteSchemaRule(
            id,
            "Schema for key " + this.pattern + " deleted"
          );
          if (schemaRes?.ok) {
            if (this.translationKeys.length > 0) {
              const translationRes = await this.deleteTranslations(
                this.translationKeys,
                "SKU",
                this.pattern
              );
              if (!translationRes?.ok) return;
            }

            await this.loadPatternSchema();
            this.reloadTrigger++;
          } else {
            this.$store.commit(
              "SET_ERROR",
              "Something went wrong while deleting schema of product"
            );
            this.deleting = false;
            return;
          }
        }
        this.violation = null;
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    isData() {
      return this.lookupRegion === "data";
    },

    isSchema() {
      return this.lookupRegion === "schema";
    },

    domainLevel() {
      //since the smallest possible domain is system, return at least 1
      const domain = this.$store.state.domains.find(
        (domain) => domain.id === this.selectedDomain
      );
      return domain?.fullPath?.split(".")?.length || 1;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    isInEditMode() {
      return this.$route.query.mode !== "view";
    },

    schemaRule() {
      const domain = this.$store.state.domains.find(
        ({ id }) => this.selectedDomain === id
      );
      return {
        keySpace: "SKU",
        keyPattern: this.pattern,
        domainId: domain.id,
        domainPath: domain.fullPath,
        schema: this.patternSchema?.schema,
      };
    },

    previewShown() {
      return this.patternSchema && this.isInEditMode && this.showPreview;
    },
  },
};
</script>

<style scoped>
.pattern-configuration-editor-container {
  height: 100%;
  padding: 0;
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.pattern-configuration-editor {
  height: 100%;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0;
  position: relative;
}

.pattern-configuration-editor-container.preview-shown
  > .pattern-configuration-editor {
  width: 60%;
  max-width: 60%;
}

.pattern-configuration-editor > .pattern-configuration-editor-form {
  height: calc(100% - 152px);
  overflow: scroll;
}

.pattern-configuration-editor > .pattern-configuration-toolbar {
  margin-bottom: 12px;
}

.pattern-configuration-editor
  > .object.root::v-deep
  > div:not(.primitive-type-container)
  > .controls {
  top: 0px;
}

.pattern-configuration-editor
  > .read-only::v-deep
  > div:not(.primitive-type-container)
  > .controls {
  display: none;
}

.pattern-configuration-editor > .data-editor.object.root.read-only {
  padding-top: 0;
}

/* Schema Merge Preview */

.pattern-configuration-editor-container > .preview-expand-btn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 4px;
  cursor: pointer;
  max-width: 28px;
}

.pattern-configuration-editor-container > .preview-expand-btn:hover,
.pattern-configuration-editor-container > .preview-expand-btn:focus {
  background: #eeeeee;
  cursor: pointer;
  outline: 0;
}

.pattern-configuration-editor-container > .preview-expand-btn > div {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.pattern-configuration-editor-container
  > .preview-expand-btn
  > .toggle-icon-bottom {
  align-items: flex-end;
}

.pattern-configuration-editor-container
  > .preview-expand-btn
  > .toggle-icon-top {
  align-items: flex-start;
}

.pattern-configuration-editor-container
  > .preview-expand-btn
  > .preview-expand-title-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pattern-configuration-editor-container
  > .preview-expand-btn
  > .preview-expand-title-container
  > .preview-expand-title {
  display: flex;
  transform: rotate(270deg);
  white-space: nowrap;
}

.pattern-configuration-editor-container > .schema-previewer-container {
  min-width: 40%;
}
</style>