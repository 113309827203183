<template>
  <div class="localization-entry">
    <v-subheader class="px-0">i18n Key</v-subheader>
    <div class="pl-3 mb-3">
      <v-text-field
        v-model="i18nKey"
        outlined
        dense
        hide-details="auto"
        data-test-id="localizationI18nKey"
        :disabled="disabled"
        :rules="i18nRules"
      />
    </div>
    <div class="d-flex flex-column flex-grow-1">
      <v-subheader class="px-0">
        Localizations
        <v-tooltip
          v-if="!loadingLocalizations && hasUnsavedLocalizations"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small right color="warning"
              >mdi-alert</v-icon
            >
          </template>
          <div>
            Contains unsaved localizations for locales:
            {{ unsavedLocalizations.join(", ") }}
          </div>
        </v-tooltip>
      </v-subheader>
      <div
        ref="localizationEditorContainer"
        class="localizations-editor-container"
      >
        <div v-if="loadingLocalizations" class="loading-overlay">
          <v-overlay>
            <v-progress-circular indeterminate size="64" color="primary" />
          </v-overlay>
        </div>
        <div
          v-else
          :class="{
            'localizations-editor': true,
            expanded: expandLocales,
          }"
          ref="localizationEditor"
          tabindex="-1"
          @focusin="handleFocusIn"
          @focusout="handleFocusOut"
        >
          <I18nEditor
            v-model="localizations"
            :disabled="disabled"
            data-test-id="localizationI18nEditor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import I18nEditor from "../configuration/data/editors/specific/I18nEditor";

export default {
  inject: {
    setTranslation: {
      default() {
        return () => {
          return;
        };
      },
    },

    addTranslationKey: {
      default() {
        return () => {
          return;
        };
      },
    },

    getTranslations: {
      default() {
        return () => {
          return null;
        };
      },
    },
  },

  components: {
    I18nEditor,
  },

  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    keyPattern: {
      type: String,
      required: false,
      default: "*",
    },

    keySpace: {
      type: String,
      required: false,
      default: "*",
    },

    expandLocales: {
      type: Boolean,
      required: false,
      default: false,
    },

    uimodel: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      i18nKey: this.value,
      localizations: null,
      savedLocalizations: null,
      loadingLocalizations: false,
      timeout: 0,
    };
  },

  async mounted() {
    await this.getLocalizations();
    //Notify the parent component of the translation key
    if (this.i18nKey) this.addTranslationKey(this.i18nKey);
  },

  watch: {
    i18nKey(i18nKey) {
      if (!i18nKey) {
        this.$emit("input", undefined);
        return;
      }
      this.$emit("input", i18nKey);
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.addTranslationKey(i18nKey);
        this.getLocalizations();
      }, 300);
    },

    value(value) {
      this.i18nKey = value;
    },

    localizations: {
      handler: function (localizations) {
        const localizationsClone = this.$cloneObject(localizations);

        //only propagate changes, so get the differences between saved and current localzations
        const locales = Object.keys(localizationsClone);
        locales.forEach((locale) => {
          const saved = this.savedLocalizations?.[locale];
          if (this.$isEqual(saved, localizationsClone[locale]))
            this.$delete(localizationsClone, locale);
        });

        this.setTranslation(this.i18nKey, localizationsClone);
      },
      deep: true,
    },
  },

  methods: {
    async getLocalizations() {
      try {
        if (!this.i18nKey) return;
        this.loadingLocalizations = true;
        this.savedLocalizations = null;
        let localizations = this.getTranslations(this.i18nKey);
        if (!localizations) {
          localizations =
            await this.$store.$coreApi.coreLocalizationApi.getLocalizationsByKey(
              this.selectedDomain,
              this.i18nKey,
              this.keySpace,
              this.keyPattern
            );

          if (localizations) {
            this.savedLocalizations = localizations
              ? this.$cloneObject(localizations)
              : {};
          }
        }

        this.localizations = localizations ?? {};
      } finally {
        this.loadingLocalizations = false;
      }
    },

    handleFocusIn() {
      //Set coordinates of opened i18n editor
      const container = this.$refs.localizationEditorContainer;
      const element = this.$refs.localizationEditor;
      let style = element.style;
      style.top = container.offsetTop + "px";
      style.left = container.offsetLeft + "px";
      style.maxWidth = container.offsetWidth + "px";
      style.width = container.offsetWidth + "px";
    },

    handleFocusOut() {
      const container = this.$refs.localizationEditor;
      //if the translation containers is about to be hidden,
      //scroll to top to only show the first translation option
      if (getComputedStyle(container).overflow !== "hidden") return;
      container.scrollTop = 0;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    i18nRules() {
      //if the component is disabled or there are no localizations
      //the i18nkey is not required
      if (this.disabled) return [];
      if (!this.localizations || Object.keys(this.localizations).length === 0)
        return [];
      return [(val) => !!val || "Key is required"];
    },

    hasUnsavedLocalizations() {
      return (
        !!this.unsavedLocalizations && this.unsavedLocalizations.length > 0
      );
    },

    unsavedLocalizations() {
      const savedLocalizations = this.savedLocalizations;
      const localizations = this.localizations ?? {};
      const unsaved = Object.keys(localizations).reduce((array, locale) => {
        const savedValue = savedLocalizations?.[locale];
        const value = localizations?.[locale];
        const hasValue = value !== null && value !== undefined;
        const hasSavedValue = savedValue !== null && savedValue !== undefined;
        if ((hasValue && !hasSavedValue) || !this.$isEqual(value, savedValue)) {
          array.push(locale);
        }
        return array;
      }, []);

      return unsaved;
    },
  },
};
</script>

<style scoped>
.localization-entry {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  padding: 12px;
}

.localization-entry .localizations-editor-container {
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  min-height: 60px;
}

.localization-entry
  .localizations-editor-container
  > .localizations-editor:not(:focus-within):not(.expanded) {
  overflow: hidden;
  height: 60px;
}

.localization-entry > .loading-overlay {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.loading-overlay::v-deep .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.loading-overlay::v-deep .v-overlay--active .v-overlay__scrim {
  opacity: 0 !important;
}

.localizations-editor > .i18n-editor.primitive-type-container {
  align-items: center;
}

.localizations-editor.expanded {
  max-height: 300px;
  overflow-y: scroll;
}

.localizations-editor:not(.expanded):focus-within {
  height: auto;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  z-index: 3;
  border: 1px solid rgb(46, 117, 212);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  outline: none;
  background-color: white;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 0px;
}

.localizations-editor:not(.expanded)::v-deep > .i18n-editor > .i18n-table {
  background: transparent;
}

.localizations-editor::v-deep > .i18n-editor > .i18n-table .label-column,
.localizations-editor::v-deep
  > .i18n-editor
  > .i18n-table
  .label-column
  > .label-input {
  padding-right: 0px;
}

.localizations-editor::v-deep
  > .i18n-editor
  > .i18n-table
  .label-column
  > .label-input
  > .primitive-type-container
  > .controls {
  display: none;
}

.localizations-editor:focus-within::v-deep
  > .i18n-editor
  > .i18n-table
  .label-column
  > .label-input
  > .primitive-type-container
  > .controls {
  display: inline-flex;
}
</style>