<template>
  <DataEditor
    v-model="enumOptions"
    :uimodel="enumOptionsModel"
    :key="pattern"
    class="enum-option-editor"
    @restore="restore"
  />
</template>

<script>
import DataEditor from "components/configuration/data/editors/DataEditor";

export default {
  components: {
    DataEditor,
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    uimodel: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowSchemaRangeChanges: {
      type: Boolean,
      required: false,
      default: true,
    },

    pattern: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      enumOptions: this.value,
      savedEnumOptions: undefined,
    };
  },

  mounted() {
    this.savedEnumOptions =
      this.uimodel?.savedSchema?.enumOptions ??
      this.$cloneObject(this.uimodel?.schema?.enumOptions);
  },

  watch: {
    value(value) {
      this.enumOptions = value;
    },

    enumOptions: {
      handler: function (options) {
        //compare the enum options to the ones of the saved schema to prevent the emitting of the input event
        //if they are equal. This would otherwise lead to problem with the schemas enum array
        //which will be built from the enum options array and may differ from the one loaded from the server
        //due to the lack of an order.
        if (this.$isEqual(options, this.savedEnumOptions)) return;
        this.$emit("input", options);
      },
      deep: true,
    },
  },

  methods: {
    restore() {
      this.enumOptions = Object.assign(
        [],
        this.$cloneObject(this.savedEnumOptions)
      );
    },
  },

  computed: {
    enumOptionsModel() {
      return {
        data: this.value,
        savedData: this.savedEnumOptions,
        mergedData: this.$cloneObject(this.enumOptions),
        parentData: this.uimodel.schema,
        schema: this.enumOptionSchema,
        domainData: [
          {
            domain: this.uimodel.domain,
            data: this.savedEnumOptions,
          },
        ],
        inheritedData: undefined,
        editedDomain: this.uimodel.domain,
        key: this.uimodel.key,
        keySpace: this.uimodel.keySpace,
        path: (this.uimodel.path || "") + "/enumOptions",
        editable: !this.disabled && this.allowSchemaRangeChanges,
      };
    },

    enumOptionSchema() {
      //The data editor builds the edit form
      //for enumOptions according to this schema
      return {
        type: "array",
        items: {
          type: "object",
          required: ["value"],
          properties: {
            value: {
              type: "string",
              pattern: this.pattern,
              strictRequired: true, //Tells the data editor that null and undefined are not allowed
            },
            labels: {
              type: "object",
              properties: {
                i18nKey: {
                  type: "string",
                  dataUI: {
                    editor: "Localization",
                    editable: true,
                    editorParameters: {
                      expandLocales: true,
                    },
                  },
                },
                values: {
                  type: "object",
                  dataUI: {
                    hidden: true,
                  },
                },
              },
            },
            images: {
              type: "object",
              properties: {
                i18nKey: {
                  type: "string",
                  dataUI: {
                    editor: "Localization",
                    editable: true,
                    editorParameters: {
                      expandLocales: true,
                    },
                  },
                },
                values: {
                  type: "object",
                  dataUI: {
                    hidden: true,
                  },
                },
              },
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.enum-option-editor.array::v-deep > div {
  padding-top: 24px;
}
</style>