var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"properties-inheritance-editor",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.propertiesInherited ? 3 : 12}},[_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[_c('v-checkbox',{staticClass:"inherit-properties-switch",attrs:{"data-test-id":"schemaInheritPropertiesSwitch","hide-details":"","title":"Inherit properties","input-value":_vm.schema.inheritProperties,"disabled":_vm.disabled,"false-value":false,"true-value":true,"color":_vm.schema.inheritProperties ? 'orange' : undefined},on:{"change":_vm.changeInheritProperties},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-truncate"},[_vm._v("Inherit Properties")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticClass:"help-tooltip"},[_vm._v(" If turned on, the schema will inherit properties from higher level ")])])]},proxy:true}])})],1)]),(_vm.propertiesInherited)?_c('v-col',[_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[_c('v-combobox',{staticClass:"excluded-properties-combobox",attrs:{"outlined":"","multiple":"","small-chips":"","data-test-id":"schemaExcludePropertiesCombobox","hide-details":"","placeholder":"No properties excluded","label":"Properties excluded from inheritance","value":_vm.excludedProperties,"disabled":_vm.disabled,"items":_vm.displayedProperties},on:{"change":_vm.updateExcludedProperties},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"data-test-id":'schemaProperty_' + item,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.reactivateInheritance(item, parent.selectItem)}}},[_vm._v(" mdi-close-circle ")])],1)]}},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1859502481)},[_c('div',{staticClass:"help-tooltip"},[_vm._v(" Exclude certain properties from inheritance. It is also possible to specifiy property names which do not (yet) exist ")])])]},proxy:true}],null,false,77952859)})],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }