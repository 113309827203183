/** Mixin for schema editor specific methods */
export default {

    methods: {

        restore(property, value, container) {
            if (value === undefined) {
                if (!container?.[property]) return;
                this.$delete(container, property);
                return;
            }
            this.$set(container, property, this.$cloneObject(value));
        },

        getPropertyOrder(properties) {
            //Sort properties according to their sort order
            if (!properties || !this.$isPlainObject(properties)) return undefined;
            const orderedProperties = Object.entries(properties).sort(([, a], [, b]) => {
                const sortOrderA = a?.schemaUI?.sortOrder ?? 99999;
                const sortOrderB = b?.schemaUI?.sortOrder ?? 99999;
                return sortOrderA - sortOrderB; //ascending
            });
            return orderedProperties.map(([property,]) => property);
        },

        getPropertyEditableState(uimodel, mergedSchema) {
            const editableAttributes = uimodel.editableAttributes;
            const hasEditableAttributes = Array.isArray(editableAttributes) && editableAttributes.length > 0;
            const isEditableBySchemaUi = mergedSchema?.schemaUI?.editable;
            let editable = isEditableBySchemaUi ?? true;
            if ((uimodel.editable === false && isEditableBySchemaUi !== true) || hasEditableAttributes) {
                editable = false;
            }
            return editable;
        },

        buildSubUimodels(uimodel) {

            //Use a map to maintain the correct order of the properties,
            //since JS objects do not guarantee order
            let all = new Map();
            const type = uimodel.schema?.type;
            const editableAttributes = uimodel.editableAttributes;
            //build the item sub UI model for array schema
            if (type === 'array') {
                const itemSchema = uimodel.schema?.items;

                const mergedSchema = uimodel.mergedSchema?.items;
                const savedSchema = uimodel.savedSchema?.items;
                const inherited = uimodel.inherited || (itemSchema === undefined && mergedSchema != undefined);
                const editable = this.getPropertyEditableState(uimodel, mergedSchema);

                if (itemSchema) {
                    let items = {
                        schema: itemSchema,
                        path: uimodel.path + "/items",
                        domain: uimodel.domain,
                        name: "ITEMS",
                        mergedSchema,
                        savedSchema,
                        inherited,
                        level: uimodel.level + 1,
                        parentSchema: uimodel.schema,
                        key: uimodel.key,
                        keySpace: uimodel.keySpace,
                        editable
                    }

                    if (itemSchema.type === 'object') {
                        //if the sub ui model is an object, remember the property order
                        //this will be used when re-arranging properties
                        const propertyOrder = itemSchema?.properties ? this.getPropertyOrder(itemSchema?.properties) : [];
                        items.propertyOrder = propertyOrder;
                    }

                    if (uimodel.domainSchemas) {

                        //add the domain information to the sub model
                        items.domainSchemas = [];
                        for (let source of uimodel.domainSchemas) {
                            const schema = source.schema?.items;
                            items.domainSchemas.push({
                                domain: source.domain,
                                schema,
                            });
                        }
                    }

                    if (Array.isArray(editableAttributes)) {
                        items.editableAttributes = editableAttributes;
                    }

                    all.set("items", items);
                }

                const subArrayModel = Object.fromEntries(all);
                return subArrayModel;

            }

            if (type !== 'object' || !uimodel.propertyOrder) return Object.fromEntries(all);

            //build sub UI model for object schema
            const properties = uimodel.schema?.properties ?? {};
            const requiredProps = uimodel.schema?.required ?? [];
            const propertyOrder = uimodel.propertyOrder ?? this.getPropertyOrder(properties);

            for (let p of propertyOrder) {

                const schema = properties[p];
                const path = uimodel.path + "/" + p;
                const mergedSchema = uimodel.mergedSchema?.properties?.[p];
                const savedSchema = uimodel.savedSchema?.properties?.[p];
                const inherited = uimodel.inherited || (schema === undefined && mergedSchema !== undefined);
                const editable = this.getPropertyEditableState(uimodel, mergedSchema);

                const model = {
                    schema,
                    path,
                    domain: uimodel.domain,
                    required: requiredProps.includes(p),
                    name: p,
                    mergedSchema,
                    savedSchema,
                    inherited,
                    level: uimodel.level + 1,
                    parentSchema: uimodel.schema,
                    key: uimodel.key,
                    keySpace: uimodel.keySpace,
                    editable,
                }

                if (schema?.type === 'object') {
                    //if the sub ui model is an object, remember the property order
                    //this will be used when re-arranging properties
                    let propertyOrder = [];
                    if (schema?.properties) propertyOrder = this.getPropertyOrder(schema?.properties);
                    else if (inherited && mergedSchema?.properties) propertyOrder = this.getPropertyOrder(mergedSchema?.properties);
                    model.propertyOrder = propertyOrder;
                }

                if (Array.isArray(editableAttributes)) {
                    model.editableAttributes = editableAttributes;
                }

                all.set(p, model);
            }

            all.forEach((model, property) => {

                //add the domain information to the sub model
                if (uimodel.domainSchemas) {
                    model.domainSchemas = [];
                    for (let source of uimodel.domainSchemas) {
                        const schema = source.schema;
                        model.domainSchemas.push({
                            domain: source.domain,
                            schema: this.$cloneObject(schema?.properties?.[property]) ?? {},
                            keyPattern: source.keyPattern
                        });
                    }
                }
            });
            return Object.fromEntries(all);
        },

        validateSchema(path, schema, errorMessages /* array */) {
            if (path === null) path = "$";
            if (!schema) return;
            //validate the schema according to its type
            const type = schema.type;
            if (type === null || type === "null") errorMessages.push(path + ": type may not be null")
            else if (type === "boolean") return;
            else if (type === "optionEnum") return;
            else if (type === "optionEnumValue") return;
            else if (type === "number" || type === "integer") this.validateNumericSchema(path, schema, errorMessages);
            else if (type === "string") this.validateStringSchema(path, schema, errorMessages);
            else if (type === "array") this.validateArraySchema(path, schema, errorMessages);
            else if (type === "object") this.validateObjectSchema(path, schema, errorMessages);
            else if (type != undefined) errorMessages.push(path + ": invalid type");
        },

        validateObjectSchema(path, schema, errorMessages /* array */) {

            if (schema.properties != undefined) {
                //validate each property
                const properties = schema.properties;
                const propertyNames = Object.keys(properties);
                if (propertyNames.length > 0) {
                    for (let name in properties) {
                        //check if property has name
                        if (!name) {
                            errorMessages.push(path + ": One or more properties have no name")
                        }

                        let propertyPath = path + "/" + name;
                        this.validateSchema(propertyPath, properties[name], errorMessages);
                    }
                }
            }

            if (schema.additionalProperties != undefined) {
                const additionalProperties = schema.additionalProperties;
                const propertyType = this.$getType(additionalProperties);
                if (propertyType === "object") {
                    //if the additionalProperties do have a defined schema, validate it
                    const propertyPath = path + "/additionalProperties";
                    this.validateSchema(propertyPath, additionalProperties, errorMessages)
                }
                else if (propertyType != "boolean") {
                    errorMessages.push(path + ": additionalProperties must either be a valid schema or one of: true, false");
                }
            }
        },

        validateArraySchema(path, schema, errorMessages /* array */) {
            if (schema.minItems != undefined) this.validateSchemaAttribute(schema, "minItems", path, errorMessages)
            if (schema.maxItems != undefined) this.validateSchemaAttribute(schema, "maxItems", path, errorMessages)
            if (schema.items != undefined) {
                path += "/items"
                this.validateSchema(path, schema.items, errorMessages);
            }
        },

        validateStringSchema(path, schema, errorMessages /* array */) {
            if (schema.minLength != undefined) this.validateSchemaAttribute(schema, "minLength", path, errorMessages)
            if (schema.maxLength != undefined) this.validateSchemaAttribute(schema, "maxLength", path, errorMessages)
            if (schema.pattern != undefined && schema.enum != undefined) {
                const pattern = new RegExp(schema.pattern);
                const enums = schema.enum;
                if (Array.isArray(enums)) {
                    for (let value of enums) {
                        if (!pattern.test(value)) {
                            errorMessages.push(path + "/enum: Value '" + value + "' does not match specified pattern");
                        }
                    }
                }
            }
        },

        validateNumericSchema(path, schema, errorMessages /* array */) {
            //iterate over the schema attributes, which can be validated
            for (let attribute of ["minimum", "maximum", "exclusiveMaximum", "exclusiveMinimum"]) {
                //retrieve the validation function according to the attribute
                this.validateSchemaAttribute(schema, attribute, path, errorMessages)
            }
        },

        validateSchemaAttribute(schema, attribute, path, errorMessages) {
            if (schema?.[attribute] === undefined) return;
            //retrieve the validation function according to the attribute
            const validate = this.validationMethods[attribute];
            if (validate === undefined) return;
            const result = validate(schema[attribute], schema);
            const attributePath = path + "/" + attribute;
            if (result != true) errorMessages.push(attributePath + ": " + result);
        }

    },

    computed: {

        validationMethods() {

            //validation functions
            const isEmpty = val => (val === undefined || val === "" || val === null);

            return {

                minimum: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const minimum = Number(val);
                    if (Number.isNaN(minimum, schema)) return "Must be a number";
                    if ((schema.maximum != undefined && minimum > schema.maximum)) return "Must be smaller than or equal maximum"
                    if (schema.exclusiveMaximum != undefined && minimum >= schema.exclusiveMaximum) return "Must be smaller than exclusiveMaximum"
                    return true;
                },
                exclusiveMinimum: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const exclusiveMinimum = Number(val);
                    if (Number.isNaN(exclusiveMinimum, schema)) return "Must be a number";
                    if ((schema.maximum != undefined && exclusiveMinimum >= schema.maximum)) return "Must be smaller than maximum"
                    if (schema.exclusiveMaximum != undefined && (schema.exclusiveMaximum - exclusiveMinimum) < 2) return "Must be smaller than exclusiveMaximum"
                    return true;
                },
                maximum: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const maximum = Number(val);
                    if (Number.isNaN(maximum, schema)) return "Must be a number";
                    if (schema.minimum != undefined && maximum < schema.minimum) return "Must be greater than or equal minimum"
                    if (schema.exclusiveMinimum != undefined && maximum <= schema.exclusiveMinimum) return "Must be greater than exclusiveMinimum"

                    return true;
                },
                exclusiveMaximum: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const exclusiveMaximum = Number(val);
                    if (Number.isNaN(exclusiveMaximum, schema)) return "Must be a number";
                    if (schema.minimum != undefined && exclusiveMaximum <= schema.minimum) return "Must be greater than minimum"
                    if (schema.exclusiveMinimum != undefined && (exclusiveMaximum - schema.exclusiveMinimum) < 2) return "Must be greater than exclusiveMinimum"
                    return true;
                },

                //String
                minLength: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const minLength = Number(val);
                    if (Number.isNaN(minLength)) return "Must be a number"
                    if (minLength < 0) return "Must be a positive value"
                    if (!isEmpty(schema.maxLength) && minLength > schema.maxLength) return 'Must be smaller than maxLength'
                    return true;
                },

                maxLength: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const maxLength = Number(val);
                    if (Number.isNaN(maxLength)) return "Must be a number"
                    if (maxLength < 0) return "Must be a positive value"
                    if (!isEmpty(schema.minLength) && maxLength < schema.minLength) return 'Must be greater than minLength'
                    return true;
                },

                //Arrays
                minItems: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const minItems = Number(val);
                    if (Number.isNaN(minItems)) return "Must be a number"
                    if (minItems < 0) return 'Must be positive'
                    if (!isEmpty(schema.maxItems) && minItems > schema.maxItems) return 'Must be smaller than maxItems'
                    return true;
                },
                maxItems: (val, schema) => {
                    if (isEmpty(val)) return true;
                    const maxItems = Number(val);
                    if (Number.isNaN(maxItems)) return "Must be a number"
                    if (maxItems <= 0) return 'Must greater than 0'
                    if (!isEmpty(schema.minItems) && maxItems < schema.minItems) return 'Must be greater than minItems'
                    return true;
                },
            };
        }

    }

}