<template>
  <v-container fluid class="product-type-editor-container">
    <!-- MAIN CONTENT -->
    <v-container v-if="loadingType" fluid class="loading-overlay">
      <v-overlay>
        <v-progress-circular indeterminate size="96" color="primary" />
      </v-overlay>
    </v-container>
    <v-container v-else-if="productType" fluid class="product-type-editor">
      <v-toolbar dense class="product-type-editor-toolbar">
        <v-tabs grow v-model="selectedTab">
          <v-tab
            :to="{
              name: 'productTypeData',
              params: $route.params,
            }"
            data-test-id="productTypeDataTab"
          >
            Type {{ productType }}
          </v-tab>
          <v-tab
            :to="{
              name: 'productTypeDimensions',
              params: $route.params,
            }"
            data-test-id="productTypeDimensionsTab"
          >
            Dimensions
          </v-tab>
          <v-tab
            :to="{
              name: pattern ? 'productData' : 'productDataSearch',
              params: $route.params,
            }"
            data-test-id="productDataTab"
          >
            Product Properties
          </v-tab>
          <v-tab
            :to="{
              name: pattern ? 'productSchema' : 'productSchemaSearch',
              params: $route.params,
            }"
            data-test-id="productSchemaTab"
          >
            Product Schema
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-container
        v-if="routeName"
        fluid
        class="product-type-editor-tab d-flex flex-column"
      >
        <div
          v-if="routeName.startsWith('productType')"
          class="d-flex flex-row-reverse flex-wrap py-3"
          :data-test-id="
            routeName === 'productTypeData'
              ? 'productTypeDataEditor'
              : 'productTypeDimensionEditor'
          "
        >
          <div class="d-flex">
            <div v-if="loading" class="px-4">
              <v-progress-circular
                indeterminate
                :size="24"
                :width="2"
                color="grey darken-3"
              />
            </div>
            <v-btn
              :outlined="!isDirty"
              :disabled="loading"
              :color="hasViolation ? 'error' : 'green'"
              class="ml-2"
              data-test-id="productTypeSaveBtn"
              @click="save(false)"
            >
              <ViolationAlert
                v-if="hasViolation"
                :violation="violation"
                :color="isDirty ? 'white' : 'error'"
                alignment="left"
              />
              Save
            </v-btn>

            <v-btn
              v-if="deleteable"
              outlined
              :disabled="loading"
              color="red"
              class="ml-2"
              data-test-id="productTypeDeleteBtn"
              @click="remove"
            >
              Delete
            </v-btn>
          </div>
          <div
            class="sku-pattern text-body-1 d-flex flex-grow-1 align-center"
            data-test-id="productTypeSkuPattern"
          >
            SKU Pattern: {{ formattedSkuPattern }}
          </div>
        </div>
        <v-form
          v-if="routeName.startsWith('productType')"
          ref="editorForm"
          class="product-type-editor-form"
        >
          <DataEditor
            v-if="routeName === 'productTypeData'"
            v-model="productTypeData.data"
            :saved-data="productTypeData.savedData"
            :merged-data="productTypeData.mergedData"
            :schema="productTypeData.schema"
            :property="'SKIPASS'"
            :key="routeName + reloadTrigger"
            :key-pattern="productType + '.productType.data'"
            :key-space="keySpace"
            :disabled="loading"
            class="ma-0"
            @restore="
              productTypeData.data = $cloneObject(productTypeData.savedData)
            "
          />

          <SchemaEditor
            v-else-if="routeName === 'productTypeDimensions'"
            v-model="dimensionData.schema"
            :domain="selectedDomain"
            :domain-schemas="dimensionData.domainSchemas"
            :merged-schema="dimensionData.mergedSchema"
            :key-pattern="productType + '.dimensions'"
            :key-space="keySpace"
            :type-editable="false"
            :key="routeName + reloadTrigger"
            :disabled="loading"
            :allow-schema-range-changes="false"
            parent-type="schema"
            name="DIMENSIONS"
            class="ma-0"
            @restore="
              dimensionData.schema = $cloneObject(dimensionData.savedSchema)
            "
          />
        </v-form>
        <v-container
          v-if="isProductEditor"
          fluid
          class="product-configuration-editor-container mt-1"
        >
          <div
            v-show="routeName.endsWith('Search')"
            class="d-flex flex-column pattern-search elevation-6 pa-0"
          >
            <PatternSearch
              v-show="routeName.endsWith('Search')"
              :value="pattern"
              :sku-pattern="skuPattern"
              :product-type="productType"
              :lookup-region="lookupRegion"
              :is-dirty="isDirty"
              @input="(val) => (pattern = sanitizeKeyPattern(val))"
            />
          </div>

          <PatternConfigurationEditor
            v-if="pattern"
            v-model="pattern"
            :product-type="productType"
            :lookup-region="lookupRegion"
            class="pattern-editor"
            ref="patternEditor"
          />
        </v-container>
      </v-container>
    </v-container>
  </v-container>
</template>


<script>
import mixin from "../../mixins/config-service-mixin";
import DataEditor from "../configuration/data/editors/DataEditor";
import SchemaEditor from "../configuration/schema/editors/SchemaEditor";
import PatternSearch from "./pattern/PatternSearch";
import PatternConfigurationEditor from "./pattern/PatternConfigurationEditor";
import ViolationAlert from "../common/display-helpers/ViolationAlert";

export default {
  mixins: [mixin],

  components: {
    DataEditor,
    SchemaEditor,
    PatternSearch,
    PatternConfigurationEditor,
    ViolationAlert,
  },

  props: {
    productType: {
      type: String,
      required: true,
    },
  },

  provide() {
    return {
      setTranslation: this.setTranslation,
      getProductType: this.getProductType,
      getPropertyData: this.getPropertyData,
      addTranslationKey: this.addTranslationKey,
      getViolations: this.getViolations,
      getSource: this.getSource,
    };
  },

  data() {
    return {
      selectedTab: 0,
      productTypes: [],
      loadingType: false,
      loadingProductTypes: false,
      productTypeData: undefined,
      dimensionData: undefined,
      pattern: undefined,
      violation: null,
      showErrors: false,
      loading: false,
      isDirty: false,
      reloadTrigger: 0,
      translations: [],
      translationKeys: [],
      sources: [],
    };
  },

  async created() {
    await this.init();
  },

  watch: {
    "$route.params": function (params) {
      if (params.pattern !== this.pattern) this.pattern = params.pattern;
    },

    isDirty(isDirty) {
      this.$set(this.$route.params, "unsavedChanges", isDirty);
    },

    routeName(name) {
      this.violation = null;
      if (name === "productTypeData") {
        //if the route was changed to product type data and the state is dirty, reset the dimension data
        if (this.isDirty)
          this.dimensionData.schema = this.$cloneObject(
            this.dimensionData.savedSchema
          );
      }
      if (name === "productTypeDimensions") {
        //if the route was changed to product type dimensions and the state is dirty, reset the product type data
        if (this.isDirty)
          this.productTypeData.data = this.$cloneObject(
            this.productTypeData.savedData
          );
      }
      this.isDirty = false;
    },

    async pattern(pattern) {
      if (!pattern) return;
      const params = {
        ...this.$route.params,
        pattern,
        productType: this.productType,
      };
      await this.$router.push({
        name: this.lookupRegion === "data" ? "productData" : "productSchema",
        params,
        query: this.$route?.query,
      });
    },

    "productTypeData.data": {
      handler: function (data) {
        this.isDirty = !this.$isEqual(data, this.productTypeData?.savedData);
      },
      deep: true,
    },

    "dimensionData.schema": {
      handler: function (schema) {
        const savedSchema = this.dimensionData?.savedSchema;
        this.isDirty =
          (!!schema?.properties && !this.$isEqual(schema, savedSchema)) ||
          this.translations.length > 0;
      },
      deep: true,
    },

    translations(translations) {
      const schema = this.dimensionData?.schema;
      this.isDirty =
        (!!schema.properties &&
          !this.$isEqual(schema, this.dimensionData?.savedSchema)) ||
        translations.length > 0;
    },
  },

  methods: {
    async init() {
      try {
        this.loadingType = true;
        await this.loadProductTypeData();
        await this.loadDimensionData();

        if (this.isProductEditor) {
          //if the product configuration is requested, set the given pattern.
          let pattern = this.$route.params.pattern;
          if (!pattern) return;
          this.pattern = this.sanitizeKeyPattern(pattern);
        }
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Error when loading product type configuration! Cause: " + e
        );
      } finally {
        this.loadingType = false;
      }
    },

    async loadProductTypeData() {
      const schemaRule = await this.loadMergedSchema(
        this.selectedDomain,
        "ProductType",
        this.productType + ".productType.data",
        true
      );
      const schema = schemaRule?.schema;
      const dataLocation = schemaRule?.dataLocation;
      const mergedDataRes =
        await this.$store.$coreApi.coreConfigurationApi.getMergedData(
          this.selectedDomain,
          dataLocation.keySpace,
          this.productType,
          schema,
          {
            query: "withInheritanceSources=true",
          }
        );

      const mergedData = mergedDataRes?.data;
      this.sources = mergedDataRes?.sources ?? [];

      //retrive current data
      const fullData = await this.$store.$coreApi.coreConfigurationApi.getData(
        this.selectedDomain,
        dataLocation.keySpace,
        this.productType,
        {
          includeParents: false,
        }
      );

      const currentKeyData = fullData?.[0]?.data;
      //deep clone of data for usage and restore
      const data = this.$cloneObject(currentKeyData);
      const savedData = this.$cloneObject(currentKeyData);

      this.productTypeData = Object.assign(
        {},
        {
          schemaRule,
          schema,
          data,
          savedData,
          mergedData,
        }
      );
      this.reloadTrigger++;
    },

    async loadDimensionData() {
      const key = this.productType + ".dimensions";
      const mergedSchemaRule = await this.loadMergedSchema(
        this.selectedDomain,
        "ProductType",
        key,
        true
      );
      const mergedSchema = mergedSchemaRule.schema;

      //load the schema of each parent domain and key pattern
      const sources = await this.$store.dispatch("get", {
        path: "/schemaRule/ProductType/" + key + "/withParents",
      });

      let domainSchemas = [];
      let schema, savedSchema, schemaRule;
      sources
        .sort((s1, s2) => s1.specificity - s2.specificity)
        .forEach((source) => {
          if (source.domainId === this.selectedDomain) {
            schema = this.$cloneObject(source.schema);
            savedSchema = this.$cloneObject(schema);
            schemaRule = source;
          }
          domainSchemas.push({
            schema: source.schema,
            domain: source.domainId,
          });
        });

      this.dimensionData = Object.assign(
        {},
        {
          schemaRule,
          schema,
          mergedSchema,
          savedSchema,
          domainSchemas,
        }
      );

      this.reloadTrigger++;
    },

    getProductType() {
      return this.productType;
    },

    setTranslation(key, labels) {
      // Parse the keys and labels in the correct form for the translation service
      if (!key || !labels) return;
      this.translations = this.updateTranslations(
        key,
        labels,
        this.translations
      );
    },

    addTranslationKey(i18nKey) {
      if (this.translationKeys.some((translation) => translation === i18nKey))
        return;
      this.translationKeys.push(i18nKey);
    },

    async getPropertyData(params) {
      //Returns the data of given
      if (!params) return null;
      let sourceType = params.sourceType;
      let domain = params.domainId || this.selectedDomain;
      let path = params.path;
      let currentData = null;

      if (sourceType === "data" || sourceType === "schema") {
        let keySpace = params.keySpace;
        let key = params.key;

        if (key?.includes("<productType>"))
          key = key.replace("<productType>", this.productType);
        //Retrieve the data of a certain config key
        if (key && keySpace) {
          //data is in a different key so call the API
          if (sourceType === "data") {
            const merged =
              await this.$store.$coreApi.coreConfigurationApi.getMergedData(
                this.selectedDomain,
                keySpace,
                key,
                null
              );

            currentData = merged?.data;
          } else {
            const merged = await this.loadMergedSchema(domain, keySpace, key);
            currentData = merged?.schema;
          }
        }
      } else if (sourceType === "dimension") {
        //load the data of a product type dimension
        let productType = params.productType;
        let dimensionKey = params.dimensionKey;
        if (!productType) return null;
        if (productType === "<productType>") productType = this.productType;

        currentData = await this.$store.dispatch("get", {
          absolutePath:
            "/" +
            domain +
            "/option/" +
            productType +
            "/dimension" +
            (dimensionKey ? "/" + dimensionKey + "/option" : ""),
        });
      }

      if (!currentData) return null;
      if (!path) return currentData;
      //traverse the given data according to the path and return the property value
      return this.$getObjectValueByPath(currentData, path);
    },

    async save() {
      try {
        if (!this.$validateVForm(this.$refs.editorForm)) {
          this.violation = {
            message: "At least one input is invalid, please check",
          };
          return;
        }
        this.loading = true;
        this.violation = null;
        let keyPattern = "";

        switch (this.routeName) {
          case "productTypeData": {
            keyPattern = this.productType + ".productType.data";
            const schemaRule = this.productTypeData.schemaRule;
            const keySpace = schemaRule.dataLocation?.keySpace;
            //upsert the product type data
            const dataRes = await this.upsertData(
              keySpace,
              this.productType,
              this.productTypeData?.data,
              "Updated data of product type " +
                this.productType +
                " successfully"
            );
            if (dataRes?.status !== 200) {
              if (dataRes?.violation) {
                this.violation = dataRes.violation;
                this.$nextTick(() =>
                  this.$validateVForm(this.$refs.editorForm, true)
                );
              }
              return;
            }

            break;
          }
          case "productTypeDimensions": {
            keyPattern = this.productType + ".dimensions";
            //only save if dimensions were changed
            if (
              !this.$isEqual(
                this.dimensionData?.schema,
                this.dimensionData?.savedSchema
              )
            ) {
              const dimensionRule = this.dimensionData.schemaRule;
              //if the schema rule has no ID, create a new one
              let id = dimensionRule?.id;
              if (!id) id = this.$uuid.v4();
              //upsert the dimension schema
              const schemaRes = await this.upsertSchemaRule(
                id,
                this.keySpace,
                keyPattern,
                undefined,
                this.dimensionData.schema,
                "Updated dimensions of product type " +
                  this.productType +
                  " successfully"
              );
              if (schemaRes?.status !== 200) {
                if (schemaRes?.violation) {
                  this.violation = schemaRes.violation;
                  this.$nextTick(() =>
                    this.$validateVForm(this.$refs.editorForm, true)
                  );
                }
                return;
              }
            }
            break;
          }
          default:
            return;
        }

        if (this.translations.length > 0) {
          const res = await this.upsertTranslations(
            this.translations,
            this.keySpace,
            keyPattern
          );
          if (!res?.ok) {
            const response = await res.json();
            this.$store.commit(
              "SET_ERROR",
              "Something went wrong while updating translations! " +
                response.message
            );
            this.loading = false;
            return;
          } else {
            this.$store.commit(
              "SET_SUCCESS",
              "Updated translations successfully"
            );
          }
          this.translations = [];
        }

        if (this.routeName === "productTypeDimensions") {
          await this.loadDimensionData();
        } else if (this.routeName === "productTypeData") {
          await this.loadProductTypeData();
        }
      } finally {
        this.loading = false;
      }
    },

    async remove() {
      try {
        this.loading = true;
        switch (this.routeName) {
          case "productTypeData":
            {
              const confirmed = await this.$confirm(
                "Delete configuration?",
                "Are you sure you want to delete the configuration of " +
                  this.productType +
                  " ?"
              );
              if (!confirmed) return;

              const schemaRule = this.productTypeData.schemaRule;
              const dataLocation = schemaRule.dataLocation;
              const dataRes = await this.deleteData(
                dataLocation.keySpace,
                this.productType
              );
              //upsert the product type data
              if (!dataRes?.ok) {
                this.$store.commit(
                  "SET_ERROR",
                  "Something went wrong while deleting data of product type " +
                    this.productType
                );
                this.loading = false;
                return;
              }

              await this.loadProductTypeData();
            }
            break;

          case "productTypeDimensions":
            {
              const confirmed = await this.$confirm(
                "Delete dimensions?",
                "Are you sure you want to delete the dimensions of " +
                  this.productType +
                  " ?"
              );
              if (!confirmed) return;

              const dimensionRule = this.dimensionData.schemaRule;
              //upsert the dimension schema
              const schemaRes = await this.deleteSchemaRule(
                dimensionRule.id,
                "Successfully deleted dimensions of product type " +
                  this.productType
              );
              if (!schemaRes?.ok) {
                this.$store.commit(
                  "SET_ERROR",
                  "Something went wrong while deleting dimensions of product type " +
                    this.productType
                );
                this.loading = false;
                return;
              }

              if (this.translationKeys.length > 0) {
                const translationRes = await this.deleteTranslations(
                  this.translationKeys,
                  this.keySpace,
                  this.productType + ".dimensions"
                );
                if (!translationRes?.ok) return;
              }

              await this.loadDimensionData();
            }

            break;
          default:
            return;
        }
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    routeName() {
      return this.$route.name;
    },

    keySpace() {
      return "ProductType";
    },

    formattedSkuPattern() {
      //returns the formatted SKU pattern for display
      if (!this.skuPattern) return "";
      return this.skuPattern.replace("<productType>", this.productType);
    },

    skuPattern() {
      return this.productTypeData?.mergedData?.skuPattern;
    },

    isProductEditor() {
      let namedRoute = this.routeName;
      return (
        namedRoute.startsWith("productData") ||
        namedRoute.startsWith("productSchema")
      );
    },

    lookupRegion() {
      if (this.routeName.startsWith("productData")) return "data";
      if (this.routeName.startsWith("productSchema")) return "schema";
      return null;
    },

    deleteable() {
      //checks if the data/dimensions of the product type may be deleted
      if (this.routeName === "productTypeData")
        return !!this.productTypeData?.savedData;
      if (this.routeName === "productTypeDimensions")
        return !!this.dimensionData?.savedSchema;
      return false;
    },

    hasViolation() {
      return !!this.violation;
    },
  },
};
</script>

<style scoped>
.product-type-editor-container {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  padding: 0;
  overflow-y: hidden;
}

.product-type-editor-container > .product-type-editor-menu {
  position: relative;
}

.product-type-editor-container > .product-type-editor {
  padding: 12px 0 0 0;
  position: relative;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-toolbar
  .v-btn
  + .v-btn {
  margin-left: 6px;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab {
  height: calc(100% - 48px);
  max-width: calc(100% - 92px);
  margin-top: 8px;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab
  > .schema-editor {
  max-width: 100%;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab
  > .sku-pattern {
  text-align: left;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab
  > .product-type-editor-form {
  height: calc(100% - 64px);
  overflow: scroll;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab
  > .product-type-editor-form::v-deep
  > .schema-editor {
  height: 100%;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab
  > .product-type-editor-form::v-deep
  > .schema-editor
  > .json-schema-editor,
.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab
  > .product-type-editor-form::v-deep
  > .schema-editor
  > .schema-reader {
  margin-top: 12px;
  height: calc(100% - 76px);
  overflow-y: scroll;
}

.product-type-editor-container
  > .product-type-editor
  > .product-type-editor-tab
  > .product-configuration-editor-container {
  padding: 0;
  position: relative;
  height: 100%;
}

.product-type-editor-container .loading-overlay {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.product-type-editor-container .loading-overlay::v-deep .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.product-type-editor-container
  .loading-overlay::v-deep
  .v-overlay--active
  .v-overlay__scrim {
  opacity: 0 !important;
}
</style>