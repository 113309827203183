<!--
Inspiration: https://github.com/YMFE/json-schema-editor-visual
-->
<template>
  <v-container
    v-if="!uimodel.hide"
    fluid
    :class="{
      'schema-editor': true,
      [type]: true,
      'show-items': showItems,
      'show-json': showJSONEditor,
      'show-reader': showReadOnly,
      invalid: isInvalid,
      changed: restoreable,
      'name-hidden': hideName,
    }"
    :key="type"
    :data-test-id="'schema_editor_' + uimodel.path"
  >
    <v-input
      class="schema-editor-input"
      hide-details="auto"
      error-count="3"
      :error-messages="violations"
    >
      <v-container>
        <v-btn
          icon
          v-if="isComplex && !showJSONEditor && !showReadOnly"
          @click="showItems = !showItems"
        >
          <v-icon v-if="!showItems">mdi-chevron-right</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
        <v-text-field
          v-if="!hideName"
          outlined
          dense
          type="text"
          hide-details="auto"
          :value="uimodel.name"
          @input="changeName"
          :rules="[propertyNameRules]"
          :ref="'propertyName'"
          :disabled="
            parentType === 'array' ||
            parentType === 'schema' ||
            isDisabled ||
            uimodel.inherited
          "
          placeholder="Property Name"
          class="property-name"
        />
        <v-select
          v-if="!showJSONEditor && !showReadOnly"
          dense
          outlined
          hide-details="auto"
          v-model="type"
          :items="types"
          :disabled="!typeEditable || isDisabled"
          tabindex="-1"
          class="type-select"
          data-test-id="schemaTypeSelect"
        />
        <div
          class="controls pr-1"
          :data-test-id="'schema_editor_controls_' + uimodel.path"
        >
          <v-btn
            v-if="uimodel.level === 0 && !extViewMode"
            icon
            title="Read-only mode"
            data-test-id="readOnlyBtn"
            :color="showReadOnly ? 'primary' : 'black'"
            @click="showReadOnly = true"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>

          <v-divider
            vertical
            inset
            v-if="uimodel.level === 0 && !extViewMode"
          />

          <v-btn
            v-if="!extViewMode"
            icon
            title="Edit in Form"
            data-test-id="formEditorBtn"
            :color="!showJSONEditor && !showReadOnly ? 'primary' : 'black'"
            @click="
              showReadOnly = false;
              showJSONEditor = false;
            "
          >
            <v-icon>mdi-form-textbox</v-icon>
          </v-btn>

          <v-divider vertical inset v-if="showJSONEditorBtn" />

          <v-btn
            v-if="showJSONEditorBtn"
            icon
            data-test-id="jsonEditorBtn"
            title="Edit in JSON"
            :color="showJSONEditor && !showReadOnly ? 'primary' : 'black'"
            :disabled="isDisabled"
            @click="
              showReadOnly = false;
              showJSONEditor = true;
            "
          >
            <v-icon>mdi-code-json</v-icon>
          </v-btn>

          <v-divider
            vertical
            inset
            v-if="!showJSONEditor && !showReadOnly && !extViewMode"
          />

          <v-btn
            v-if="!showJSONEditor && !showReadOnly"
            icon
            class="control configure"
            title="Show attributes"
            data-test-id="showAttributesBtn"
            @click="editAttributes = !editAttributes"
          >
            <v-icon v-if="isInvalid" color="red"> mdi-clipboard-alert </v-icon>
            <v-icon v-else :color="editAttributes ? 'primary' : 'darkgrey'">
              mdi-clipboard-text
            </v-icon>
          </v-btn>

          <v-divider
            vertical
            inset
            v-if="restoreable && (!isDisabled || hasEditor) && !showReadOnly"
          />

          <v-btn
            v-if="restoreable && (!isDisabled || hasEditor)"
            icon
            color="orange"
            :title="'Restore to ' + JSON.stringify(uimodel.savedSchema)"
            @click="$emit('restore')"
          >
            <v-icon>mdi-restore</v-icon>
          </v-btn>

          <v-divider
            vertical
            inset
            v-if="removeable && !isDisabled && !showReadOnly"
          />

          <v-btn
            v-if="removeable && !isDisabled"
            icon
            color="red"
            class="remove"
            title="Remove schema"
            @click="$emit('remove', uimodel.name)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
        <div class="enum-preview pl-2">
          <div
            v-if="hasEnums"
            class="text-truncate"
            :title="schema.enum.join(', ')"
          >
            {{ enumItemPreview }}
          </div>
        </div>
      </v-container>
    </v-input>
    <div v-if="showReadOnly" class="schema-reader">
      <Reader :schema="uimodel.mergedSchema" data-test-id="schemaReaderView" />
    </div>

    <JsonSchemaEditor
      v-else-if="showJSONEditor"
      data-test-id="schemaJsonEditor"
      :value="schema"
      :disabled="isDisabled && !hasEditor"
      :property="uimodel.name"
      @input="(val) => (schema = val)"
    />

    <!-- SCHEMA ATTRIBURES -->
    <v-form ref="schemaAttributes">
      <SchemaAttributes
        v-if="editAttributes && !showJSONEditor && !showReadOnly"
        :value="schema"
        :uimodel="uimodel"
        :disabled="isDisabled"
        :allow-schema-range-changes="allowSchemaRangeChanges"
        @input="(val) => (schema = val)"
        @close="editAttributes = false"
      />
    </v-form>

    <!-- OBJECT PROPERTIES AND ARRAY ITEMS -->
    <div
      v-show="isComplex && showItems && !showJSONEditor && !showReadOnly"
      :class="{
        items: true,
        'has-editor': !!editor,
      }"
      data-test-id="schemaFormEditor"
    >
      <Component
        v-if="hasEditor && editor"
        v-bind="{ ...editorParameters }"
        :is="editor"
        :value="schema"
        :uimodel="uimodel"
        @input="schema = $event"
        @remove="remove"
        @restore="$emit('restore')"
      />

      <div v-else-if="type === 'object'">
        <div>
          <v-toolbar flat height="36">
            <v-subheader>Properties</v-subheader>
            <v-divider vertical />
            <v-btn
              icon
              v-if="!isDisabled"
              @click="addProperty"
              color="primary"
              class="button add"
              title="Add property"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- TODO: Activate for sorting properties via drag and drop
                    <div
                        @dragstart.stop="dragstart"
                        @dragenter.stop.prevent
                        @dragover.stop.prevent="dragover"
                        @dragleave.stop.prevent="dragleave"
                        @drop.stop.prevent="drop"
                        name="properties"
                        v-if="subUiModels"
                    > -->

          <div v-if="subUiModels">
            <SchemaEditor
              v-for="(prop, index) of uimodel.propertyOrder"
              :key="index"
              name="schema-editor"
              :uimodel="subUiModels[prop]"
              :disabled="isDisabled"
              @input="(val) => setProperty(subUiModels[prop].name, val)"
              @remove="remove"
              @name-change="(val) => updatePropertyName(prop, val)"
              @restore="
                restore(prop, subUiModels[prop].savedSchema, schema.properties)
              "
              parent-type="object"
            />
          </div>
          <!-- TODO: Activate for sorting properties via drag and drop   
                            draggable="true"
                        />
                    </div> -->
        </div>
        <div>
          <v-toolbar flat height="36">
            <v-subheader>Additional Properties</v-subheader>
            <v-divider vertical />
            <select
              class="additional-properties-select"
              v-model="additionalProperties"
              :disabled="isDisabled"
              tabindex="-1"
            >
              <option :value="undefined">UNDEFINED</option>
              <option :value="false">NONE</option>
              <option :value="true">ANY</option>
              <option :value="null">DEFINED SCHEMA</option>
            </select>
          </v-toolbar>

          <SchemaEditor
            v-if="additionalProperties === null"
            parentType="schema"
            v-model="schema.additionalProperties"
            :disabled="isDisabled"
            class="additional-properties-editor"
            @restore="
              restore(
                'additionalProperties',
                uimodel.savedSchema
                  ? uimodel.savedSchema.additionalProperties
                  : undefined,
                schema
              )
            "
          />
        </div>
      </div>
      <div v-else-if="type === 'array'">
        <v-btn
          text
          v-if="!schema.items && !isDisabled"
          @click="$set(schema, 'items', { type: 'string' })"
        >
          <v-icon left>mdi-plus</v-icon> Add item schema
        </v-btn>
        <SchemaEditor
          v-else
          :uimodel="subUiModels.items"
          :disabled="isDisabled"
          @input="(val) => setProperty(subUiModels.items.name, val)"
          @remove="remove"
          @restore="restore('items', subUiModels.items.savedSchema, schema)"
          parent-type="array"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
/*

TODO: inherit schema from parent domains and higher ranked key patterns
TODO: Show a user that a property was added
TODO: Add support for pattern properties in object schemas
TODO: Validate schema when saving

TODO: Check if property name is already existing
    --> Difficult in JSON editor, since the value is parsed before validation and JSON.parse just overrides the first property
        if another one exists with the same name

*/
import mixin from "mixins/schema-editor-mixin";
import JsonSchemaEditor from "./JsonEditor";
import SchemaAttributes from "./attributes/Attributes";
import Reader from "../reader/Reader";
import Vue from "vue";

export default {
  mixins: [mixin],

  inject: {
    getViolations: {
      default() {
        console.warn("Parent does not provide getViolations function");
        return () => {
          return [];
        };
      },
    },
  },

  components: {
    SchemaEditor: () => import("./SchemaEditor"),
    JsonSchemaEditor,
    SchemaAttributes,
    Reader,
  },

  name: "SchemaEditor",

  props: {
    value: {
      type: Object,
      required: false,
    },

    name: {
      type: String,
      required: false,
      default: "SCHEMA",
    },

    uimodel: {
      type: Object,
      required: false,
      default: () => {
        return { domainSchemas: null };
      },
    },

    //Defines the type of the parent schema.
    //Type "schema" is used for the root
    parentType: {
      type: String,
      required: false,
    },

    //All schemas from other domains and pattern from which the current schema is inheriting
    // only used for first entry, will then be split up into uimodel
    domainSchemas: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    //The merged root schema
    // only used for first entry, will then be split up into uimodel
    mergedSchema: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    savedSchema: {
      type: Object,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    //Key space of the current schema
    // only used for first entry, will then be split up into uimodel
    keySpace: {
      type: String,
      required: false,
    },

    //Key pattern of the current schema
    // only used for first entry, will then be split up into uimodel
    keyPattern: {
      type: String,
      required: false,
    },

    //Defines, if the schema type may be edited
    typeEditable: {
      type: Boolean,
      required: false,
      default: true,
    },

    //Hides the name textfield
    hideName: {
      type: Boolean,
      required: false,
      default: false,
    },

    //An array of allowed schema types
    //will influence the possible types in the type selection
    allowedTypes: {
      type: Array,
      required: false,
      validator: (types) => {
        return Array.isArray(types);
      },
    },

    //set the view mode outside of the schema editor
    //Will disable switching between modes via controls
    extViewMode: {
      type: String,
      required: false,
      validator: (mode) => {
        return ["view", "edit", "json"].includes(mode);
      },
    },

    disableRestore: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowSchemaRangeChanges: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      schema: this.uimodel.schema ?? this.value,
      type: this.uimodel.schema?.type ?? this.value?.type,
      timeout: 0,
      editAttributes: false,
      showItems: true,
      additionalProperties: true,
      showJSONEditor: false,
      showReadOnly: false,
      isInvalid: false,
      editor: null,
    };
  },

  async mounted() {
    if (this.parentType === "schema" && !this.uimodel.schema) {
      this.$set(this.uimodel, "schema", this.value);
      const savedSchema = this.$cloneObject(
        this.savedSchema ?? this.value ?? undefined
      );
      this.$set(this.uimodel, "savedSchema", savedSchema);
      this.$set(this.uimodel, "name", this.name);
      this.$set(this.uimodel, "required", true);
      this.$set(this.uimodel, "level", 0);
      this.$set(this.uimodel, "mergedSchema", this.mergedSchema);
      this.$set(this.uimodel, "domainSchemas", this.domainSchemas);
      this.$set(this.uimodel, "path", "$");
      this.$set(this.uimodel, "keySpace", this.keySpace);
      this.$set(this.uimodel, "key", this.keyPattern);
      this.$set(this.uimodel, "editable", !this.disabled);
      const editableAttributes =
        this.uimodel.mergedSchema?.schemaUI?.editorParameters
          ?.editableAttributes;
      if (Array.isArray(editableAttributes)) {
        this.$set(this.uimodel, "editableAttributes", editableAttributes);
      }

      if (this.value === undefined && this.mergedSchema !== undefined) {
        //if the current schema is not defined, but a merged one exists,
        //flag the uimodel as inherited and use the merged schema to built the
        //schema editor
        this.$set(this.uimodel, "inherited", true);
        this.schema = this.$cloneObject(this.mergedSchema);
      }

      if (this.schema?.type === "object") {
        let propertyOrder = this.schema?.properties
          ? Object.keys(this.schema?.properties)
          : [];
        this.$set(this.uimodel, "propertyOrder", propertyOrder);
      }

      if (this.keySpace !== "Configuration") this.showReadOnly = true;
    }

    if (this.uimodel.schema?.type === "object") {
      if (
        this.$getType(this.uimodel.schema?.additionalProperties) === "object"
      ) {
        this.additionalProperties = null;
      } else {
        this.additionalProperties = this.uimodel.schema?.additionalProperties;
      }
    }

    if (this.hasEditor) {
      await this.loadEditor();
      if (this.uimodel.inherited) {
        //if the schema is undefined, but it is has an editor,
        //clone the merged schema and remove its properties object so the whole
        //schema editor is rendered correctly up to the editor without changing the schema
        let schema = this.$cloneObject(this.uimodel.mergedSchema);
        this.$delete(schema, "properties");
        this.$delete(schema, "required");
        this.$delete(schema, "nonRequired");
        this.schema = schema;
      }
    }

    const extViewMode = this.extViewMode;
    if (extViewMode) {
      this.showJSONEditor = extViewMode === "json";
      this.showReadOnly = extViewMode === "view";
    }

    //initial validation
    this.$nextTick(() => this.validate());
  },

  watch: {
    schema: {
      handler: function (schema) {
        //trigger validation
        this.$nextTick(() => this.validate());
        this.$emit("input", schema);
      },
      deep: true,
    },

    value(val) {
      this.$set(this.uimodel, "schema", val);
    },

    type(type) {
      if (this.schema?.type === type) return;
      const schema = { type };
      //Takeover possible dataUI and schemaUI, so that the editable
      //behaviour does not change whe type is changed
      if (this.schema?.dataUI) this.$set(schema, "dataUI", this.schema.dataUI);
      if (this.schema?.schemaUI)
        this.$set(schema, "schemaUI", this.schema.schemaUI);
      this.schema = Object.assign({}, schema);
    },

    "uimodel.schema": {
      handler: function (schema) {
        this.type = schema?.type;
        if (this.$isEqual(this.schema, schema)) return;
        this.schema = schema;
      },
      deep: true,
    },

    "schema.additionalProperties": function (val) {
      if (this.$isPlainObject(val)) {
        this.additionalProperties = null;
        return;
      }
      this.additionalProperties = val;
    },

    additionalProperties(val) {
      if (val === undefined) {
        this.$delete(this.schema, "additionalProperties");
        return;
      }

      if (val === true || val === false) {
        //additionalProperties == true/false
        this.$set(this.schema, "additionalProperties", val);
        return;
      }

      if (!this.$isPlainObject(this.schema.additionalProperties)) {
        this.$set(this.schema, "additionalProperties", { type: "string" });
      }
    },

    showJSONEditor(isShown) {
      //validate the schema when switching from json to editor
      let view = "json";
      if (!isShown) {
        view = this.showReadOnly ? "view" : "edit";
        this.$nextTick(() => this.validate());
      }
      this.$emit("update:view", view);
    },

    showReadOnly(isShown) {
      let view = "view";
      if (!isShown) {
        view = this.showJSONEditor ? "json" : "edit";
      }
      this.$emit("update:view", view);
    },

    extViewMode(mode) {
      this.showJSONEditor = mode === "json";
      this.showReadOnly = mode === "view";
    },

    violations: {
      handler: function () {
        this.validate();
      },
      deep: true,
    },
  },

  methods: {
    setProperty(property, schema) {
      if (this.schema?.type !== "array") {
        if (!this.schema) this.schema = { type: "object" };
        if (!this.schema.properties) this.$set(this.schema, "properties", {});

        if (this.$isEqual(this.schema.properties[property], schema)) return;
        this.$set(this.schema.properties, property, schema);

        if (!this.uimodel.propertyOrder)
          this.$set(this.uimodel, "propertyOrder", []);
        if (!this.uimodel.propertyOrder.includes(property))
          this.uimodel.propertyOrder.push(property);
      } else {
        //Array type, so update the item schema
        this.$set(this.schema, "items", schema);
      }
    },

    addProperty() {
      let name = "new";
      let count = 1;

      if (this.schema.properties) {
        //create a new name for the new property
        while (this.schema?.properties[name + count]) {
          count++;
        }
      }
      //make sure the items are shown if a property or item is added
      this.showItems = true;
      this.setProperty(name + count, { type: "string" });
    },

    remove(name) {
      if (this.schema.type === "array") {
        //remove the item schema
        this.$delete(this.schema, "items");
        return;
      }

      let schema = this.$cloneObject(this.schema);

      //Remove also from required array
      if (schema.required) {
        let idx = schema.required.indexOf(name);
        if (idx > -1) schema.required.splice(idx, 1);
      }

      //remove the property with the given name
      delete schema.properties[name];

      if (Object.keys(schema.properties).length === 0) {
        //if object has no declared properties anmyore, remove the property object
        delete schema.properties;
      }

      this.schema = Object.assign({}, schema);
    },

    changeName(value) {
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        //change the name of the current property
        //calls updatePropertyName on parent
        this.$emit("name-change", value);
      }, 300);
    },

    updatePropertyName(oldName, newName) {
      //update the property name of a child schema
      if (oldName === newName) return;

      //check if property with same name already exists
      if (this.uimodel.propertyOrder.indexOf(newName) > -1) return;

      //replace the old property name with the new one, copy the value and delete the old key
      let properties = Object.assign({}, this.schema.properties, {
        [newName]: this.schema.properties[oldName],
      });
      delete properties[oldName];

      //if a requried property, replace the old with the new property name in the array
      if (this.schema.required) {
        let idx = this.schema.required.indexOf(oldName);
        this.schema.required.splice(idx, 1, newName);
      }

      //replace the property in the property order array
      const idx = this.uimodel.propertyOrder.indexOf(oldName);
      if (idx > -1) this.$set(this.uimodel.propertyOrder, idx, newName);
      this.orderProperties(properties);
    },

    orderProperties(properties) {
      //bring the schema properties in the correct order
      //according to the uimodels propertyOrder array
      let ordered = new Map();
      this.uimodel.propertyOrder.forEach((property) => {
        ordered.set(property, properties[property]);
      });
      this.schema.properties = Object.assign({}, Object.fromEntries(ordered));
    },

    validate() {
      if (
        (this.$refs?.schemaAttributes &&
          !this.$refs.schemaAttributes.validate()) ||
        this.violations.length > 0
      ) {
        this.isInvalid = true;
        return;
      }

      if (this.uimodel.propertyOrder) {
        //update the property order if object is valid
        this.uimodel.propertyOrder = this.schema?.properties
          ? Object.keys(this.schema.properties)
          : [];
      }
      this.isInvalid = false;
    },

    async loadEditor() {
      try {
        const editor =
          this.schema?.schemaUI?.editor ||
          this.uimodel?.mergedSchema?.schemaUI?.editor;
        //eslint-disable-next-line
        const comp = () =>
          import("./specific/" + editor + ".vue").catch((e) => {
            this.editor = null;
            console.error("Error: Could not load editor. " + e);
          });
        this.editor = Vue.component(editor, comp);
      } catch (e) {
        this.editor = null;
        console.error("Error: Could not load editor. " + e);
      }
    },
  },

  computed: {
    types() {
      const types = [
        { text: "STRING", value: "string" },
        { text: "NUMBER", value: "number" },
        { text: "INTEGER", value: "integer" },
        { text: "BOOLEAN", value: "boolean" },
        { text: "ARRAY", value: "array" },
        { text: "OBJECT", value: "object" },
      ];

      if (this.allowedTypes && Array.isArray(this.allowedTypes)) {
        return types.filter((type) =>
          this.allowedTypes.some((allowedType) => allowedType === type.value)
        );
      }

      return types;
    },

    subUiModels() {
      return this.buildSubUimodels(this.uimodel);
    },

    propertyNameRules() {
      return (val) => {
        if (val === undefined || val === "" || val === null)
          return "Name is required";
        return true;
      };
    },

    restoreable() {
      return (
        !this.disableRestore &&
        this.uimodel?.savedSchema !== undefined &&
        !this.$isEqual(this.uimodel.savedSchema, this.schema)
      );
    },

    removeable() {
      return (
        this.parentType !== "schema" &&
        !this.isDisabled &&
        !this.uimodel.inherited
      );
    },

    hasEditor() {
      if (this.editor !== null) return true;
      return !!(
        this.schema?.schemaUI?.editor ||
        this.uimodel?.mergedSchema?.schemaUI?.editor
      );
    },

    editorName() {
      return (
        this.schema?.schemaUI?.editor ??
        this.uimodel?.mergedSchema?.schemaUI?.editor
      );
    },

    editorParameters() {
      if (this.uimodel.inherited)
        return this.uimodel?.mergedSchema?.schemaUI?.editorParameters;
      return this.schema?.schemaUI?.editorParameters;
    },

    isComplex() {
      const schema =
        this.schema ?? (this.uimodel.mergedSchema || this.mergedSchema);
      return schema?.type === "object" || schema?.type === "array";
    },

    showJSONEditorBtn() {
      //hide the JSON editor btn, if the view mode is switched externally
      //or if the schema editor is disabled and has no external editor component
      return !this.extViewMode && (!this.isDisabled || this.hasEditor);
    },

    violations() {
      if (this.hasEditor && this.editorName === "OptionEnumEditor") {
        return this.getViolations(null, { returnObject: true }).map(
          (violation) => {
            const idx = violation.property.lastIndexOf(".");
            const property = violation.property.substring(idx + 1);
            return property + ": " + violation.message;
          }
        );
      }

      const path = this.uimodel?.path?.replaceAll("/", ".");
      return this.getViolations(path);
    },

    hasEnums() {
      return this.type === "string" && Array.isArray(this.schema?.enum);
    },

    enumItemPreview() {
      const enumItems = this.schema?.enum ?? [];
      return enumItems.join(", ");
    },

    isEditableBySchemaUI() {
      return !!this.uimodel.editable;
    },

    isDisabled() {
      if (this.disabled) return true;
      return !this.isEditableBySchemaUI;
    },
  },
};
</script>

<style scoped>
.schema-editor {
  background-color: white;
}

.schema-editor.container {
  padding: 8px;
  margin: 8px 0px 8px 50px;
  width: calc(100% - 24px);
  height: fit-content;
}

.schema-editor > .schema-editor-input .container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin-left: 0;
}

.schema-editor > .schema-reader {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.schema-editor > .json-schema-editor {
  padding: 0 12px;
}

.schema-editor.changed > .schema-editor-input .container > .type-select,
.schema-editor.changed.show-json
  > .schema-editor-input
  .container
  > .property-name
  .v-input__slot {
  border-right: 4px solid orange;
  border-radius: 5px;
}

.schema-editor.object,
.schema-editor.array,
.schema-editor.additional-properties-editor,
.schema-editor.show-json {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 12px 0px 12px 12px;
}

.schema-editor.container.invalid:not(.show-json) {
  border: 2px solid red !important;
  border-radius: 5px;
  padding: 4px !important;
}

.schema-editor input,
.schema-editor select {
  border: 1px solid silver;
  padding: 4px;
  border-radius: 3px;
  background-color: white;
  -moz-appearance: auto;
  -webkit-appearance: auto;
}

.schema-editor .invalid-icon {
  margin: 0px 12px 0px 6px;
}

.schema-editor div.controls {
  display: flex;
  align-items: baseline;
  background-color: #fafafa;
  padding-left: 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid #ddd;
  border-left: 0;
  height: 40px;
  margin-left: -4px;
  margin-right: 4px;
}

.schema-editor.show-json.name-hidden div.controls {
  margin-left: 0;
  padding-left: 0;
  border-left: 1px solid #ddd;
}

.schema-editor .type-select {
  max-width: 150px;
  background-color: white;
  margin-left: 12px;
}

.schema-editor.name-hidden > .schema-editor-input .container > .type-select {
  margin: 0;
}

.schema-editor .enum-preview {
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 400px;
}

.schema-editor .additional-properties-select {
  margin-left: 12px;
}

.schema-editor .property-name {
  max-width: 200px;
}

.schema-editor .items {
  margin-top: 12px;
  padding: 12px 12px 12px 20px;
  box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%),
    inset 0px -4px 8px -5px rgb(50 50 50 / 75%);
}

.schema-editor .items.has-editor {
  height: calc(100% - 50px);
}

.controls > .v-divider--vertical {
  margin-left: 6px;
  margin-right: 6px;
}

.schema-editor .items::v-deep .v-toolbar__content,
.schema-editor .items::v-deep .v-toolbar__content .v-subheader {
  padding-left: 0px;
}
</style>