<template>
  <v-container
    fluid
    :class="{
      'json-editor-container': true,
      invalid: hasErrors,
      root: uimodel.dataLevel === 0,
    }"
  >
    <label
      v-if="showLabel === true && label"
      class="label"
      :title="uimodel.property"
    >
      {{ label }}
    </label>
    <textarea ref="editor" />
    <Controls
      :uimodel="uimodel"
      :show-label="showLabel"
      :editor-shown="true"
      :hide-view-controls="hideViewControls"
      @remove="$emit('remove')"
      @restore="restore"
      @toggle-inherit="toggleInherit"
      @type-change="$emit('type-change', $event)"
      @toggle-editor="toggleEditor"
      @enable-readonly="$emit('enable-readonly')"
    />

    <v-tooltip top v-if="hasErrors" v-model="showErrors" allow-overflow>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="red" class="error-alert">
          mdi-alert-circle-outline
        </v-icon>
      </template>
      <ol>
        <li v-for="(error, index) in validationErrors" :key="index">
          {{ error }}
        </li>
      </ol>
    </v-tooltip>
  </v-container>
</template>

<script>
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/xq-light.css";
import "codemirror/mode/javascript/javascript.js";

import Controls from "./controls/Controls";

export default {
  props: {
    value: {
      type: [Object, Array],
      required: false,
    },

    uimodel: {
      type: Object,
      required: false,
      default: () => {
        return { domainData: null };
      },
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },

    removed: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideViewControls: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Controls,
  },

  data() {
    return {
      editor: null,
      timeout: 0,
      validator: null,
      validationErrors: [],
      showErrors: false,
    };
  },

  watch: {
    value(value) {
      const data = this.editor.getValue();
      if (value === undefined || value === null) {
        //inherited data so disable editor
        this.editor.setValue("");
        this.editor.setOption("readOnly", "nocursor");
        return;
      }

      this.editor.setOption("readOnly", false);

      //check if the values are not equal
      if (!data || !this.$isEqual(JSON.parse(data), value)) {
        this.editor.setValue(JSON.stringify(value, null, "\t") ?? "");
        //clear history to prevent a bug where the keybinding ctrl+z would undo the initial data
        this.editor.doc.clearHistory();
        this.editor.refresh();
      }
    },
  },

  mounted() {
    //create a new editor instance and set options
    this.editor = CodeMirror.fromTextArea(this.$refs.editor, {
      height: "auto",
      lineNumbers: true,
      theme: "xq-light",
      mode: {
        name: "javascript",
        json: true,
        statementIndent: 2,
      },
    });

    //add change listener, so the value is validated and updated whenever the user enters something
    this.editor.on("keyup", (editor) => {
      //validate and update data 300ms after the last input change
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        try {
          let value = editor.getValue();
          if (this.uimodel.schema && value != undefined) {
            //validate the json input against the schema

            if (value === "" && this.uimodel.inheritedData) {
              //the value is empty so it should be inherited
              if (this.value != undefined) this.$emit("input", undefined);
              return;
            }
            value = value ? JSON.parse(value) : null;
            if (
              this.$isEqual(this.value, value) &&
              this.validationErrors.length === 0
            )
              return;
            this.$emit("input", value);
            this.validationErrors = new Array();
          }
        } catch (e) {
          this.validationErrors.push(e.toString());
        }
      }, 300);
    });

    //set the initial data
    const value = this.value ?? this.uimodel?.data;
    const data = value ? JSON.stringify(value, null, "\t") : null;
    if (
      !this.disabled &&
      ((data !== undefined && data !== null) || !this.uimodel.inheritable) &&
      (this.uimodel.parentData !== undefined ||
        this.uimodel.inheritedData === undefined ||
        this.uimodel.dataLevel === 0)
    ) {
      //show data if the property is not inherited
      this.editor.setValue(data ?? "");
      this.editor.setOption("readOnly", false);
    } else {
      //inherited data so disable editor
      this.editor.setValue("");
      this.editor.setOption("readOnly", "nocursor");
    }

    //clear history to prevent a bug where the keybinding ctrl+z would undo the initial data
    this.editor.doc.clearHistory();
    this.editor.refresh();
  },

  methods: {
    restore() {
      let savedData = this.uimodel.savedData;
      let type = this.$getType(savedData) ?? this.uimodel?.schema?.type;

      //if the restored value is neither object nor array, close the json editor
      if (type !== "object" && type !== "array") {
        this.$emit("toggle-editor", true);
      } else {
        this.$nextTick(() => {
          const value = savedData ? JSON.stringify(savedData, null, "\t") : "";
          this.editor.setValue(value);
        });
      }
      this.$emit("restore");
    },

    toggleInherit() {
      if (this.inherited) {
        //turn inherit OFF
        this.$nextTick(() => {
          this.editor.setOption("readOnly", "");
          const data = this.uimodel.data;
          const value = data
            ? JSON.stringify(this.uimodel.data, null, "\t")
            : "";
          this.editor.setValue(value);
        });
      } else {
        //turn inherit ON
        this.$nextTick(() => {
          if (this.uimodel.inheritable) {
            this.validationErrors = new Array();
            this.editor.setValue("");
            this.editor.setOption("readOnly", "nocursor");
          }
        });
      }
      this.$emit("toggle-inherit");
    },

    toggleEditor(val) {
      if (this.hasErrors) {
        //do not switch if the editor still has errors
        //show the tooltip instead to inform the user
        this.showErrors = true;
        window.setTimeout(() => (this.showErrors = false), 2000);
        return;
      }
      this.$emit("toggle-editor", val);
    },
  },

  computed: {
    label() {
      //use schema titel as default label
      let label = this.uimodel?.schema?.title?.values?.["en-GB"];
      //if no title, fallback to property name
      if (label === undefined) label = this.uimodel?.property;
      if (label === undefined) return undefined;
      //Display "(invalid)" only if additional properties are not undefined
      if (
        this.isInvalidData &&
        this.uimodel.parentSchema?.additionalProperties !== undefined
      )
        return label + " (invalid)";
      if (this.removed) return label + " (removed)";
      return label + (this.uimodel?.required ? "*" : "");
    },

    hasErrors() {
      return this.validationErrors.length > 0;
    },

    inherited() {
      return this.uimodel.data === null || this.uimodel.data === undefined;
    },
  },
};
</script>

<style scoped>
div.json-editor-container {
  height: 100%;
  text-align: left;
}

.json-editor-container::v-deep .CodeMirror {
  height: 100%;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.json-editor-container::v-deep .CodeMirror .CodeMirror-scroll {
  margin-right: -64px !important;
  width: 100%;
}

.json-editor-container.invalid::v-deep .CodeMirror {
  border: 1px solid red;
}

.json-editor-container > .controls {
  position: absolute;
  right: 10px;
  top: 0px;
}

.json-editor-container > .error-alert {
  position: absolute;
  bottom: 18px;
  right: 18px;
  z-index: 10;
}

.inherited-data-container {
  display: flex;
  flex-flow: row;
  flex: 1 0 auto;
}

.inherited-data-container .container:first-child {
  flex-shrink: 3;
}
</style>