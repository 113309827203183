<template>
  <v-container class="i18n-editor" fluid>
    <v-container v-if="loading" class="loading-overlay">
      <v-overlay>
        <v-progress-circular indeterminate size="48" color="primary" />
      </v-overlay>
    </v-container>
    <v-simple-table v-else class="i18n-table">
      <template v-slot:default>
        <tbody>
          <tr
            v-for="(locale, index) in displayedLocales"
            :key="index"
            class="i18n-row"
          >
            <td class="locale-column">
              <v-container
                :class="{
                  'locale-select-container': true,
                  'text-body-1': true,
                  'font-weight-bold':
                    fallbackLocale && fallbackLocale === locale,
                }"
                :data-test-id="'i18n_locale_' + locale"
              >
                {{ locale }}
              </v-container>
            </td>
            <td class="label-column">
              <DataEditor
                class="label-input"
                :uimodel="i18nModels[locale]"
                :show-label="false"
                :ref="'editor_' + locale"
                @input="setTranslation(locale, $event)"
                @restore="restore(locale)"
              />
            </td>
          </tr>
          <tr v-if="showAddLocaleBtn">
            <td colspan="2" class="px-0">
              <v-btn
                text
                color="primary"
                class="add-locale-btn"
                tabindex="-1"
                @click="addLocale"
              >
                <v-icon left color="primary">mdi-plus</v-icon>
                Add locale
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import mixin from "mixins/data-editor-mixin";

export default {
  mixins: [mixin],

  components: {
    DataEditor: () => import("../DataEditor"),
  },

  props: {
    value: {
      type: Object,
      required: false,
    },

    uimodel: {
      type: Object,
      required: false,
      default: () => {
        return { domainData: null, path: "$" };
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    //An configuration object which can define supportedLocales and fallbackLocale
    configuration: {
      type: Object,
      required: false,
    },

    //defines which locale should be shown first
    //default is the fallbackLocale
    firstLocale: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      data: this.uimodel?.data ?? this.value,
      supportedLocales: [],
      fallbackLocale: null,
      loading: false,
      update: 0,
    };
  },

  mounted() {
    this.getSupportedLocales();
  },

  watch: {
    "uimodel.data": {
      handler: function (data) {
        this.data = data;
      },
      deep: true,
    },

    value: {
      handler: function (value) {
        this.$set(this.uimodel, "data", value);
      },
      deep: true,
    },

    firstLocale(firstLocale) {
      const idx = this.supportedLocales.findIndex(
        (locale) => locale === firstLocale
      );
      if (idx > -1) {
        //set fallback language to first index
        this.supportedLocales.splice(idx, 1);
        this.supportedLocales.unshift(firstLocale);
      }
    },
  },

  methods: {
    setTranslation(locale, label) {
      if (label === undefined) return;
      //make sure that data can take translations
      if (!this.data || !this.$isPlainObject(this.data)) this.data = {};
      if (this.data?.[locale] === label) return;
      //if the label is set, update the translation, else delete it
      this.$set(this.data, locale, label);
      this.$emit("input", this.data);
    },

    async getSupportedLocales() {
      try {
        const configuration = this.$cloneObject(this.configuration);
        this.supportedLocales = configuration?.supportedLocales;
        this.fallbackLocale = configuration?.fallbackLocale;
        if (
          !configuration?.supportedLocales ||
          !configuration?.fallbackLocale
        ) {
          //get all supported locales specified in the core.configService configuration
          this.supportedLocales =
            this.$store.state.localization.supportedLocales;
          this.fallbackLocale = this.$store.state.localization.fallbackLocale;
        }
        const idx = this.supportedLocales.findIndex(
          (locale) => locale === this.fallbackLocale
        );
        if (idx > -1) {
          //set fallback language to first index
          this.supportedLocales.splice(idx, 1);
          this.supportedLocales.unshift(this.fallbackLocale);
        }
      } finally {
        this.loading = false;
      }
    },

    async addLocale() {
      const locale = await this.$prompt("New locale", {
        rules: [
          (val) => !!val || "Locale is required",
          (val) =>
            !this.supportedLocales.some((locale) => locale === val) ||
            "Locale " + val + " does already exist",
          (val) => this.$isValidLocale(val) || val + " is not a valid locale",
        ],
      });

      if (!locale) return;
      this.supportedLocales.push(locale);

      this.$nextTick(() => {
        const input = this.$refs?.["editor_" + locale]?.[0]?.$refs.field;
        if (input) input.focus();
      });
    },

    restore(locale) {
      this.update++;
      this.restoreProperty(this.i18nModels[locale]);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    i18nModels() {
      this.update;
      let i18nModels = [];
      let uimodels = null;

      if (this.uimodel) {
        let uimodel = this.$cloneObject(this.uimodel);
        if (this.inherited && !uimodel.mergedData) {
          //if the property is inherited and has also no merged data, set the displayed locales
          //as merged data, so thet the uimodels and possible inherited data get caculated correctly
          const mergedData = this.displayedLocales.reduce((obj, locale) => {
            return { ...obj, [locale]: null };
          }, {});
          this.$set(uimodel, "mergedData", mergedData);
        }
        uimodels = this.buildSubUimodels(uimodel);
      }
      for (let locale of this.displayedLocales) {
        let labelUiModel = uimodels?.[locale];
        let persisted = true;
        if (!labelUiModel) {
          let localeData = this.data?.[locale];
          if (this.$isPlainObject(localeData)) {
            persisted = localeData.persisted;
            localeData = localeData.value;
          }

          //create uimodel for not existing translations manually
          labelUiModel = {
            data: localeData,
            property: locale,
            schema: {
              type: "string",
            },
            domain: this.uimodel?.domain || this.selectedDomain,
          };
        }

        if (!persisted) {
          //if the value is fallback data, make sure that the
          //date editor renders it as placeholder
          this.$set(labelUiModel, "default", labelUiModel.data);
          this.$set(labelUiModel, "data", "");
        } else if (!labelUiModel.savedData) {
          //set the saved data on the uimodel if persisted is true
          //and no saved data is specified
          this.$set(labelUiModel, "savedData", labelUiModel.data);
        }

        const path = this.uimodel?.path + "/" + locale;
        this.$set(labelUiModel, "path", path);
        this.$set(labelUiModel, "editable", !this.disabled);

        this.$set(
          labelUiModel,
          "inheritable",
          !!this.uimodel.key && !!this.uimodel.keySpace
        );
        //set the isAdditional flag to false, because even though the
        //translation might be an additional property it should not be removable
        this.$set(labelUiModel, "isAdditional", false);
        this.$set(labelUiModel, "parentData", this.$cloneObject(this.data));
        this.$set(i18nModels, locale, labelUiModel);
      }

      return i18nModels;
    },

    displayedLocales() {
      const locales = this.supportedLocales;
      //get missing locales from current data
      if (this.data) {
        Object.keys(this.data).forEach((locale) => {
          if (!locales.includes(locale)) locales.push(locale);
        });
      }
      //if a specific locale should be displayed first
      //make sure it is
      if (this.firstLocale) {
        const idx = locales.findIndex((locale) => locale === this.firstLocale);
        if (idx > -1) locales.splice(idx, 1);
        locales.unshift(this.firstLocale);
      }
      return locales.filter((locale) => !locale.startsWith("_"));
    },

    showAddLocaleBtn() {
      return !this.disabled && (!this.uimodel || !this.inherited);
    },

    inherited() {
      return (
        this.uimodel.inheritable &&
        (this.data === null || this.data === undefined)
      );
    },
  },
};
</script>

<style scoped>
.i18n-editor {
  padding: 0px;
  position: relative;
  height: 100%;
}

.i18n-table thead > th {
  text-align: center;
}

.i18n-table tbody > tr > td {
  vertical-align: middle;
  height: fit-content;
}

.i18n-table .locale-column {
  white-space: nowrap;
}

.i18n-table .label-column {
  width: 100%;
}

.i18n-table .label-column > .inherited-label-container {
  height: 60px;
  display: flex;
}

.i18n-table .add-locale-btn {
  cursor: pointer;
}

.i18n-table tbody > tr > td > .data-editor::v-deep > .primitive-type-container {
  justify-content: center;
}

.i18n-table .locale-select-container {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  width: fit-content;
  text-align: left;
}

.i18n-table .theme--light.v-select::v-deep .v-select__selection--disabled {
  color: black;
}

.i18n-table .add-locale-btn {
  height: 48px;
  width: 100%;
}

.i18n-table .label-input.data-editor::v-deep .field-wrap {
  width: 100%;
}

.i18n-table .i18n-row {
  height: 62px;
}

.i18n-editor > .loading-overlay {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.loading-overlay::v-deep .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.loading-overlay::v-deep .v-overlay--active .v-overlay__scrim {
  opacity: 0 !important;
}
</style>
