<template>
  <div class="required-properties-editor">
    <v-chip-group :value="requiredMix" :disabled="disabled" column multiple>
      <v-chip
        v-for="property in displayedProperties"
        filter
        :key="property"
        :value="property"
        :filter-icon="getRequiredIcon(property)"
        :data-test-id="'required_personalization_' + property"
        :color="getRequiredStatusColor(property)"
        :disabled="isDisabled(property)"
        :title="getRequiredTitle(property)"
        :class="{
          'inherited-required': getInheritedStatus(property) === true,
          'inherited-non-required': getInheritedStatus(property) === false,
        }"
        @click="updateRequired(property)"
      >
        {{ property }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import mixin from "../../../../../../mixins/schema-editor-mixin";
export default {
  mixins: [mixin],
  props: {
    schema: {
      type: Object,
      required: true,
    },

    inheritedSchema: {
      type: Object,
      required: false,
    },

    properties: {
      type: Array,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    uimodel: {
      type: Object,
      required: true,
    },

    fixedRequiredProperties: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      reloadKey: false,
    };
  },

  watch: {
    properties: {
      handler: function () {
        this.reloadKey = !this.reloadKey;
      },
      deep: true,
    },
  },

  methods: {
    getRequiredStatus(property) {
      /*
            Returns...
            * true if required
            * false if non-required
            * undefined if not specified
        */
      const isRequired = this.required.includes(property);
      const isNonRequired = this.nonRequired.includes(property);
      if (isRequired) return true;
      else if (isNonRequired) return false;
      return undefined;
    },

    updateRequired(property) {
      const status = this.getRequiredStatus(property);
      if (status === true) {
        //is currently required, so switch to nonRequired
        this.removeFromRequired(property);
        this.addNonRequiredProperty(property);
      } else if (status === false) {
        //is currently nonRequired, so set to undefined
        this.removeFromNonRequired(property);
      } else {
        //is neither required nor nonRequired, so set required
        this.addRequiredProperty(property);
      }
    },

    getRequiredStatusColor(property) {
      const status = this.getRequiredStatus(property);
      if (status === undefined) return undefined;
      if (status) return "primary";
      return "warning";
    },

    getRequiredIcon(property) {
      const status = this.getRequiredStatus(property);
      if (status === undefined) {
        const inheritedStatus = this.getInheritedStatus(property);
        if (inheritedStatus === undefined) {
          return undefined;
        }
        if (inheritedStatus) {
          return "mdi-star-outline";
        }
        return "mdi-star-off-outline";
      }
      if (status) return "mdi-star";
      return "mdi-star-off";
    },

    getRequiredTitle(property) {
      const status = this.getRequiredStatus(property);
      if (status === undefined) {
        const inheritedStatus = this.getInheritedStatus(property);
        let text = "This property's state is not set ";
        if (inheritedStatus === undefined) {
          return text + "nor inherited and therefore not required by default";
        }
        if (inheritedStatus) {
          return text + "but is required via inheritance";
        }
        return text + "but is explicitly NOT required via inheritance";
      }
      if (status) return "This property is required ";
      return "This property is NOT required";
    },

    restoreRequired() {
      const savedRequired = this.uimodel?.savedSchema?.required;
      const savedNonRequired = this.uimodel?.savedSchema?.nonRequired;
      this.restore("required", savedRequired, this.schema);
      this.restore("nonRequired", savedNonRequired, this.schema);
    },

    addNonRequiredProperty(property) {
      let nonRequired = this.nonRequired;
      nonRequired.push(property);
      this.$set(this.schema, "nonRequired", nonRequired);
    },

    removeFromNonRequired(property) {
      const index = this.nonRequired.findIndex((item) => item === property);
      if (Array.isArray(this.schema?.nonRequired)) {
        this.$delete(this.schema.nonRequired, index);
        if (this.schema.nonRequired.length === 0) {
          this.$delete(this.schema, "nonRequired");
        }
      }
    },

    addRequiredProperty(property) {
      let required = this.required;
      required.push(property);
      this.$set(this.schema, "required", required);
    },

    removeFromRequired(property) {
      const index = this.required.findIndex((item) => item === property);
      if (Array.isArray(this.schema?.required)) {
        this.$delete(this.schema.required, index);
        if (this.schema.required.length === 0) {
          this.$delete(this.schema, "required");
        }
      }
    },

    getInheritedStatus(property) {
      if (
        this.inheritProperties &&
        !this.excludedProperties.includes(property) &&
        this.getRequiredStatus(property) === undefined
      ) {
        const isInheritedRequired = this.inheritedRequired.includes(property);
        const isInheritedNonRequired =
          this.inheritedNonRequired.includes(property);
        if (isInheritedRequired || this.isFixedRequired(property)) {
          return true;
        } else if (isInheritedNonRequired) return false;
      }
      return undefined;
    },

    isDisabled(property) {
      return this.disabled || this.isFixedRequired(property);
    },

    isFixedRequired(property) {
      return this.fixedRequiredProperties.some((p) => p === property);
    },
  },

  computed: {
    displayedProperties() {
      this.reloadKey;
      if (this.properties) {
        return this.properties;
      }

      let properties = [];
      if (this.schema.properties) {
        properties = Object.keys(this.schema.properties);
      }
      return properties.sort((p1, p2) => {
        return p1.localeCompare(p2);
      });
    },

    required() {
      return this.schema.required ?? [];
    },

    nonRequired() {
      return this.schema.nonRequired ?? [];
    },

    requiredMix() {
      const requiredMix = this.required
        .concat(this.nonRequired)
        .concat(this.inheritedRequired)
        .concat(this.inheritedNonRequired)
        .concat(this.fixedRequiredProperties);
      //remove possible duplicates
      return requiredMix.reduce((array, property) => {
        const notExcludedFromInheritance =
          this.getRequiredStatus(property) !== undefined ||
          (this.inheritProperties &&
            !this.excludedProperties.includes(property));

        if (!array.includes(property) && notExcludedFromInheritance) {
          array.push(property);
        }
        return array;
      }, []);
    },

    inheritedRequired() {
      return this.inheritedSchema?.required ?? [];
    },

    inheritedNonRequired() {
      return this.inheritedSchema?.nonRequired ?? [];
    },

    inheritProperties() {
      return this.schema.inheritProperties;
    },

    excludedProperties() {
      return this.schema?.inheritExcludePropertyInformation ?? [];
    },
  },
};
</script>

<style scoped>
.required-properties-editor {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  position: relative;
}

.required-properties-editor > .restore-btn {
  margin-left: 4px;
}

.required-properties-editor .v-chip.inherited-required,
.inherited-required {
  border: 2px solid var(--v-primary-base) !important;
}

.required-properties-editor .v-chip.inherited-non-required,
.inherited-non-required {
  border: 2px solid var(--v-warning-base) !important;
}

.required-properties-editor
  .v-chip.inherited-non-required::v-deep
  .v-chip__filter.v-icon {
  color: var(--v-warning-base) !important;
}

.required-properties-editor
  .v-chip.inherited-required::v-deep
  .v-chip__filter.v-icon {
  color: var(--v-primary-base) !important;
}
</style>