<template>
  <!-- SCHEMA ATTRIBUTES -->
  <v-container
    class="schema-attributes-container"
    data-test-id="schemaAttributes"
  >
    <v-toolbar flat width="100%">
      <v-toolbar-title>Attributes</v-toolbar-title>
      <v-divider vertical inset class="mx-2" />
      <v-checkbox
        v-model="schema.inherit"
        class="schema-inherit-switch"
        data-test-id="schemaInheritSwitch"
        hide-details
        :input-value="schema.inherit"
        :disabled="disabled || !allowSchemaRangeChanges"
        :false-value="false"
        :true-value="true"
        :color="schema.inherit ? 'orange' : undefined"
      >
        <template #label>
          <div>Inherit Attributes</div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" right>
                mdi-help-circle-outline
              </v-icon>
            </template>
            <div class="help-tooltip">
              If turned on, the schema will inherit any attributes from higher
              level
            </div>
          </v-tooltip>
        </template>
      </v-checkbox>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="schema-attributes">
      <!-- COMMON ATTRIBUTES -->
      <v-container class="pa-0">
        <v-row dense>
          <v-col cols="2">
            <div class="text-truncate">title</div>
          </v-col>
          <v-col class="d-flex">
            <Localization
              data-test-id="titleLocalizationEditor"
              :value="titleKey"
              :disabled="disabled"
              :key-pattern="uimodel.key"
              :key-space="uimodel.keySpace"
              @input="
                (i18nKey) =>
                  updateAttribute('title', i18nKey ? { i18nKey } : undefined)
              "
            />

            <v-btn
              v-if="titleRestoreable"
              icon
              color="orange"
              :title="
                'Restore to ' +
                JSON.stringify(
                  uimodel.savedSchema ? uimodel.savedSchema.title : 'undefined'
                )
              "
              @click="
                restore(
                  'title',
                  uimodel.savedSchema ? uimodel.savedSchema.title : undefined,
                  schema
                )
              "
              class="restore-btn"
            >
              <v-icon>mdi-restore</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <div class="text-truncate">description</div>
          </v-col>
          <v-col>
            <Localization
              data-test-id="descriptionLocalizationEditor"
              :value="descriptionKey"
              :disabled="disabled"
              :key-pattern="uimodel.key"
              :key-space="uimodel.keySpace"
              @input="
                (i18nKey) =>
                  updateAttribute(
                    'description',
                    i18nKey ? { i18nKey } : undefined
                  )
              "
            />

            <v-btn
              v-if="descriptionRestoreable"
              icon
              color="orange"
              :title="
                'Restore to ' +
                JSON.stringify(
                  uimodel.savedSchema
                    ? uimodel.savedSchema.description
                    : 'undefined'
                )
              "
              @click="
                restore(
                  'description',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.description
                    : undefined,
                  schema
                )
              "
              class="restore-btn"
            >
              <v-icon>mdi-restore</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- TYPE SPECIFIC ATTRIBUTES -->

      <!-- STRING -->
      <v-container v-if="type === 'string'" class="type-settings">
        <v-row dense>
          <v-col cols="2">format</v-col>
          <v-col>
            <AttributesField
              :value="schema.format"
              :items="stringFormats"
              attribute="format"
              type="select"
              :uimodel="uimodel"
              :disabled="isDisabled('format')"
              @input="updateAttribute('format', $event)"
              @restore="
                restore(
                  'format',
                  uimodel.savedSchema ? uimodel.savedSchema.format : undefined,
                  schema
                )
              "
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>minLength</v-col>
          <v-col cols="4">
            <AttributesField
              :value="schema.minLength"
              attribute="minLength"
              type="number"
              class="pr-2"
              :uimodel="uimodel"
              :rules="[ruleSet.minLength]"
              :disabled="isDisabled('minLength')"
              @restore="
                restore(
                  'minLength',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.minLength
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('minLength', $event)"
            />
          </v-col>
          <v-col cols="2">maxLength</v-col>
          <v-col cols="4">
            <AttributesField
              :value="schema.maxLength"
              attribute="maxLength"
              type="number"
              :uimodel="uimodel"
              :rules="[ruleSet.maxLength]"
              :disabled="isDisabled('maxLength')"
              @restore="
                restore(
                  'maxLength',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.maxLength
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('maxLength', $event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">pattern</v-col>
          <v-col>
            <AttributesField
              :value="schema.pattern"
              attribute="pattern"
              type="text"
              :uimodel="uimodel"
              :disabled="isDisabled('pattern')"
              placeholder="Regular Expression"
              @restore="
                restore(
                  'pattern',
                  uimodel.savedSchema ? uimodel.savedSchema.pattern : undefined,
                  schema
                )
              "
              @input="updateAttribute('pattern', $event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2" class="d-flex align-start">enumOptions</v-col>
          <v-col>
            <EnumOptions
              :value="schema.enumOptions"
              :uimodel="uimodel"
              :disabled="isDisabled('enumOptions')"
              :allow-schema-range-changes="allowSchemaRangeChanges"
              :pattern="schema.pattern"
              class="pt-3"
              @input="updateEnumOptions($event)"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- NUMBER -->
      <v-container
        v-if="type === 'number' || type === 'integer'"
        class="type-settings"
      >
        <v-row dense>
          <v-col cols="2">exclusiveMinimum</v-col>
          <v-col cols="4">
            <AttributesField
              :value="schema.exclusiveMinimum"
              attribute="exclusiveMinimum"
              type="number"
              :uimodel="uimodel"
              :rules="[ruleSet.exclusiveMinimum]"
              :disabled="isDisabled('exclusiveMinimum')"
              @restore="
                restore(
                  'exclusiveMinimum',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.exclusiveMinimum
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('exclusiveMinimum', $event)"
            />
          </v-col>
          <v-col cols="2">exclusiveMaximum</v-col>
          <v-col cols="4">
            <AttributesField
              :value="schema.exclusiveMaximum"
              attribute="exclusiveMaximum"
              type="number"
              :uimodel="uimodel"
              :rules="[ruleSet.exclusiveMaximum]"
              :disabled="isDisabled('exclusiveMaximum')"
              @restore="
                restore(
                  'exclusiveMaximum',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.exclusiveMaximum
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('exclusiveMaximum', $event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="">minimum</v-col>
          <v-col cols="4">
            <AttributesField
              :value="schema.minimum"
              attribute="minimum"
              type="number"
              :uimodel="uimodel"
              :rules="[ruleSet.minimum]"
              :disabled="isDisabled('minimum')"
              @restore="
                restore(
                  'minimum',
                  uimodel.savedSchema ? uimodel.savedSchema.minimum : undefined,
                  schema
                )
              "
              @input="updateAttribute('minimum', $event)"
            />
          </v-col>
          <v-col>maximum</v-col>
          <v-col cols="4">
            <AttributesField
              :value="schema.maximum"
              attribute="maximum"
              type="number"
              :uimodel="uimodel"
              :rules="[ruleSet.maximum]"
              :disabled="isDisabled('maximum')"
              @restore="
                restore(
                  'maximum',
                  uimodel.savedSchema ? uimodel.savedSchema.maximum : undefined,
                  schema
                )
              "
              @input="updateAttribute('maximum', $event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>multipleOf</v-col>
          <v-col cols="10">
            <AttributesField
              :value="schema.multipleOf"
              attribute="multipleOf"
              type="number"
              :uimodel="uimodel"
              :disabled="isDisabled('multipleOf')"
              @restore="
                restore(
                  'multipleOf',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.multipleOf
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('multipleOf', $event)"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- ARRAY -->
      <v-container v-if="type === 'array'" class="type-settings">
        <v-row dense>
          <v-col>minItems</v-col>
          <v-col cols="5">
            <AttributesField
              :value="schema.minItems"
              attribute="minItems"
              type="number"
              :uimodel="uimodel"
              :rules="[ruleSet.minItems]"
              :disabled="isDisabled('minItems')"
              @restore="
                restore(
                  'minItems',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.minItems
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('minItems', $event)"
            />
          </v-col>
          <v-col>maxItems</v-col>
          <v-col cols="5">
            <AttributesField
              :value="schema.maxItems"
              attribute="maxItems"
              type="number"
              :uimodel="uimodel"
              :rules="[ruleSet.maxItems]"
              :disabled="isDisabled('maxItems')"
              @restore="
                restore(
                  'maxItems',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.maxItems
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('maxItems', $event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">uniqueItems</v-col>
          <v-col>
            <AttributesField
              :value="schema.uniqueItems"
              :uimodel="uimodel"
              :disabled="isDisabled('uniqueItems')"
              attribute="uniqueItems"
              type="checkbox"
              @restore="
                restore(
                  'uniqueItems',
                  uimodel.savedSchema
                    ? uimodel.savedSchema.uniqueItems
                    : undefined,
                  schema
                )
              "
              @input="updateAttribute('uniqueItems', $event)"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- OBJECT -->
      <v-container
        v-if="
          type === 'object' &&
          schema.properties &&
          Object.keys(schema.properties).length > 0
        "
        class="type-settings"
      >
        <PropertiesInheritance
          :schema="schema"
          :disabled="disabled || !allowSchemaRangeChanges"
          :uimodel="uimodel"
        />
        <v-row v-if="schema.properties">
          <v-col cols="2">
            <span class="mr-2">required</span>
            <RequiredPropertiesTooltip />
          </v-col>
          <v-col cols="10">
            <RequiredProperties
              :schema="schema"
              :disabled="isDisabled(required)"
              :uimodel="uimodel"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- Default value -->
      <v-container>
        <v-row dense>
          <v-col cols="2">default</v-col>
          <v-col>
            <v-switch
              v-model="hasDefault"
              class="default-switch"
              hide-details
              :disabled="disabled"
            />
          </v-col>
          <v-col
            cols="10"
            v-if="hasDefault && type !== 'object' && type !== 'array'"
          >
            <DataEditor
              v-if="hasDefault"
              v-model="schema.default"
              ext-view-mode="edit"
              :domain-data="undefined"
              :merged-data="undefined"
              :schema="schema"
              property="default"
            />
          </v-col>
        </v-row>
        <v-row v-if="hasDefault && (type === 'object' || type === 'array')">
          <DataEditor
            v-model="schema.default"
            ext-view-mode="edit"
            :domain-data="undefined"
            :merged-data="undefined"
            :schema="schema"
            property="default"
          />
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import mixin from "mixins/schema-editor-mixin";
import AttributesField from "./AttributesField";
import DataEditor from "components/configuration/data/editors/DataEditor";
import Localization from "components/localization/Localization";
import EnumOptions from "./EnumOptions";
import RequiredProperties from "./properties/RequiredProperties";
import RequiredPropertiesTooltip from "./properties/RequiredPropertiesTooltip";
import PropertiesInheritance from "./properties/PropertiesInheritance";

export default {
  mixins: [mixin],

  components: {
    AttributesField,
    DataEditor,
    Localization,
    EnumOptions,
    RequiredProperties,
    RequiredPropertiesTooltip,
    PropertiesInheritance,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    uimodel: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowSchemaRangeChanges: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      schema: this.value || {},
      hasDefault: this.value?.default != undefined,
      update: 0,
    };
  },

  watch: {
    schema: {
      handler: function (schema) {
        //remove undefined values
        this.$emit("input", schema);
      },
      deep: true,
    },

    value: {
      handler: function (val) {
        if (this.$isEqual(val, this.schema)) return;
        this.schema = val;
      },
      deep: true,
    },

    hasDefault(val) {
      if (!val) {
        this.$delete(this.schema, "default");
        return;
      }
      this.$set(this.schema, "default", undefined);
    },

    "schema.default": function (val) {
      if (val != undefined) {
        this.hasDefault = true;
      }
    },
  },

  methods: {
    updateAttribute(property, value) {
      if (
        value === undefined ||
        (property === "required" && Array.isArray(value) && value.length === 0)
      ) {
        //if the attributes value is undefined, remove it from the schema
        this.$delete(this.schema, property);
        return;
      }
      this.$set(this.schema, property, value);
    },

    updateEnumOptions(options) {
      if (!options || options.length === 0) {
        this.$delete(this.schema, "enumOptions");
        this.$delete(this.schema, "enum");
        return;
      }
      this.$set(this.schema, "enumOptions", options);
      this.$set(
        this.schema,
        "enum",
        options.map((option) => {
          return option.value;
        }, [])
      );
    },

    isDisabled(property) {
      const editableAttributes = this.uimodel.editableAttributes;
      if (Array.isArray(editableAttributes)) {
        const propertyPath =
          this.uimodel.path.replaceAll("/", ".") + "." + property;
        const isEditable = editableAttributes.some(
          (path) => path === propertyPath
        );
        return !isEditable;
      }
      return this.disabled || !this.allowSchemaRangeChanges;
    },
  },

  computed: {
    stringFormats() {
      return [
        { text: "Date-Time", value: "date-time" },
        { text: "Date", value: "date" },
        { text: "Email", value: "email" },
        { text: "Telephone", value: "telephone" },
        { text: "Password", value: "password" },
        { text: "Byte", value: "byte" },
        { text: "Birthdate", value: "birthdate" },
      ];
    },

    titleRestoreable() {
      return (
        this.uimodel?.savedSchema?.title &&
        this.uimodel?.savedSchema?.title?.i18nKey !=
          this.schema?.title?.i18nKey &&
        !this.disabled
      );
    },

    descriptionRestoreable() {
      return (
        this.uimodel?.savedSchema?.description &&
        this.uimodel?.savedSchema?.description?.i18nKey !=
          this.schema?.description?.i18nKey &&
        !this.disabled
      );
    },

    ruleSet() {
      //possible validation rules schemas
      return {
        required: (val) => {
          if (val === undefined || val === "" || val === null)
            return "Name is required";
          return true;
        },

        //Number
        minimum: (val) => this.validationMethods.minimum(val, this.schema),
        exclusiveMinimum: (val) =>
          this.validationMethods.exclusiveMinimum(val, this.schema),
        maximum: (val) => this.validationMethods.maximum(val, this.schema),
        exclusiveMaximum: (val) =>
          this.validationMethods.exclusiveMaximum(val, this.schema),

        //String
        minLength: (val) => this.validationMethods.minLength(val, this.schema),
        maxLength: (val) => this.validationMethods.maxLength(val, this.schema),
        enumValues: (val) => {
          //check if all enums are matching the pattern if given
          if (!this.schema.pattern) return true;
          if (val === undefined || val === "" || val === null) return true;
          let pattern = new RegExp(this.schema.pattern);
          let enumArr = val.split(",");
          if (Array.isArray(enumArr)) {
            for (let value of enumArr) {
              if (!pattern.test(value)) {
                return "Value " + value + " does not match specified pattern";
              }
            }
          }
          return true;
        },

        //Arrays
        minItems: (val) => this.validationMethods.minItems(val, this.schema),
        maxItems: (val) => this.validationMethods.maxItems(val, this.schema),
      };
    },

    type() {
      return this.schema?.type || this.uimodel?.mergedSchema?.type;
    },

    titleKey() {
      return this.schema?.title?.i18nKey ?? "";
    },

    descriptionKey() {
      return this.schema?.description?.i18nKey ?? "";
    },
  },
};
</script>

<style scoped>
.schema-attributes-container {
  display: flex;
  flex-flow: column nowrap;
  margin: 8px;
  width: calc(100% - 8px);
  position: relative;
  max-width: 100%;
  background-color: #fff;
  padding: 1.125em 1.5em;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgb(0 0 0 / 30%),
    0 0.0625rem 0.125rem rgb(0 0 0 / 20%);
}

.schema-attributes {
  margin-left: 10px;
  width: calc(100% - 10px);
}

.schema-attributes .col {
  text-overflow: ellipsis;
  overflow: hidden;
}

.schema-attributes > .type-settings {
  padding: 12px 0px;
}

.schema-attributes .default-switch.v-input--selection-controls {
  margin: 0;
  padding: 0;
}

.schema-attributes .ui-info-fieldset {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin: 8px 0px;
}

.schema-attributes::v-deep .data-editor.root:not(.object):not(.array) {
  padding-top: 0px;
}

.schema-attributes::v-deep
  .object.root
  > div:not(.primitive-type-container)
  > .controls {
  top: 0px;
}
</style>

<style>
.schema-attributes-container .schema-inherit-switch.orange--text .v-label {
  color: darkorange !important;
}
</style>