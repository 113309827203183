<template>
  <v-toolbar
    class="pattern-header grey lighten-5"
    data-test-id="patternHeader"
  >
    <div class="d-flex flex-grow-1 align-stretch">
      <div
        v-ripple
        tabindex="0"
        class="d-flex justify-center px-2 back-btn"
        data-test-id="backToSearchBtn"
        @click="goToSearch"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <v-divider
        class="mr-3"
        vertical
      />
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-row-reverse flex-wrap pt-2">
          <div class="d-flex flex-grow-1 justify-end">
            <div
              v-if="loading"
              class="px-4"
            >
              <v-progress-circular
                indeterminate
                :size="24"
                :width="2"
                color="grey darken-3"
              />
            </div>

            <v-btn
              v-if="!isPreview"
              :outlined="!isDirty"
              :disabled="loading"
              :color="hasViolation ? 'error' : 'green'"
              class="ml-2"
              data-test-id="patternSaveBtn"
              @click="$emit('save', false)"
            >
              <ViolationAlert
                v-if="hasViolation"
                :key="showViolation"
                :violation="violation"
                :color="isDirty ? 'white' : 'error'"
                alignment="left"
              />

              Save
            </v-btn>

            <v-btn
              v-if="deleteable && !isPreview"
              outlined
              :disabled="loading"
              color="red"
              class="white--text ml-2"
              data-test-id="patternDeleteBtn"
              @click="$emit('remove')"
            >
              Delete
            </v-btn>
          </div>
          <div class="text-overline font-weight-regular flex-grow-1">
            CONFIGURATION
          </div>
        </div>
        <v-toolbar-title class="d-flex flex-grow-1 py-1 flex-wrap">
          <div
            class="mr-3 font-weight-bold header-title"
            data-test-id="patternHeaderTitle"
          >
            {{ pattern }}
          </div>
          <div class="d-flex">
            <PatternHeaderMenu
              :items="configRelations"
              no-data-text="No configurations found"
              @click:item="(config) => openConfiguration(config.key)"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  text
                  small
                  color="primary"
                  class="ml-1"
                  data-test-id="patternConfigurationRelationsBtn"
                  :disabled="loadingConfigRelations"
                  :loading="loadingConfigRelations"
                >
                  <v-icon left>mdi-link</v-icon>
                  Configurations ({{ configRelations.length }})
                </v-btn>
              </template>

              <template #item="{ item }">
                <v-list-item-icon v-if="item.icon">
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content :data-test-id="'config_relation_' + item.key">
                  <v-list-item-title class="d-flex align-center">
                    <v-icon left>mdi-cog</v-icon>
                    {{ item.key }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    small
                    hint="Configuration has product"
                    class="ml-1"
                    :color="item.hasProduct ? 'primary' : ''"
                    :disabled="!item.hasProduct"
                  >
                    mdi-package-variant
                  </v-icon>
                </v-list-item-icon>
              </template>
            </PatternHeaderMenu>

            <PatternHeaderMenu
              :items="products"
              :max-items="50"
              item-key="sku"
              no-data-text="No products found"
              @click:item="(event) => openProducts(event)"
              @show-all="(event) => openProducts(event)"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  text
                  small
                  class="ml-1"
                  color="primary"
                  data-test-id="patternProductRelationsBtn"
                  :disabled="loadingProducts"
                  :loading="loadingProducts"
                >
                  <v-icon left>mdi-link</v-icon>
                  Products ({{ products.length }})
                </v-btn>
              </template>

              <template #item="{ item }">
                <v-list-item-content :data-test-id="'product_relation_' + item.sku">
                  <v-list-item-title class="d-flex align-center">
                    <v-icon left>mdi-package-variant</v-icon>
                    {{ item.sku }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    small
                    hint="Configuration has product"
                    class="ml-1"
                    :color="item.hasConfig ? 'primary' : ''"
                    :disabled="!item.hasConfig"
                  >
                    mdi-cog
                  </v-icon>
                </v-list-item-icon>
              </template>
            </PatternHeaderMenu>
          </div>
        </v-toolbar-title>
      </div>
    </div>
    <template #extension>
      <v-tabs v-model="selectedTab">
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :data-test-id="'view_mode_' + tab.value"
        >
          <v-icon left>{{ tab.icon }}</v-icon>
          {{ tab.text }}
        </v-tab>
      </v-tabs>
    </template>
  </v-toolbar>
</template>

<script>
import mixin from "../../../mixins/elastic-search-mixin";
import PatternHeaderMenu from "./PatternHeaderMenu";
import ViolationAlert from "../../common/display-helpers/ViolationAlert";

export default {
  mixins: [mixin],

  components: {
    PatternHeaderMenu,
    ViolationAlert,
  },

  props: {
    pattern: {
      type: String,
      required: true,
    },

    lookupRegion: {
      type: String,
      required: true,
    },

    isDirty: {
      type: Boolean,
      required: false,
      default: false,
    },

    deleteable: {
      type: Boolean,
      required: false,
      default: true,
    },

    violation: {
      type: Object,
      required: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      configRelations: [],
      products: [],
      loadingConfigRelations: false,
      loadingProducts: false,
      showViolation: false,
      selectedTab: null,
    };
  },

  mounted() {
    this.loadLinks();
  },

  watch: {
    $route(route) {
      const mode = route?.query?.mode;
      if (mode)
        this.selectedTab = this.tabs.findIndex((tab) => tab.value === mode);
    },

    loading(loading) {
      if (!loading && this.hasViolation) {
        this.showViolation = true;
        window.setTimeout(() => (this.showViolation = false), 3000);
      }
    },

    async selectedTab(tab) {
      await this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          mode: this.tabs[tab].value,
        },
      });
    },
  },

  methods: {
    async loadLinks() {
      //load configurations and products async
      const configRelPromise = this.loadConfigRelations();
      const productPromise = this.loadProducts();

      //wait until configurations and products are loaded to check their relation
      Promise.all([configRelPromise, productPromise]).then(() => {
        //check if product has config and vice versa
        this.products.forEach((product) =>
          this.$set(
            product,
            "hasConfig",
            product.sku === this.pattern ||
            this.configRelations.some((config) => config.key === product.sku)
          )
        );
        this.configRelations.forEach((confRel) =>
          this.$set(
            confRel,
            "hasProduct",
            this.products.some((product) => confRel.key === product.sku)
          )
        );
      });
    },

    async loadConfigRelations() {
      try {
        this.loadingConfigRelations = true;
        const configs =
          await this.$store.$coreApi.coreConfigurationApi.configLookup(
            this.selectedDomain,
            "SKU",
            this.pattern + ".*",
            {
              lookupRegion: [this.lookupRegion],
              includeParentDomains: false,
            }
          );

        //Show all configurations except the current one
        const isWildcardPattern = this.pattern.includes("*");
        this.configRelations =
          configs
            ?.filter((config) => !(config.key === this.pattern))
            .map((config) => {
              let icon;
              const key = config.key;
              const domain = config.domain;
              const isWildcardConfig = key.includes("*");

              if (!isWildcardPattern) {
                //if current pattern is a fully specified SKU, the
                //related configs can only be parent configs
                icon = "mdi-arrow-left";
              } else {
                //current pattern has at least one wildcard char

                if (!isWildcardConfig) {
                  //if the config is a fully specified SKu,
                  //it has to be a child of the current pattern
                  icon = "mdi-arrow-right";
                } else {
                  //Both current pattern and config are wildcards
                  const patternSpecificity = this.calculateSpecificity(
                    this.pattern,
                    domain.fullPath
                  );
                  if (patternSpecificity > config.specificity)
                    icon = "mdi-arrow-left";
                  else icon = "mdi-arrow-right";
                }
              }

              return {
                icon,
                ...config,
              };
            }) ?? [];
      } finally {
        this.loadingConfigRelations = false;
      }
    },

    //TODO: Remove after PEAK-27465 and use specificity returned by lookup endpoint
    calculateSpecificity(key, domainPath) {
      //calculate specificity of pattern
      //see https://chillimetrix.alturos.com/confluence/display/PEAKV3/Commerce+core+-+Configuration+Service

      let domainSpecificity = 0;
      domainPath.split(".").forEach((part, index) => {
        if (part === "*") return;
        domainSpecificity += Math.pow(2, index + 1);
      });

      let keySpecificity = 0;
      key.split(".").forEach((part, index) => {
        if (part === "*") return;
        keySpecificity += 10 + (index + 1);
      });
      return domainSpecificity * 100 + 180 + keySpecificity;
    },

    async openConfiguration(pattern) {
      await this.$router.push({
        name: this.lookupRegion === "data" ? "productData" : "productSchema",
        params: {
          pattern,
        },
        query: this.$route.query,
      });
    },

    async loadProducts() {
      try {
        this.loadingProducts = true;
        const search = this.searchRequestBody({
          limit: 51,
          _source: ["sku"],
          query: {
            wildcard: {
              ["sku.keyword"]: {
                value: this.pattern + ".*",
              },
            },
          },
        });

        const result = await this.$store.$coreApi.coreElasticSearchApi.search({
          domain: this.selectedDomain,
          indexType: "product",
          body: search,
        });

        const products = result?.items ?? [];
        this.products = products.map((product) => {
          return product._source;
        });
      } finally {
        this.loadingProducts = false;
      }
    },

    async goToSearch() {
      //remove pattern parameter and go back to search
      let params = this.$route.params;
      this.$delete(params, "pattern");
      const name =
        this.lookupRegion === "data"
          ? "productDataSearch"
          : "productSchemaSearch";
      await this.$router.push({
        name,
        params,
      });
    },

    async openProducts({ search, sku }) {
      let filterQuery = this.skuFilterQuery(this.pattern + "*");
      if (search) {
        //add current search to filter
        filterQuery.bool.filter.push({
          wildcard: {
            ["sku.keyword"]: {
              value: "*" + search + "*",
            },
          },
        });
      }

      const filter = this.$urlEncode(JSON.stringify(filterQuery));
      const query = {
        page: 1,
        limit: 100,
        filter,
        index: "product",
      };
      if (sku) {
        //if a SKU is given, open the detail of it
        await this.$router.push({
          name: "productDetail",
          params: {
            domain: this.selectedDomain,
            product: sku,
          },
          query,
        });
      } else {
        await this.$router.push({
          name: "products",
          params: {
            domain: this.selectedDomain,
          },
          query,
        });
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    tabs() {
      return [
        { text: "Preview", value: "view", icon: "mdi-eye" },
        { text: "Edit", value: "edit", icon: "mdi-form-textbox" },
        { text: "JSON", value: "json", icon: "mdi-code-json" },
      ];
    },

    isPreview() {
      return this.tabs[this.selectedTab]?.value === "view";
    },

    hasViolation() {
      return !!this.violation;
    },
  },
};
</script>

<style scoped>
.pattern-header {
  min-height: 80px;
  height: auto !important;
}

.pattern-header::v-deep>.v-toolbar__content {
  padding: 4px 8px 4px 0px;
  height: auto !important;
  border-bottom: 1px solid lightgrey;
}

.pattern-header::v-deep>.v-toolbar__content>div>div.back-btn {
  min-height: 80px;
  width: 60px;
}

.pattern-header::v-deep>.v-toolbar__content div.header-title {
  text-overflow: clip;
  white-space: pre-wrap;
  word-break: break-all;
}

.pattern-header>.v-toolbar__content>div>div.back-btn:hover,
.pattern-header>.v-toolbar__content>div>div.back-btn:focus {
  background: #eeeeee;
  cursor: pointer;
  outline: 0;
}

.pattern-header::v-deep>.v-toolbar__extension {
  background-color: white;
}

.pattern-header-menu {
  text-align: left;
}
</style>