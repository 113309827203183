import { render, staticRenderFns } from "./SchemaEditor.vue?vue&type=template&id=64a9920a&scoped=true&"
import script from "./SchemaEditor.vue?vue&type=script&lang=js&"
export * from "./SchemaEditor.vue?vue&type=script&lang=js&"
import style0 from "./SchemaEditor.vue?vue&type=style&index=0&id=64a9920a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a9920a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VInput } from 'vuetify/lib/components/VInput';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VContainer,VDivider,VForm,VIcon,VInput,VSelect,VSubheader,VTextField,VToolbar})
