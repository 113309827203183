var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.editable &&
    (_vm.hasComment ||
      _vm.hasDescription ||
      _vm.changeable ||
      _vm.restoreable ||
      _vm.inheritable ||
      _vm.editorEnabled ||
      _vm.removeable)
  )?_c('div',{staticClass:"controls",attrs:{"data-test-id":_vm.dataTestId}},[(_vm.hasComment || _vm.hasDescription)?_c('div',{staticClass:"help"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1859502481)},[_c('div',{staticClass:"help-tooltip"},[(_vm.hasDescription)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.description))}})]):_vm._e(),(_vm.hasComment)?_c('div',[_vm._v(_vm._s(_vm.uimodel.schema.dataUI.comment))]):_vm._e()])])],1):_vm._e(),((_vm.hasComment || _vm.hasDescription) && _vm.changeable)?_c('v-divider',{attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.changeable)?_c('div',{staticClass:"type"},[_c('select',{attrs:{"disabled":_vm.disabled,"data-test-id":"dataTypeSelect"},on:{"input":function($event){return _vm.$emit('type-change', $event.currentTarget.value)}}},_vm._l((_vm.types),function(type,index){return _c('option',{key:index,domProps:{"value":JSON.stringify(type.schema),"selected":_vm.$isEqual(type.schema, _vm.uimodel.schema)}},[_vm._v(" "+_vm._s(type.title)+" ")])}),0)]):_vm._e(),((_vm.hasComment || _vm.hasDescription || _vm.changeable) && _vm.restoreable)?_c('v-divider',{attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.restoreable)?_c('div',{staticClass:"restore",attrs:{"title":_vm.restoreTitle}},[_c('v-btn',{attrs:{"icon":"","color":"orange","data-test-id":"restoreDataBtn","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('restore')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-restore")])],1)],1):_vm._e(),(
      (_vm.hasComment || _vm.hasDescription || _vm.changeable || _vm.restoreable) &&
      _vm.inheritable &&
      !_vm.readonly
    )?_c('v-divider',{attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.inheritable && !_vm.readonly)?_c('InheritanceSourceMenu',{attrs:{"inherited-key-pattern":_vm.uimodel.inheritedKeyPattern,"inherited-domain":_vm.uimodel.inheritedDomain,"key-pattern":_vm.keyPattern},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{
          inherit: true,
          changed: _vm.hasChanged,
        }},'div',attrs,false),on),[_c('v-checkbox',{key:_vm.parentInherited + _vm.hasData,attrs:{"dense":"","data-test-id":"inheritDataCheckbox","hide-details":"","label":"inherit","color":"orange","input-value":_vm.inherited,"tabindex":-1,"disabled":_vm.disabled},on:{"click":function($event){_vm.$emit('toggle-inherit');
            _vm.showEditor = false;}}})],1)]}}],null,false,3137816338)}):_vm._e(),(
      (_vm.hasComment ||
        _vm.hasDescription ||
        _vm.changeable ||
        _vm.restoreable ||
        (_vm.inheritable && !_vm.readonly)) &&
      _vm.isRoot &&
      !_vm.hideViewControls
    )?_c('v-divider',{attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.isRoot && !_vm.hideViewControls)?_c('div',{staticClass:"editor-switch",attrs:{"title":"Read-only mode on"}},[_c('v-btn',{attrs:{"icon":"","data-test-id":"readOnlyBtn","color":_vm.readonly ? 'primary' : 'black',"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('enable-readonly')}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1):_vm._e(),(
      (_vm.hasComment ||
        _vm.hasDescription ||
        _vm.changeable ||
        _vm.restoreable ||
        _vm.inheritable ||
        _vm.isRoot) &&
      _vm.editorEnabled &&
      !_vm.hideViewControls
    )?_c('v-divider',{attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.isRoot && !_vm.hideViewControls)?_c('div',{staticClass:"root-editor-toggle"},[_c('div',{staticClass:"editor-switch",attrs:{"title":"Edit in Form"}},[_c('v-btn',{attrs:{"icon":"","color":!_vm.editorShown && !_vm.readonly ? 'primary' : 'black',"disabled":_vm.disabled,"data-test-id":"formEditorBtn"},on:{"click":function($event){return _vm.$emit('toggle-editor', false)}}},[_c('v-icon',[_vm._v("mdi-form-textbox")])],1)],1),(_vm.editorEnabled)?_c('v-divider',{attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.editorEnabled)?_c('div',{staticClass:"editor-switch",attrs:{"title":"Edit in JSON"}},[_c('v-btn',{attrs:{"icon":"","data-test-id":"jsonEditorBtn","color":_vm.editorShown && !_vm.readonly ? 'primary' : 'black',"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('toggle-editor', true)}}},[_c('v-icon',[_vm._v("mdi-code-json")])],1)],1):_vm._e()],1):(_vm.editorEnabled && !_vm.hideViewControls)?_c('div',{staticClass:"editor-switch",attrs:{"title":"Edit in JSON"}},[_c('v-btn',{attrs:{"icon":"","color":"black","data-test-id":"editorToogleBtn","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('toggle-editor', !_vm.editorShown)}}},[(!_vm.editorShown)?_c('v-icon',[_vm._v("mdi-code-json")]):_c('v-icon',[_vm._v("mdi-form-textbox")])],1)],1):_vm._e(),(
      (_vm.hasComment ||
        _vm.hasDescription ||
        _vm.changeable ||
        _vm.restoreable ||
        _vm.inheritable ||
        ((_vm.isRoot || _vm.editorEnabled) && !_vm.hideViewControls)) &&
      _vm.removeable
    )?_c('v-divider',{attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.removeable)?_c('div',{staticClass:"remove"},[_c('v-btn',{attrs:{"icon":"","color":"red","data-test-id":"removeDataBtn","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }