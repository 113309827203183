<template>
  <v-container
    fluid
    :class="{
      'schema-attributes-field': true,
      changed: restoreable,
    }"
  >
    <v-select
      v-if="type === 'select'"
      v-model="attributeValue"
      :items="items"
      :rules="rules"
      :disabled="disabled"
      :data-test-id="dataTestId"
      dense
      outlined
      hide-details="auto"
      clearable
    />

    <v-checkbox
      v-else-if="type === 'checkbox'"
      v-model="attributeValue"
      :rules="rules"
      :disabled="disabled"
      :data-test-id="dataTestId"
      dense
      outlined
      hide-details="auto"
    />

    <v-text-field
      v-else-if="type === 'number'"
      v-model.number="attributeValue"
      :rules="rules"
      :disabled="disabled"
      type="number"
      class="number"
      :placeholder="placeholder"
      :data-test-id="dataTestId"
      dense
      outlined
      hide-details="auto"
    />

    <v-text-field
      v-else
      v-model="attributeValue"
      :rules="rules"
      :type="type"
      :class="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :data-test-id="dataTestId"
      dense
      outlined
      hide-details="auto"
    />

    <v-btn
      v-if="restoreable && !disabled"
      icon
      color="orange"
      :title="
        'Restore to ' +
        JSON.stringify(
          uimodel.savedSchema ? uimodel.savedSchema[attribute] : 'undefined'
        )
      "
      @click="$emit('restore')"
      class="restore-btn"
    >
      <v-icon>mdi-restore</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },

    attribute: {
      required: true,
    },

    uimodel: {
      required: true,
    },

    type: {
      //text, number, select, checkbox
      required: false,
      default: "text",
    },

    rules: {
      required: false,
      default: () => {
        return [];
      },
    },

    placeholder: {
      required: false,
      default: "",
    },

    items: {
      required: false,
      default: () => {
        return [];
      },
    },

    disabled: {
      required: false,
      default: false,
    },
  },

  data() {
    return {
      attributeValue: this.value,
      timeout: 0,
    };
  },

  watch: {
    attributeValue(val) {
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        if (val === "" || val === null || val === undefined) {
          this.$emit("input", undefined);
          return;
        }
        this.$emit("input", val);
      }, 300);
    },
  },

  computed: {
    restoreable() {
      if (!this.uimodel.savedSchema) return false;
      let savedValue = this.$getObjectValueByPath(
        this.uimodel.savedSchema,
        this.attribute
      );
      if (this.attribute === "enum" && Array.isArray(savedValue))
        savedValue = savedValue.join();
      return (
        savedValue != undefined &&
        !this.$isEqual(savedValue, this.attributeValue)
      );
    },

    dataTestId() {
      return "attribute_" + this.attribute;
    },
  },
};
</script>

<style scoped>
.schema-attributes-field {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}

.schema-attributes-field.changed > .v-text-field::v-deep .v-input__slot {
  border-right: 4px solid orange;
  border-radius: 5px;
}

.schema-attributes-field
  > .v-text-field::v-deep
  .v-text-field__details
  .v-messages__message {
  white-space: pre-line;
}

.schema-attributes-field > .v-input--selection-controls {
  margin: 0;
  padding: 0;
}

.schema-attributes-field > .restore-btn {
  margin-left: 8px;
}
</style>