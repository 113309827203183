<template>
  <div
    :data-test-id="dataTestId"
    class="controls"
    v-if="
      editable &&
      (hasComment ||
        hasDescription ||
        changeable ||
        restoreable ||
        inheritable ||
        editorEnabled ||
        removeable)
    "
  >
    <!-- HELP ICON -->
    <div v-if="hasComment || hasDescription" class="help">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
        </template>
        <div class="help-tooltip">
          <div v-if="hasDescription">
            <span v-html="$sanitize(description)"></span>
          </div>
          <div v-if="hasComment">{{ uimodel.schema.dataUI.comment }}</div>
        </div>
      </v-tooltip>
    </div>

    <v-divider
      vertical
      inset
      v-if="(hasComment || hasDescription) && changeable"
    />

    <!-- TYPE SELECT -->
    <div v-if="changeable" class="type">
      <select
        :disabled="disabled"
        data-test-id="dataTypeSelect"
        @input="$emit('type-change', $event.currentTarget.value)"
      >
        <option
          v-for="(type, index) in types"
          :key="index"
          :value="JSON.stringify(type.schema)"
          :selected="$isEqual(type.schema, uimodel.schema)"
        >
          {{ type.title }}
        </option>
      </select>
    </div>

    <v-divider
      vertical
      inset
      v-if="(hasComment || hasDescription || changeable) && restoreable"
    />

    <!-- RESTORE BUTTON -->
    <div v-if="restoreable" class="restore" :title="restoreTitle">
      <v-btn
        icon
        color="orange"
        data-test-id="restoreDataBtn"
        :disabled="disabled"
        @click="$emit('restore')"
      >
        <v-icon small>mdi-restore</v-icon>
      </v-btn>
    </div>

    <v-divider
      vertical
      inset
      v-if="
        (hasComment || hasDescription || changeable || restoreable) &&
        inheritable &&
        !readonly
      "
    />

    <!-- INHERIT CHECKBOX -->
    <InheritanceSourceMenu
      v-if="inheritable && !readonly"
      :inherited-key-pattern="uimodel.inheritedKeyPattern"
      :inherited-domain="uimodel.inheritedDomain"
      :key-pattern="keyPattern"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-on="on"
          v-bind="attrs"
          :class="{
            inherit: true,
            changed: hasChanged,
          }"
        >
          <v-checkbox
            dense
            data-test-id="inheritDataCheckbox"
            hide-details
            label="inherit"
            color="orange"
            :input-value="inherited"
            :tabindex="-1"
            :disabled="disabled"
            :key="parentInherited + hasData"
            @click="
              $emit('toggle-inherit');
              showEditor = false;
            "
          />
        </div>
      </template>
    </InheritanceSourceMenu>
    <v-divider
      vertical
      inset
      v-if="
        (hasComment ||
          hasDescription ||
          changeable ||
          restoreable ||
          (inheritable && !readonly)) &&
        isRoot &&
        !hideViewControls
      "
    />

    <!-- READER VIEW SWITCH -->
    <div
      v-if="isRoot && !hideViewControls"
      class="editor-switch"
      title="Read-only mode on"
    >
      <v-btn
        icon
        data-test-id="readOnlyBtn"
        :color="readonly ? 'primary' : 'black'"
        :disabled="disabled"
        @click="$emit('enable-readonly')"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </div>

    <v-divider
      vertical
      inset
      v-if="
        (hasComment ||
          hasDescription ||
          changeable ||
          restoreable ||
          inheritable ||
          isRoot) &&
        editorEnabled &&
        !hideViewControls
      "
    />

    <div v-if="isRoot && !hideViewControls" class="root-editor-toggle">
      <!-- JSON/FORM EDITOR SWITCH -->
      <div class="editor-switch" title="Edit in Form">
        <v-btn
          icon
          :color="!editorShown && !readonly ? 'primary' : 'black'"
          :disabled="disabled"
          data-test-id="formEditorBtn"
          @click="$emit('toggle-editor', false)"
        >
          <v-icon>mdi-form-textbox</v-icon>
        </v-btn>
      </div>

      <v-divider vertical inset v-if="editorEnabled" />

      <!-- JSON EDITOR SWITCH -->
      <div v-if="editorEnabled" class="editor-switch" title="Edit in JSON">
        <v-btn
          icon
          data-test-id="jsonEditorBtn"
          :color="editorShown && !readonly ? 'primary' : 'black'"
          :disabled="disabled"
          @click="$emit('toggle-editor', true)"
        >
          <v-icon>mdi-code-json</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- JSON EDITOR SWITCH -->
    <div
      v-else-if="editorEnabled && !hideViewControls"
      class="editor-switch"
      title="Edit in JSON"
    >
      <v-btn
        icon
        color="black"
        data-test-id="editorToogleBtn"
        :disabled="disabled"
        @click="$emit('toggle-editor', !editorShown)"
      >
        <v-icon v-if="!editorShown">mdi-code-json</v-icon>
        <v-icon v-else>mdi-form-textbox</v-icon>
      </v-btn>
    </div>

    <v-divider
      vertical
      inset
      v-if="
        (hasComment ||
          hasDescription ||
          changeable ||
          restoreable ||
          inheritable ||
          ((isRoot || editorEnabled) && !hideViewControls)) &&
        removeable
      "
    />

    <!-- REMOVE BUTTON -->
    <div v-if="removeable" class="remove">
      <v-btn
        icon
        color="red"
        data-test-id="removeDataBtn"
        :disabled="disabled"
        @click="$emit('remove')"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import mixin from "../../../../../mixins/data-editor-mixin";
import InheritanceSourceMenu from "./InheritanceSourceMenu.vue";

export default {
  mixins: [mixin],

  components: {
    InheritanceSourceMenu,
  },

  props: {
    uimodel: {
      type: Object,
      required: false,
      default: () => {
        return { domainData: null };
      },
    },

    type: {
      type: String,
      required: false,
      default: null,
    },

    editorShown: {
      type: Boolean,
      required: false,
      default: false,
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideViewControls: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    editable() {
      return this.uimodel.editable;
    },

    changeable() {
      //can the type of this property be changed?
      return (
        !this.uimodel.removed &&
        this.uimodel?.possibleSchemas?.length > 0 &&
        (this.uimodel.inheritedData === undefined ||
          (this.uimodel.data !== undefined && this.uimodel.data !== null))
      );
    },

    types() {
      //possible schemas for a property whose type may be switched
      let types = [];
      const schemas = this.uimodel?.possibleSchemas;

      if (schemas !== undefined) {
        schemas.forEach((s) => {
          types.push({
            schema: s,
            title: s.type.toUpperCase(),
          });
        });
      }
      return types;
    },

    inheritable() {
      //can this property be inherited from a parent domain?
      return !this.uimodel.isInvalid && this.uimodel.inheritable;
    },

    inherited() {
      return (
        this.inheritable &&
        (this.uimodel.data === null ||
          this.uimodel.data === undefined ||
          this.parentInherited)
      );
    },

    parentInherited() {
      const parentData = this.uimodel.parentData;
      return (
        this.uimodel.dataLevel > 0 &&
        (parentData === null || parentData === undefined)
      );
    },

    hasData() {
      return this.uimodel.data !== undefined && this.uimodel.data !== null;
    },

    restoreable() {
      this.uimodel.data;
      //show restore control on data editors, which were removed and have still other data editors as siblings on the same level
      const removed =
        !!this.uimodel.removed && this.uimodel.parentData !== undefined;

      //An additional property is only restoreable if it has already saved data
      const additionalRestoreable =
        !this.uimodel.isAdditional || this.uimodel.savedData !== undefined;

      return (
        (this.inheritable || this.uimodel.savedData !== undefined) &&
        additionalRestoreable &&
        (removed ||
          ((this.uimodel.parentData !== undefined ||
            this.uimodel.dataLevel === 0) &&
            !this.$isEqual(this.uimodel.data, this.uimodel.savedData)))
      );
    },

    removeable() {
      //may the user remove this property?
      return (
        this.uimodel.isAdditional &&
        !this.uimodel.inheritable &&
        this.uimodel.data !== undefined &&
        this.$getType(this.uimodel.parentData) !== "array" &&
        !this.uimodel.removed
      );
    },

    editorEnabled() {
      //may the user switch to the json editor?
      return (
        (this.uimodel.schema?.type === "object" ||
          this.uimodel.schema?.type === "array") &&
        !this.uimodel.removed
      );
    },

    hasComment() {
      return !!this.uimodel.schema?.dataUI?.comment;
    },

    hasDescription() {
      return !!this.uimodel.schema?.description?.values;
    },

    description() {
      return this.uimodel?.schema?.description?.values?.["en-GB"];
    },

    isRoot() {
      return (
        this.uimodel.isRoot ||
        (this.uimodel.dataLevel === 0 && !this.uimodel.parentData)
      );
    },

    hasChanged() {
      return !this.$isEqual(this.uimodel?.data, this.uimodel?.savedData);
    },

    keyPattern() {
      return (
        this.uimodel.key ??
        this.$route?.params?.key ??
        this.$route?.params?.pattern
      );
    },

    restoreTitle() {
      let objString = JSON.stringify(this.uimodel.savedData);
      if (objString?.length > 100)
        objString = objString.substring(0, 100) + "...";
      return "Restore to " + objString;
    },

    dataTestId() {
      if (this.uimodel.testId) {
        return "data_editor_controls_" + this.uimodel.testId;
      }
      return "data_editor_controls_" + this.uimodel.path;
    },
  },
};
</script>
<style scoped>
.controls {
  color: gray;
  height: 36px;
  display: flex;
  align-items: center;
}

.controls .v-divider--vertical {
  margin-left: 6px;
  margin-right: 6px;
}

.controls .inherit {
  display: flex;
  align-items: center;
}

.controls .inherit > .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.controls .inherit::v-deep .v-input.v-input--is-label-active .v-label {
  color: darkorange !important;
}

.controls > .help {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
}

.controls > .restore {
  display: flex;
  color: darkorange;
  cursor: pointer;
}

.controls > .remove {
  display: flex;
  color: tomato;
  cursor: pointer;
}

.controls > .type {
  display: flex;
  padding: 5px 0;
}

.controls > .type select {
  -webkit-appearance: auto;
  appearance: auto;
  box-sizing: border-box;
  border-style: solid;
  border-radius: 5px;
}

.controls .root-editor-toggle {
  display: flex;
  align-items: center;
  height: 100%;
}

.controls > .editor-switch {
  display: flex;
}

.editor-switch > .v-input--selection-controls {
  padding: 0;
  margin: 0;
}

.inheritance-source-text {
  background-color: white;
  font-size: 0.75rem;
}
</style>
