<template>
  <v-container v-if="runningAction" class="loading-overlay">
    <v-overlay>
      <v-progress-circular indeterminate size="96" color="primary" />
    </v-overlay>
  </v-container>
  <v-container
    v-else-if="!isRemovedAdditionalProperty"
    fluid
    :class="{
      'data-editor': true,
      [type]: true,
      'not-inherited': !inherited,
      root: uimodel.dataLevel === 0,
      removed,
      added,
      changed,
      'is-invalid': isInvalidData || showEditableWarning,
      'has-enum-items': hasEnumItems,
      'read-only': showReadOnly,
      'has-violations': violations.length > 0,
      'no-label': !labelShown,
      'show-json': showJSONEditor,
    }"
    :data-test-id="dataTestId"
  >
    <!-- READER VIEW -->
    <v-container fluid v-if="showReadOnly">
      <Reader
        data-test-id="dataReaderView"
        :data="uimodel.data"
        :schema="uimodel.schema"
        :uimodel="uimodel"
      />
      <Controls
        v-if="!isPrimitive && uimodel.editable && !extViewMode"
        :uimodel="uimodel"
        :type="type"
        :editor-shown="showJSONEditor"
        :readonly="showReadOnly"
        :disabled="disabled"
        :key="restoreUpdate"
        @remove="$emit('remove')"
        @restore="restore"
        @toggle-inherit="toggleInherit"
        @type-change="changeType"
        @toggle-editor="
          (val) => {
            showReadOnly = false;
            showJSONEditor = val;
          }
        "
        @enable-readonly="showReadOnly = true"
      />
    </v-container>

    <!-- JSON EDITOR -->
    <JsonEditor
      v-else-if="showJSONEditor"
      v-model="data"
      data-test-id="dataJsonEditor"
      :uimodel="uimodel"
      :show-label="labelShown"
      :type="type"
      :removed="removed"
      :hide-view-controls="!!extViewMode"
      :disabled="disabled"
      @remove="
        showJSONEditor = false;
        $emit('remove');
      "
      @restore="restore"
      @toggle-inherit="toggleInherit"
      @type-change="changeType"
      @toggle-editor="
        (val) => {
          showReadOnly = false;
          showJSONEditor = val;
        }
      "
      @enable-readonly="showReadOnly = true"
    />

    <!-- ######### SPECIAL EDITOR #########-->
    <v-container
      v-else-if="hasEditor && editor"
      fluid
      :class="{
        [editorName]: true,
        'primitive-type-container':
          (isPrimitive && editorName !== 'Localization') ||
          editorName === 'I18nEditor',
      }"
    >
      <div
        v-if="labelShown && editorName !== 'Localization'"
        class="label"
        :title="label"
      >
        <span data-test-id="dataEditorLabel">{{ label }}</span>
      </div>
      <div
        :class="{
          custom: true,
        }"
        ref="customEditorContainer"
        tabindex="0"
        @focusout="handleFocusOut()"
      >
        <Component
          v-model="data"
          v-bind="{ ...editorParameters }"
          :is="editor"
          :uimodel="uimodel"
          :show-label="labelShown"
          :inherited="inherited"
          :key-pattern="uimodel.key"
          :key-space="uimodel.keySpace"
          :disabled="disabled"
          :loading="loading"
          @remove="isPrimitive ? $emit('remove') : removeProperty(uimodel)"
          @restore="isPrimitive ? restore() : restoreProperty(uimodel)"
        />
      </div>
      <Controls
        v-if="!isPrimitive && uimodel.editable"
        :uimodel="uimodel"
        :type="type"
        :editor-shown="showJSONEditor"
        :readonly="showReadOnly"
        :hide-view-controls="!!extViewMode"
        :disabled="disabled"
        :key="restoreUpdate"
        @remove="$emit('remove')"
        @restore="restore"
        @toggle-inherit="toggleInherit"
        @type-change="changeType"
        @toggle-editor="
          (val) => {
            showReadOnly = false;
            showJSONEditor = val;
          }
        "
        @enable-readonly="showReadOnly = true"
      />
    </v-container>

    <v-container
      v-else-if="
        showEditableWarning &&
        (isPrimitive || (type === 'array' && hasEnumItems))
      "
      class="primitive-type-container"
    >
      <div v-if="labelShown" class="label" :title="label">
        <span data-test-id="dataEditorLabel">{{ label }}</span>
      </div>
      <span class="field-wrap">
        <div class="editable-warning-container text-caption">
          <v-icon left small color="error"> mdi-alert-circle-outline </v-icon>
          <div class="error--text">{{ editableWarning }}</div>
        </div>
      </span>
    </v-container>

    <!-- ######### BOOLEAN TYPE ######### -->
    <v-container
      v-else-if="type === 'boolean'"
      class="primitive-type-container"
      data-test-id="dataFormEditor"
    >
      <div v-if="labelShown" class="label" :title="label">
        <span data-test-id="dataEditorLabel">{{ label }}</span>
      </div>
      <span class="field-wrap" v-if="!isDisabled && !disabled">
        <v-radio-group
          v-model="data"
          dense
          row
          background-color="white"
          hide-details="auto"
          :rules="inputRules"
          :error-messages="violations"
        >
          <v-radio
            label="TRUE"
            data-test-id="dataEditorRadioYes"
            :value="true"
          />
          <v-radio
            label="FALSE"
            data-test-id="dataEditorRadioNo"
            :value="false"
          />
        </v-radio-group>
      </span>
      <span class="field-wrap" v-else>
        <v-radio-group
          :value="uimodel.inheritedData"
          dense
          row
          background-color="white"
          hide-details="auto"
          :rules="inputRules"
          :disabled="true"
          :error-messages="violations"
        >
          <v-radio
            label="TRUE"
            data-test-id="dataEditorRadioYes"
            :value="true"
          />
          <v-radio
            label="FALSE"
            data-test-id="dataEditorRadioNo"
            :value="false"
          />
        </v-radio-group>
      </span>
      <Controls
        v-if="uimodel.editable"
        :uimodel="uimodel"
        :type="type"
        :hide-view-controls="!!extViewMode"
        :disabled="disabled"
        :key="restoreUpdate"
        @remove="$emit('remove')"
        @restore="restore"
        @toggle-inherit="toggleInherit"
        @type-change="changeType"
      />
    </v-container>

    <!-- ######### STRING AND NUMBER TYPE ######### -->
    <v-container
      v-else-if="type === 'string' || type === 'number' || type === 'integer'"
      class="primitive-type-container"
      fluid
      data-test-id="dataFormEditor"
    >
      <div v-if="labelShown" class="label" :title="label">
        <span data-test-id="dataEditorLabel">{{ label }}</span>
      </div>
      <span
        v-if="!isDisabled"
        :class="{
          'field-wrap': true,
        }"
      >
        <v-text-field
          v-if="type === 'string' && !hasItems"
          v-model="data"
          dense
          outlined
          hide-details="auto"
          background-color="white"
          class="field"
          ref="field"
          data-test-id="dataEditorInput"
          autocomplete="none"
          :label="showLabelInField ? label : undefined"
          :type="format"
          :id="uid"
          :rules="inputRules"
          :loading="loading"
          :placeholder="placeholder"
          :error-messages="violations"
          :disabled="disabled"
          :clearable="!!data && format.startsWith('date')"
          @click:clear.stop.prevent="data = null"
          @focus="$emit('focus')"
        />
        <v-select
          v-else-if="hasItems"
          dense
          outlined
          hide-details="auto"
          class="field"
          ref="field"
          background-color="white"
          data-test-id="dataEditorInput"
          :value="data"
          :id="uid"
          :rules="inputRules"
          :items="enumItems"
          :clearable="true"
          :loading="loading"
          :placeholder="placeholder"
          :error-messages="violations"
          :disabled="disabled"
          :label="showLabelInField ? label : undefined"
          :menu-props="menuProps"
          @focus="$emit('focus')"
          @change="changeEnumValue"
          @click:clear.stop.prevent="changeEnumValue('')"
        >
          <template #selection="{ item }">
            <div class="d-flex flex-row">
              <div v-if="item.icon" class="mr-2">{{ item.icon }}</div>
              <div
                class="text-truncate"
                data-test-id="selectionText"
                :title="item.text"
              >
                {{ item.text }}
              </div>
            </div>
          </template>
          <template #item="{ item }">
            <div
              class="d-flex flex-row flex-grow-1"
              :data-test-id="'item_' + item.value"
            >
              <div v-if="item.icon" class="mr-2">{{ item.icon }}</div>
              <div>{{ item.text }}</div>
            </div>
          </template>
        </v-select>

        <v-text-field
          v-if="type === 'number' || type === 'integer'"
          v-model.number="data"
          dense
          outlined
          hide-details="auto"
          class="field"
          ref="field"
          type="number"
          background-color="white"
          data-test-id="dataEditorInput"
          autocomplete="none"
          :id="uid"
          :rules="inputRules"
          :loading="loading"
          :placeholder="placeholder"
          :error-messages="violations"
          :disabled="disabled"
          :label="showLabelInField ? label : undefined"
          @focus="$emit('focus')"
        />
      </span>

      <span class="field-wrap" v-else>
        <v-text-field
          dense
          outlined
          disabled
          hide-details="auto"
          class="field"
          data-test-id="dataEditorInput"
          autocomplete="none"
          :value="getDisabledData()"
          :loading="loading"
          :error-messages="violations"
          :type="format"
          :label="showLabelInField ? label : undefined"
        />
      </span>

      <Controls
        v-if="uimodel.editable"
        :uimodel="uimodel"
        :type="type"
        :hide-view-controls="!!extViewMode"
        :disabled="disabled"
        :key="restoreUpdate"
        @remove="$emit('remove')"
        @restore="restore"
        @toggle-inherit="toggleInherit"
        @type-change="changeType"
      />
    </v-container>

    <!-- ######### OBJECT TYPE ######### -->
    <v-container
      v-else-if="type === 'object'"
      fluid
      data-test-id="dataFormEditor"
    >
      <div :for="uid" class="label">
        <v-input hide-details="auto" :error-messages="violations">
          <div v-if="labelShown" data-test-id="dataEditorLabel" :title="label">
            {{ label }}
          </div>
        </v-input>
      </div>

      <div
        :class="{
          properties: true,
          even: uimodel.dataLevel === 0 || uimodel.dataLevel % 2 === 0,
          odd: uimodel.dataLevel % 2 !== 0,
        }"
      >
        <div
          v-if="showEditableWarning"
          class="editable-warning-container text-caption"
        >
          <v-icon left small color="error"> mdi-alert-circle-outline </v-icon>
          <div class="error--text">{{ editableWarning }}</div>
        </div>
        <template v-else>
          <!-- eslint-disable-next-line -->
          <div v-for="sub in subUimodels">
            <DataEditor
              v-if="sub.isAdditional === false"
              :uimodel="sub"
              :loading="loading"
              :disabled="disabled"
              @input="setProperty(sub, $event)"
              @restore="restoreProperty(sub)"
            />
          </div>
          <!-- eslint-disable-next-line -->
          <div v-for="sub in subUimodels">
            <DataEditor
              v-if="sub.isAdditional === true"
              :uimodel="sub"
              :loading="loading"
              :ref="sub.property"
              :disabled="disabled"
              @input="setProperty(sub, $event)"
              @remove="removeProperty(sub)"
              @restore="restoreProperty(sub)"
            />
          </div>
          <v-btn
            v-if="
              uimodel.schema &&
              uimodel.schema.additionalProperties &&
              !isDisabled &&
              !disabled
            "
            text
            color="primary"
            data-test-id="addPropertyBtn"
            @click="addProperty"
          >
            <v-icon left>mdi-plus</v-icon> Add
          </v-btn>
        </template>
      </div>
      <Controls
        v-if="uimodel.editable"
        :uimodel="uimodel"
        :type="type"
        :editor-shown="showJSONEditor"
        :readonly="showReadOnly"
        :hide-view-controls="!!extViewMode"
        :disabled="disabled"
        :key="restoreUpdate"
        @remove="$emit('remove')"
        @restore="restore"
        @toggle-inherit="toggleInherit"
        @type-change="changeType"
        @toggle-editor="
          (val) => {
            showReadOnly = false;
            showJSONEditor = val;
          }
        "
        @enable-readonly="showReadOnly = true"
      />
    </v-container>
    <!-- ARRAY TYPE WITH STRING ENUM ITEMS -->
    <v-container
      v-else-if="type === 'array' && hasEnumItems"
      class="primitive-type-container"
    >
      <div v-if="labelShown" :for="uid" class="label" :title="label">
        <span data-test-id="dataEditorLabel">{{ label }}</span>
      </div>
      <v-input
        hide-details="auto"
        class="enum-items-array-input"
        :disabled="disabled || isDisabled"
        :error-messages="violations"
      >
        <span class="field-wrap">
          <v-chip-group
            v-if="!disabled && !isDisabled"
            v-model="data"
            column
            multiple
            active-class="primary--text"
            data-test-id="arrayEnumItems"
          >
            <v-chip
              v-for="(value, index) in uimodel.schema.items.enum"
              :key="value + index"
              :value="value"
              :data-test-id="'enum_item_' + value"
              filter
            >
              {{ value }}
            </v-chip>
          </v-chip-group>
          <v-chip-group
            v-else
            :value="
              uimodel.savedData ? uimodel.savedData : uimodel.inheritedData
            "
            column
            multiple
            active-class="primary--text"
          >
            <v-chip
              v-for="(value, index) in uimodel.schema.items.enum"
              :key="value + index"
              :value="value"
              disabled
              filter
            >
              {{ value }}
            </v-chip>
          </v-chip-group>
        </span>
        <Controls
          :uimodel="uimodel"
          :type="type"
          :editor-shown="showJSONEditor"
          :readonly="showReadOnly"
          :hide-view-controls="!!extViewMode"
          :disabled="disabled"
          :key="restoreUpdate"
          @remove="$emit('remove')"
          @restore="restore"
          @toggle-inherit="toggleInherit"
          @type-change="changeType"
          @toggle-editor="
            (val) => {
              showReadOnly = false;
              showJSONEditor = val;
            }
          "
          @enable-readonly="showReadOnly = true"
        />
      </v-input>
    </v-container>

    <!-- ######### ARRAY TYPE ######### -->
    <v-container
      v-else-if="type === 'array'"
      fluid
      data-test-id="dataFormEditor"
    >
      <div :for="uid" class="label">
        <v-input hide-details="auto" :error-messages="violations">
          <div v-if="labelShown" data-test-id="dataEditorLabel" :title="label">
            {{ label }}
          </div>
        </v-input>
      </div>

      <div
        :class="{
          properties: true,
          even: uimodel.dataLevel === 0 || uimodel.dataLevel % 2 === 0,
          odd: uimodel.dataLevel % 2 !== 0,
        }"
      >
        <div
          v-if="showEditableWarning"
          class="editable-warning-container text-caption"
        >
          <v-icon left small color="error"> mdi-alert-circle-outline </v-icon>
          <div class="error--text">{{ editableWarning }}</div>
        </div>

        <ArrayDataEditor
          v-else
          :key="uimodel.property"
          :uimodel="uimodel"
          :disabled="disabled"
          @input="setProperty(uimodel, $event)"
          @remove="removeProperty(uimodel)"
          @restore="restoreProperty(uimodel)"
        />
      </div>
      <Controls
        v-if="uimodel.editable"
        :uimodel="uimodel"
        :type="type"
        :editor-shown="showJSONEditor"
        :readonly="showReadOnly"
        :hide-view-controls="!!extViewMode"
        :disabled="disabled"
        :key="restoreUpdate"
        @remove="$emit('remove')"
        @restore="restore"
        @toggle-inherit="toggleInherit"
        @type-change="changeType"
        @toggle-editor="
          (val) => {
            showReadOnly = false;
            showJSONEditor = val;
          }
        "
        @enable-readonly="showReadOnly = true"
      />
    </v-container>
  </v-container>
</template>
<script>
import Controls from "./controls/Controls.vue";
import JsonEditor from "./JsonEditor";
import Reader from "../Reader";
import mixin from "../../../../mixins/data-editor-mixin";
import Vue from "vue";

export default {
  name: "DataEditor",

  mixins: [mixin],

  inject: {
    getViolations: {
      default() {
        return () => {
          return [];
        };
      },
    },
  },

  components: {
    DataEditor: () => import("./DataEditor.vue"),
    ArrayDataEditor: () => import("./ArrayDataEditor.vue"),
    JsonEditor,
    Controls,
    Reader,
  },
  props: {
    //contains all necessary information for the data editor to render
    //and validate correctly. Is automatically generated by its parent data and schema.
    uimodel: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    //if false, hides the label
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },

    // synced with this.data
    //no type since this may be any data
    value: {
      required: false,
    },

    // only used for first entry, will then be split up into uimodel
    savedData: {
      required: false,
    },

    // only used for first entry, will then be split up into uimodel
    mergedData: {
      required: false,
    },

    // only used for first entry, will then be split up into uimodel
    domainLevel: {
      type: Number,
      required: false,
      default: null,
    },

    // only used for first entry, will then be split up into uimodel
    dataLevel: {
      type: Number,
      required: false,
      default: 0,
    },

    // only used for first entry, will then be split up into uimodel
    schema: {
      type: Object,
      required: false,
      default: null,
    },

    //pre-defined enum items for a string schema
    items: {
      type: Array,
      required: false,
      default: null,
    },

    //specifies if this instance is already part of an custom editor
    //also prevents infinite loops
    isCustomEditor: {
      type: Boolean,
      required: false,
      default: false,
    },

    //if true sets the data editor inpout in loading state
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    //disables the 300ms timeout when the value of a primitive
    //type is changed
    immediateUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },

    //Show the edit view first
    showEditFirst: {
      type: Boolean,
      required: false,
      default: false,
    },

    //set the view mode outside of the data editor
    //Will disable switching between modes via controls
    extViewMode: {
      type: String,
      required: false,
      validator: (mode) => {
        return ["view", "edit", "json"].includes(mode);
      },
    },

    //the currently edited configuration key pattern
    keyPattern: {
      type: String,
      required: false,
    },

    //the currently edited configuration key space
    keySpace: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    extInputRules: {
      type: Array,
      required: false,
      validator: (rules) => {
        return rules.every((rule) => typeof rule === "function");
      },
    },

    showLabelInField: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      uid: "DataEditor_" + this.$uuid.v4(),
      data: this.uimodel?.data ?? this.value,
      update: 0,
      editor: null,
      showJSONEditor: false,
      showReadOnly: false,
      timeout: 0,
      //This flag determines if the user has deactivated the inheritance manually
      inheritDeactivatedManually: false,
      updateMenuProps: false,
      restoreUpdate: 0,
      runningAction: true,
    };
  },

  async mounted() {
    try {
      this.runningAction = true;
      if (
        !this.uimodel.schema &&
        !this.uimodel.parentData &&
        !this.uimodel.property
      ) {
        const domain = this.$store.state.domains.find(
          ({ id }) => id === this.selectedDomain
        );
        const domainLevel =
          this.domainLevel ?? domain?.fullPath?.split(".")?.length ?? 1;
        const path = this.uimodel?.path ?? "$";
        const source = this.getSource(path);
        // at the first level we dont get a uimodel - that is only an internal structure - so we build it.
        this.$set(this.uimodel, "path", path);
        this.$set(this.uimodel, "inheritedData", source?.inheritValue);
        this.$set(
          this.uimodel,
          "inheritedDomain",
          source?.inheritSourceDomainId
        );
        this.$set(
          this.uimodel,
          "inheritedKeyPattern",
          source?.inheritSourceKeyPattern
        );
        this.$set(this.uimodel, "domainLevel", domainLevel);
        this.$set(this.uimodel, "dataLevel", this.dataLevel);
        this.$set(this.uimodel, "data", this.value);
        this.$set(this.uimodel, "domain", this.selectedDomain);
        this.$set(this.uimodel, "schema", this.schema);
        this.$set(this.uimodel, "savedData", this.savedData);
        this.$set(this.uimodel, "savedDomain", this.selectedDomain);
        this.$set(this.uimodel, "inheritable", true);
        this.$set(this.uimodel, "editedDomain", this.selectedDomain);
        this.$set(this.uimodel, "mergedData", this.mergedData);
        this.$set(this.uimodel, "key", this.keyPattern);
        this.$set(this.uimodel, "keySpace", this.keySpace);

        const readonly = this.schema?.dataUI?.readonly;
        const editable = this.schema?.dataUI?.editable;
        this.$set(this.uimodel, "editable", editable ?? readonly !== true);

        //show read only view initially
        if (this.dataLevel === 0 && !this.showEditFirst) {
          this.showReadOnly = true;
        }
      }
      //if the UI model has an editor specified, load it
      if (this.hasEditor && !this.isCustomEditor) {
        await this.loadEditor();
      }

      const extViewMode = this.extViewMode;
      if (extViewMode) {
        this.showJSONEditor = extViewMode === "json";
        this.showReadOnly = extViewMode === "view";
      }

      const self = this;
      window.addEventListener(
        "scroll",
        () => {
          // called when the window is scrolled.
          self.updateMenuProps = !self.updateMenuProps;
        },
        true
      );
    } finally {
      await this.$nextTick();
      this.runningAction = false;
    }
  },

  watch: {
    data: {
      handler: function (data) {
        // we prevent emitting when the parent is already undefined.
        // this also prevents a cycle where setting an object to inherit causes children to emit and the object comes back.
        const inherited = data === undefined || data === null;
        const parentInherited =
          this.uimodel.parentData === undefined ||
          this.uimodel.parentData === null;
        if (!this.inheritDeactivatedManually && inherited && parentInherited) {
          return;
        }
        this.uimodel.data = this.$cloneObject(data);
        if (!this.immediateUpdate && this.isPrimitive) {
          //emit event 300ms after the last input change
          window.clearTimeout(this.timeout);
          this.timeout = window.setTimeout(() => {
            this.$emit("input", data);
          }, 300);
        } else {
          this.$emit("input", data);
        }
      },
      deep: true,
    },

    "uimodel.data": function (data) {
      if (this.$isEqual(this.data, data)) return;
      this.data = data;
    },

    value() {
      this.$set(this.uimodel, "data", this.value);
    },

    extViewMode(mode) {
      this.showJSONEditor = mode === "json";
      this.showReadOnly = mode === "view";
    },

    "uimodel.parentData": function (parentData) {
      if (parentData === null || parentData === undefined) {
        //if parent is inherited, rest the inheritDeactivatedManually flag
        this.inheritDeactivatedManually = false;
      }
    },
  },

  methods: {
    toggleInherit() {
      if (
        (this.data === undefined || this.data === null) &&
        !this.inheritDeactivatedManually
      ) {
        this.inheritDeactivatedManually = true;
        // turn inherit OFF
        this.$set(this.uimodel, "domain", this.selectedDomain);
        const type = this.uimodel.schema?.type;

        if (type === "object") {
          //restore merged data for objects, so that each property
          //is set on the current domain, even if the objects properties are inherited from multiple domains
          const data =
            this.$cloneObject(this.uimodel?.mergedData) ??
            this.getInitialData(type);
          this.data = data;
          return;
        }

        //restore inherited data
        let data =
          this.uimodel?.inheritedData ?? this.uimodel?.savedData ?? null;

        if (data === null && (type === "array" || type === "string")) {
          data = this.getInitialData(type);
        }
        this.data = this.$cloneObject(data);
        this.$nextTick(() => {
          const inputField = this.$refs?.field;
          if (inputField) {
            //validate the input field
            inputField?.form?.validate() ?? inputField.validate();
            inputField.focus();
          }
        });
      } else {
        // turn inherit ON
        this.inheritDeactivatedManually = false;
        this.$set(this.uimodel, "domain", undefined);
        this.data = null;
        //emit input here because the watcher won't if data is undefined or null
        this.$emit("input", this.data);
      }
    },

    setProperty(sub, val) {
      if (this.type === "array") {
        //replace whole array data
        this.data = val;
      } else {
        if (val === undefined) {
          // if the data is undefined, skip the deletion of the item
          if (this.data === undefined || this.data === null) return;
          //if the new value is undefined, remove the property from the object
          this.$delete(this.data, sub.property);
        } else {
          // if a sub property wants to exist, we need to ensure that this.data can take it
          if (this.data === undefined || this.data === null) this.data = {};

          //make sure that the watcher is triggered
          this.$set(this.data, sub.property, val);

          this.$nextTick(() => {
            //focus the input field of the newly added additional property
            const comp = this.$refs[sub.property]?.[0];
            if (!comp?.$refs?.field) return;
            comp.$refs?.field.focus();
          });
        }
      }
      this.update++;
    },

    async addProperty() {
      const name = await this.$prompt("Property Name", {
        rules: [
          this.ruleSet.required,
          (val) => {
            //Check if property with same name already exists in data or schema
            const properties = this.uimodel?.schema?.properties;
            const schemaHasProperty =
              !!properties &&
              Object.prototype.hasOwnProperty.call(properties, val);
            const dataHasProperty =
              !!this.data &&
              Object.prototype.hasOwnProperty.call(this.data, val);
            return (
              (!schemaHasProperty && !dataHasProperty) ||
              "Property " + val + " already exists"
            );
          },
        ],
      });
      if (!name) return;
      const type = this.uimodel?.schema?.additionalProperties?.type ?? "string";
      this.setProperty({ property: name }, this.getInitialData(type));
    },

    changeType(schema) {
      this.uimodel.schema = JSON.parse(schema);
      const type = this.uimodel.schema.type;
      const val = this.getInitialData(type, this.data);
      this.$emit("input", val);
      this.update++;
    },

    async loadEditor() {
      try {
        const editor = this.editorName;
        let comp;
        switch (editor) {
          case "Localization":
            //eslint-disable-next-line
            comp = () => import("components/localization/Localization.vue");
            break;
          case "PaymentMethodOverview":
            //eslint-disable-next-line
            comp = () =>
              import("components/payment/method/PaymentMethodOverview.vue");
            break;
          default:
            //eslint-disable-next-line
            comp = () =>
              import("./specific/" + editor + ".vue").catch((e) => {
                this.editor = null;
                console.warn("Error: Could not load editor. " + e);
              });
            break;
        }

        this.editor = Vue.component(editor, comp);
      } catch (e) {
        console.warn("Error: Could not load editor. " + e);
        this.editor = null;
      }
    },

    getEnumItemText(item) {
      if (!this.$isPlainObject(item)) return item;
      if (item?.name) {
        if (!this.$isPlainObject(item.name)) return item.name;
        //TODO: Get the correct locale for the name
        const locale = Object.keys(item.name)[0];
        const label = item.name[locale];
        if (label) return label;
      } else if (item?.labels) {
        if (!this.$isPlainObject(item.name)) return item.labels;
        const locale = Object.keys(item.labels)[0];
        const label = item.labels[locale];
        if (label) return label;
      }
      return item.text ?? item.id ?? item.value;
    },

    getEnumItemValue(item) {
      if (!this.$isPlainObject(item)) return item;
      return item.id ?? item.value;
    },

    handleFocusOut() {
      const container = this.$refs?.customEditorContainer;
      if (!container || this.editorName !== "I18nEditor") return;
      //if the translation containers is about to be hidden,
      //scroll to top to only show the first translation option
      if (getComputedStyle(container).overflow !== "hidden") return;
      container.scrollTop = 0;
    },

    changeEnumValue(value) {
      if (value === null) return;
      this.data = value;
    },

    getDisabledData() {
      const inheritedData = this.uimodel.inheritedData;
      if (this.hasItems) {
        const item = this.enumItems.find((item) => {
          const value = this.getEnumItemValue(item);
          return value === inheritedData;
        });

        if (item) {
          return this.getEnumItemText(item);
        }
      }
      if (this.uimodel.editable === false) {
        return this.uimodel.data ?? inheritedData;
      }
      return inheritedData;
    },

    restore() {
      this.inheritDeactivatedManually = false;
      this.$emit("restore");
      this.$nextTick(() => this.restoreUpdate++);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    label() {
      //use schema titel as default label
      let label = this.uimodel?.schema?.title?.values?.["en-GB"];
      //if no title, fallback to property name
      if (label === undefined) label = this.uimodel?.property;
      if (label === undefined) return undefined;
      //Display "(invalid)" only if additional properties are not undefined
      if (
        this.isInvalidData &&
        this.uimodel.parentSchema?.additionalProperties !== undefined
      )
        return label + " (invalid)";
      if (this.removed) return label + " (removed)";
      return label + (this.uimodel?.required ? "*" : "");
    },

    subUimodels() {
      // build the models for the children of an object. this covers many combinatorial cases
      // - property is in currently edited data or not
      // - property is inherited from a parent or not
      // - property is saved or not
      // ..
      this.update;
      const uimodels = this.buildSubUimodels(this.uimodel);
      return uimodels;
    },

    type() {
      this.update;
      if (this.uimodel.schema) {
        return this.getUiModelType(this.uimodel);
      } else {
        return this.$getType(this.data);
      }
    },

    isPrimitive() {
      return this.type !== "object" && this.type !== "array";
    },

    inputRules() {
      let rules = [];
      //Collect all rules from the rule set which should be used on the current text field
      if (this.isDisabled || this.disabled) return [];
      if (
        this.type !== "string" &&
        this.type !== "number" &&
        this.type !== "integer"
      )
        return [];

      if (Array.isArray(this.extInputRules))
        rules = rules.concat(this.extInputRules);

      const schema = this.uimodel.schema;
      if (this.uimodel.required) {
        rules.push(this.ruleSet.required(schema, this.uimodel));
      }
      if (this.type === "number" || this.type === "integer") {
        if (this.type === "integer") rules.push(this.ruleSet.integer);
        if (schema?.multipleOf) rules.push(this.ruleSet.multipleOf(schema));
        if (schema?.minimum) rules.push(this.ruleSet.minimum(schema));
        if (schema?.exclusiveMinimum)
          rules.push(this.ruleSet.exclusiveMinimum(schema));
        if (schema?.maximum) rules.push(this.ruleSet.maximum(schema));
        if (schema?.exclusiveMaximum)
          rules.push(this.ruleSet.exclusiveMaximum(schema));
      }

      if (this.type === "string") {
        if (schema?.minLength) rules.push(this.ruleSet.minLength(schema));
        if (schema?.maxLength) rules.push(this.ruleSet.maxLength(schema));
        if (schema?.pattern) rules.push(this.ruleSet.pattern(schema));
      }

      return rules;
    },

    isDisabled() {
      if (!this.uimodel.editable) return true;
      //if inheritable is false, it also means that the parent model is not inheritable too.
      const inheritable = this.uimodel.inheritable;
      if (this.inherited && inheritable) return true;
      if (this.uimodel.removed) return true;
      //if the property is not inheritable (e.g. an item of an array),
      //also check if the array data is undefined or null to determine if the item was not just removed
      const canInherit =
        inheritable || this.uimodel.inheritedData !== undefined;
      const isInherited =
        (this.data === undefined && canInherit) || this.inherited;
      const parentInheritable = this.uimodel.parentInheritable ?? true;
      const parentIsInherited =
        (this.uimodel.parentData === undefined ||
          this.uimodel.parentData === null) &&
        (parentInheritable || this.uimodel.parentIsArrayItem);
      return isInherited && parentIsInherited;
    },

    changed() {
      return (
        this.uimodel.savedData !== undefined &&
        !this.$isEqual(this.data, this.uimodel.savedData)
      );
    },

    added() {
      return (
        this.uimodel.isAdditional &&
        !this.uimodel.removed &&
        this.data !== undefined &&
        this.data !== null &&
        this.uimodel.savedData === undefined &&
        this.uimodel.inheritedData === undefined
      );
    },

    removed() {
      return this.uimodel.removed;
    },

    inherited() {
      if (this.inheritDeactivatedManually) return false;
      if (this.uimodel.isArrayInherited) return true;
      return (
        (this.data === undefined || this.data === null) &&
        (this.uimodel.data === undefined || this.uimodel.data === null)
      );
    },

    hasEditor() {
      return this.uimodel.schema?.dataUI?.editor && !this.isCustomEditor;
    },

    editorName() {
      return this.uimodel.schema?.dataUI?.editor;
    },

    editorParameters() {
      return this.uimodel.schema?.dataUI?.editorParameters;
    },

    isInvalidData() {
      //data which is not represented in the schema and is no additional data
      return this.uimodel.isInvalid;
    },

    hasItems() {
      return (
        (this.type === "string" && this.uimodel.schema?.enum) || this.items
      );
    },

    enumItems() {
      const enums = this.uimodel?.schema?.enum;
      const items = this.items ?? enums ?? [];
      return items.map((item) => {
        return {
          icon: item?.icon,
          text: this.getEnumItemText(item),
          value: this.getEnumItemValue(item),
        };
      });
    },

    hasEnumItems() {
      const schema = this.uimodel?.schema;
      if (!schema || schema?.type !== "array") return false;
      const itemSchema = schema.items;
      if (itemSchema && itemSchema?.type === "string" && itemSchema.enum)
        return true;
      return false;
    },

    placeholder() {
      return this.uimodel?.default ?? "";
    },

    violations() {
      const path = this.uimodel.path?.replaceAll("/", ".");
      return this.getViolations(path);
    },

    format() {
      return this.uimodel.schema?.format || "text";
    },

    isRemovedAdditionalProperty() {
      return (
        this.inherited && this.removed && this.uimodel.savedData === undefined
      );
    },

    showEditableWarning() {
      return this.uimodel?.schema?.dataUI?.editable === false;
    },

    editableWarning() {
      if (this.isPrimitive || (this.type === "array" && this.hasEnumItems)) {
        return "This property is not editable";
      }
      return "This property and its children are not editable";
    },

    labelShown() {
      return (
        this.label &&
        this.showLabel &&
        (!this.isPrimitive || !this.showLabelInField)
      );
    },

    menuProps() {
      this.updateMenuProps;
      const element = this.$refs?.field?.$el;
      if (!element) return undefined;
      const rect = element.getBoundingClientRect();
      return {
        positionX: rect.x,
        positionY: rect.y,
        contentClass: "data-editor-enum-menu",
      };
    },

    dataTestId() {
      if (this.uimodel.testId) {
        return "data_editor_" + this.uimodel.testId;
      }
      return "data_editor_" + this.uimodel.path;
    },
  },
};
</script>
<style scoped>
.data-editor {
  position: relative;
  padding: 6px;
}

.data-editor.array.no-label.show-json,
.data-editor.object.no-label.show-json,
.data-editor.object.no-label:not(.show-json) > .container,
.data-editor.array.no-label:not(.show-json) > .container {
  padding-top: 28px;
}

.data-editor::v-deep > .container {
  padding: 4px;
  height: 100%;
}

.data-editor > .container::v-deep > .data-reader-container {
  overflow: scroll;
  height: 100%;
  padding: 12px;
}

.custom {
  display: flex;
  flex: 0 1 100%;
}

.custom > .container.data-editor {
  padding: 0;
}

.field {
  width: 100%;
  box-sizing: border-box;
}

input[type="checkbox"] {
  width: auto;
}

.number .field-wrap,
.integer .field-wrap {
  width: 150px;
}

.null {
  font-size: smaller;
  position: absolute;
  visibility: visible;
  left: 8px;
  top: 10px;
}

.null input {
  vertical-align: middle;
  margin-right: 4px;
}

.label {
  width: 150px;
  min-width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 12px;
}

.label > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.primitive-type-container {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.array > div.container:not(.primitive-type-container) > .label,
.object > div.container:not(.primitive-type-container) > .label {
  width: 400px;
}

.removed > div > .label,
.is-invalid > div > .label,
.removed > div > .label > .v-input,
.is-invalid > div > .label > .v-input {
  color: red !important;
}

.field-wrap {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.field-wrap .v-input--radio-group {
  margin-top: 0;
}

.field {
  display: inline-block;
  white-space: pre-line;
}

.object .primitive-type-container {
  justify-content: flex-start;
  padding: 4px;
}

.array .table-model-column > .data-editor::v-deep > .primitive-type-container {
  justify-content: center;
  padding: 4px;
}

.array .expandable-item-row .primitive-type-container {
  justify-content: flex-start;
  padding: 4px;
}

.object > div:not(.primitive-type-container) > .controls,
.array:not(.has-enum-items) > div > .controls {
  position: absolute;
  right: 10px;
  top: 0px;
}

.object.root > div:not(.primitive-type-container) > .controls {
  top: 0px;
}

.data-editor.root.read-only > div.container > .controls {
  top: 0px;
}

input[type="radio"] {
  vertical-align: middle;
}

.boolean .v-input--checkbox {
  display: inline-block;
  width: auto;
  margin: 0px 4px;
}

.boolean .field-wrap {
  width: auto;
}

.controls {
  color: gray;
}

.integer > div > .controls,
.number > div > .controls,
.string > div > .controls {
  background-color: #fafafa;
  padding: 4px;
  padding-left: 7px;
  border-radius: 5px;
  margin-left: -9px;
  border: 1px solid #ddd;
}

.integer
  > div
  .controls::v-deep
  > .inherit
  .number
  > div
  > .controls::v-deep
  > .inherit,
.string > div > .controls::v-deep > .inherit,
.boolean > div > .controls::v-deep > .inherit {
  margin: 6px 0px;
}

.data-editor:not(.boolean) > .primitive-type-container > .controls,
.data-editor:not(.boolean)
  > .primitive-type-container
  > .enum-items-array-input::v-deep
  .controls {
  display: inline-flex;
  align-items: center;
  background-color: #fafafa;
  padding-left: 8px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #ddd;
  border-left: 0;
  margin-left: -4px;
  height: 40px;
}

.array.has-enum-items > .primitive-type-container > .enum-items-array-input {
  flex: 0 1 auto;
}

.array.has-enum-items
  > .primitive-type-container
  > .enum-items-array-input
  .field-wrap {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-collapse: collapse;
  border-radius: 5px;
  width: auto;
  min-width: 200px;
  max-width: unset;
}

.array.has-enum-items
  > .primitive-type-container
  > .enum-items-array-input
  .field-wrap
  + .controls {
  pointer-events: auto;
}

.array.has-enum-items:not(.not-inherited):not(.has-violations)
  > .primitive-type-container
  > .enum-items-array-input::v-deep
  .v-messages__message {
  color: rgba(0, 0, 0, 0.38);
}

.array.has-enum-items
  > .primitive-type-container
  > .enum-items-array-input
  .v-chip-group::v-deep
  > div
  > .v-slide-group__content {
  padding: 3px 4px;
}

.array.has-enum-items
  > .primitive-type-container
  > .enum-items-array-input
  .v-chip-group::v-deep
  .v-chip {
  margin: 0px 8px 0px 0;
}

.data-editor > .container > .properties {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
}

.data-editor.has-violations > .container > .properties,
.array.has-enum-items.has-violations
  > .primitive-type-container
  > .enum-items-array-input
  .field-wrap {
  border: 2px solid var(--v-error-base) !important;
}

.data-editor.has-violations
  > .primitive-type-container
  > .field-wrap
  > .v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled::v-deep
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 2px solid var(--v-error-base) !important;
  opacity: 0.7;
}

.data-editor.has-violations
  > .primitive-type-container
  > .field-wrap
  > .v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled::v-deep
  > .v-input__control
  > .v-text-field__details
  > .v-messages {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  opacity: 0.7;
}

.data-editor > .container > .properties.odd,
.data-editor > .container > .custom::v-deep .odd {
  background-color: white;
}

.data-editor > .container > .properties.even,
.data-editor > .container > .custom::v-deep .even {
  background-color: #fafafa;
}

.data-editor.not-inherited.changed
  > .primitive-type-container
  .field-wrap
  .v-text-field::v-deep
  .v-input__slot {
  border-right: 4px solid darkorange !important;
}

.data-editor.not-inherited.removed
  > .primitive-type-container
  .field-wrap
  .v-text-field::v-deep
  .v-input__slot,
.data-editor.not-inherited.is-invalid
  > .primitive-type-container
  .field-wrap
  .v-text-field::v-deep
  .v-input__slot {
  border-right: 4px solid red !important;
}

.data-editor.not-inherited.added:not(.is-invalid)
  > .primitive-type-container
  .field-wrap
  .v-text-field::v-deep
  .v-input__slot {
  border-right: 4px solid dodgerblue !important;
}

/* weirdly we need to define everything important here - i guess because of the scoping.. */
@media (max-width: 600px) {
  .label {
    display: block !important;
    text-align: left !important;
  }

  .primitive-type-container > .controls {
    padding: 1px 4px !important;
    display: block !important;
    margin: -6px 4px 4px 4px !important;
    border-left: 1px solid #ddd !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 5px !important;
  }

  .field-wrap {
    width: 100% !important;
  }
}

.data-editor > .container .editable-warning-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.data-editor
  > .container.primitive-type-container
  > .field-wrap
  > .editable-warning-container {
  justify-content: flex-start;
}

.data-editor > .loading-overlay {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.data-editor > .loading-overlay::v-deep .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.data-editor > .loading-overlay::v-deep .v-overlay--active .v-overlay__scrim {
  opacity: 0 !important;
}

/* Start I18n editor */

.data-editor > .I18nEditor.primitive-type-container {
  align-items: center;
}

.data-editor > .I18nEditor > div.custom {
  overflow: hidden;
  height: 60px;
}

.data-editor > .I18nEditor > div.custom:focus-within {
  height: auto;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  z-index: 300;
  border: 1px solid rgb(46, 117, 212);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  outline: none;
  background-color: white;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 0px;
}

.data-editor > .I18nEditor > div.custom::v-deep > .i18n-editor > .i18n-table {
  background: transparent;
}

.data-editor
  > .I18nEditor
  > div.custom::v-deep
  > .i18n-editor
  > .i18n-table
  .label-column,
.data-editor
  > .I18nEditor
  > div.custom::v-deep
  > .i18n-editor
  > .i18n-table
  .label-column
  > .label-input {
  padding-right: 0px;
}

.data-editor
  > .I18nEditor
  > div.custom::v-deep
  > .i18n-editor
  > .i18n-table
  .label-column
  > .label-input
  > .primitive-type-container
  > .controls {
  display: none;
}

.data-editor
  > .I18nEditor
  > div.custom:focus-within
  ::v-deep
  > .i18n-editor
  > .i18n-table
  .label-column
  > .label-input
  > .primitive-type-container
  > .controls {
  display: inline-flex;
}

/* End I18n editor */
</style>
