<template>
  <v-container fluid class="properties-inheritance-editor">
    <v-row>
      <v-col :cols="propertiesInherited ? 3 : 12">
        <div class="d-flex flex-grow-1 align-center">
          <v-checkbox
            class="inherit-properties-switch"
            data-test-id="schemaInheritPropertiesSwitch"
            hide-details
            title="Inherit properties"
            :input-value="schema.inheritProperties"
            :disabled="disabled"
            :false-value="false"
            :true-value="true"
            :color="schema.inheritProperties ? 'orange' : undefined"
            @change="changeInheritProperties"
          >
            <template #label class="text-truncate">
              <div class="text-truncate">Inherit Properties</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right>
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <div class="help-tooltip">
                  If turned on, the schema will inherit properties from higher
                  level
                </div>
              </v-tooltip>
            </template>
          </v-checkbox>
        </div>
      </v-col>
      <v-col v-if="propertiesInherited">
        <div class="d-flex flex-grow-1 align-center">
          <v-combobox
            outlined
            multiple
            small-chips
            class="excluded-properties-combobox"
            data-test-id="schemaExcludePropertiesCombobox"
            hide-details
            placeholder="No properties excluded"
            label="Properties excluded from inheritance"
            :value="excludedProperties"
            :disabled="disabled"
            :items="displayedProperties"
            @change="updateExcludedProperties"
          >
            <template #selection="{ attrs, item, parent, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                :data-test-id="'schemaProperty_' + item"
                small
              >
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon
                  small
                  @click.stop.prevent="
                    reactivateInheritance(item, parent.selectItem)
                  "
                >
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>

            <template #append-outer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <div class="help-tooltip">
                  Exclude certain properties from inheritance. It is also
                  possible to specifiy property names which do not (yet) exist
                </div>
              </v-tooltip>
            </template>
          </v-combobox>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixin from "../../../../../../mixins/schema-editor-mixin";
export default {
  mixins: [mixin],
  props: {
    schema: {
      type: Object,
      required: true,
    },

    properties: {
      type: Array,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    uimodel: {
      type: Object,
      required: true,
    },

    removeItemCallback: {
      type: Function,
      required: false,
    },
  },

  methods: {
    changeInheritProperties() {
      const inheritProperties = !this.schema?.inheritProperties;
      if (!inheritProperties) {
        this.$delete(this.schema, "inheritExcludePropertyInformation");
      }
      this.$set(this.schema, "inheritProperties", inheritProperties);
    },

    async reactivateInheritance(property, callbackFunc) {
      let confirmed = true;
      if (this.removeItemCallback) {
        confirmed = await this.removeItemCallback(property);
      }
      if (confirmed) callbackFunc(property);
    },

    updateExcludedProperties(excludedProperties) {
      if (excludedProperties.length === 0) {
        this.$delete(this.schema, "inheritExcludePropertyInformation");
      } else {
        this.$set(
          this.schema,
          "inheritExcludePropertyInformation",
          excludedProperties
        );
      }
    },
  },

  computed: {
    displayedProperties() {
      if (this.properties) {
        return this.properties;
      } else if (this.schema.properties) {
        return Object.keys(this.schema.properties);
      }
      return [];
    },

    propertiesInherited() {
      return this.schema.inheritProperties === true;
    },

    excludedProperties() {
      return this.schema?.inheritExcludePropertyInformation ?? [];
    },
  },
};
</script>

<style>
.properties-inheritance-editor {
  padding: 0;
}

.properties-inheritance-editor .restore-btn {
  margin-left: 8px;
}

.properties-inheritance-editor .inherit-properties-switch {
  padding-top: 0;
  padding-bottom: 16px;
}

.properties-inheritance-editor .inherit-properties-switch .v-label {
  overflow: hidden;
}

.properties-inheritance-editor
  .inherit-properties-switch.orange--text
  .v-label {
  color: darkorange !important;
}
</style>